.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__snE2 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .freeBox__snE2 {
    height: 100%;
    min-height: 0;
  }
}
.navPageTitle:global(.__wab_instance) {
  position: relative;
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
@media (min-width: 280px) {
  .outer {
    height: 100%;
    min-height: 0;
  }
}
.freeBox__deGc {
  display: flex;
  position: relative;
  flex-direction: column;
  background: none;
  height: 100%;
  min-height: 0;
  padding: 8px;
}
.freeBox__deGc > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.freeBox__deGc > :global(.__wab_flex-container) > *,
.freeBox__deGc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__deGc > :global(.__wab_flex-container) > picture > img,
.freeBox__deGc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
@media (min-width: 280px) {
  .freeBox__deGc {
    height: auto;
  }
}
.freeBox__swxr0 {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 0px 0px 4px 2px #00000014;
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__swxr0 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.freeBox__swxr0 > :global(.__wab_flex-container) > *,
.freeBox__swxr0 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__swxr0 > :global(.__wab_flex-container) > picture > img,
.freeBox__swxr0
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
@media (min-width: 280px) {
  .freeBox__swxr0 {
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 280px) {
  .freeBox__swxr0 > :global(.__wab_flex-container) {
    min-height: 0;
  }
}
.text {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.inputEmail:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__aZnnL {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.submitBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn2 {
  border-width: 1px;
  border-style: none;
}
.freeBox___6MePj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.btnBack:global(.__wab_instance) {
  position: relative;
}
.submitBtn4 {
  border-width: 1px;
  border-style: none;
}
.footerWidget:global(.__wab_instance) {
  position: relative;
  left: auto;
  top: auto;
  z-index: 1;
}
@media (min-width: 280px) {
  .footerWidget:global(.__wab_instance) {
    left: 0px;
    top: 0px;
    position: sticky;
  }
}

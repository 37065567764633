.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: 100%;
  min-height: 0;
}
.navHolder {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: var(--token-r40xO_peDMY0Sw);
  width: 300px;
}
.userBtn:global(.__wab_instance) {
  position: relative;
}
.svg__lomUw {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.requestsBtn:global(.__wab_instance) {
  position: relative;
}
.svg___8MJk8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.paymentBtn:global(.__wab_instance) {
  position: relative;
  display: none;
}
.svg__qpjU {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.signout:global(.__wab_instance) {
  position: relative;
}
.svg__ogb7E {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}

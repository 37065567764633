.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__a9Ln {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .freeBox__a9Ln {
    background: url("./images/lightgreensvg.svg") 0% 0% / contain repeat-x;
  }
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
.freeBox__qf9Uy {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: 100%;
  min-height: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__qf9Uy {
    align-self: center;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: none;
    height: 100%;
    min-height: 0;
    padding: 8px;
  }
}
.freeBox__p1Zx3 {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__p1Zx3 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__p1Zx3 > :global(.__wab_flex-container) > *,
.freeBox__p1Zx3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__p1Zx3 > :global(.__wab_flex-container) > picture > img,
.freeBox__p1Zx3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 280px) {
  .freeBox__p1Zx3 > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .freeBox__p1Zx3 {
    width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__p1Zx3 > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .freeBox__p1Zx3 > :global(.__wab_flex-container) > *,
  .freeBox__p1Zx3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__p1Zx3 > :global(.__wab_flex-container) > picture > img,
  .freeBox__p1Zx3
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.freeBox__pmvfs {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__pmvfs > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__pmvfs > :global(.__wab_flex-container) > *,
.freeBox__pmvfs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pmvfs > :global(.__wab_flex-container) > picture > img,
.freeBox__pmvfs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBox__g7E4X {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.freeBox__g7E4X > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__g7E4X > :global(.__wab_flex-container) > *,
.freeBox__g7E4X > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__g7E4X > :global(.__wab_flex-container) > picture > img,
.freeBox__g7E4X
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBox__sqfAl {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  margin-bottom: 0px;
  width: 150px;
}
.img > picture > img {
  object-fit: cover;
}
.iconRound:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
@media (min-width: 280px) {
  .iconRound:global(.__wab_instance) {
    position: relative;
    flex-shrink: 0;
  }
}
@media (min-width: 768px) {
  .iconRound:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.freeBox__ne2Ht {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.freeBox__hH6H {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  background: var(--token-gmc_Ctzg2);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.text__pKKvt {
  position: relative;
  color: var(--token-wNURBLgcg);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 8px;
  width: auto;
  text-align: center;
}
@media (min-width: 280px) {
  .text__pKKvt {
    text-align: center;
  }
}
.txtPlan {
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 24px;
  width: 100%;
  min-width: 0;
}
.text__lAwKg {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 280px) {
  .text__lAwKg {
    text-align: center;
  }
}
.text__d9SeE {
  position: relative;
  color: var(--token-wNURBLgcg);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 280px) {
  .text__d9SeE {
    text-align: center;
  }
}
.freeBox__uozna {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.freeBox__uozna > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__uozna > :global(.__wab_flex-container) {
    align-items: flex-end;
    justify-content: center;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__uozna > :global(.__wab_flex-container) > *,
  .freeBox__uozna > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__uozna > :global(.__wab_flex-container) > picture > img,
  .freeBox__uozna
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.paymentDate:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___5Gdg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.submitBtn:global(.__wab_instance) {
  position: relative;
}
.txtButton {
  border-width: 1px;
  border-style: none;
}
.progressBar:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.footerWidget:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}

.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.rootpaymentDate {
  align-items: center;
  justify-content: center;
}
.freeBox__tvs96 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.freeBox__huqRz {
  display: flex;
  position: relative;
  flex-direction: row;
  align-self: center;
}
.freeBox__huqRz > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__huqRz > :global(.__wab_flex-container) > *,
.freeBox__huqRz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__huqRz > :global(.__wab_flex-container) > picture > img,
.freeBox__huqRz
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBox__z7MQo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.day {
  position: relative;
  width: 53px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
}
.freeBox___5IMZv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBoxpaymentDate___5IMZviJbof {
  display: none;
}
.month {
  position: relative;
  width: 70px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
}
.freeBox__aAbV {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBoxshowError__aAbV53A5C {
  display: flex;
}
.freeBoxpaymentDate__aAbViJbof {
  display: none;
}
.year {
  position: relative;
  width: 91px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
}
.yearpaymentDate {
  display: none;
}
.freeBox__no8Xz {
  position: relative;
  flex-direction: row;
  display: none;
  padding: 10px;
}
.freeBox__no8Xz > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__no8Xz > :global(.__wab_flex-container) > *,
.freeBox__no8Xz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__no8Xz > :global(.__wab_flex-container) > picture > img,
.freeBox__no8Xz
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBoxshowError__no8Xz53A5C {
  display: flex;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: var(--token-cE0h0jVeC);
  flex-shrink: 0;
}
.txtError {
  position: relative;
  color: var(--token-cE0h0jVeC);
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  min-width: 0;
}

.root {
  display: flex;
  width: 3em;
  height: 3em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--token-G3sstVR3F);
  position: relative;
  border-radius: 50%;
  padding: 8px;
}
@media (min-width: 380px) {
  .root {
    width: 3em;
    height: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 768px) {
  .root {
    width: 5em;
    height: 5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.rootlightgreen {
  background: var(--token-h1nwR9emp);
}
.rootdarkGreen {
  background: var(--token-vYO6h4FW3);
}
.rootwhite {
  background: var(--token-oWf5ggoEo);
}
.rootstates_selected {
  background: var(--token-h1nwR9emp);
  box-shadow: inset 2px 2px 4px 2px #00000080;
  border: 1px solid var(--token-c79ojIUd1);
}
.rootstates_failed {
  background: var(--token-cE0h0jVeC);
}
.svg___1638H {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-oWf5ggoEo);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  flex-shrink: 0;
}

.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
}
.rooteditField {
  display: flex;
  flex-direction: row;
}
.rooteditField > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rooteditField > :global(.__wab_flex-container) > *,
.rooteditField > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rooteditField > :global(.__wab_flex-container) > picture > img,
.rooteditField
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.freeBox__hg9Uw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.txtValue {
  position: relative;
  width: 100%;
  border-top-width: 0px;
  border-top-style: solid;
  border-left-width: 0px;
  border-left-style: solid;
  border-right-width: 0px;
  border-right-style: solid;
  min-width: 0;
}
.txtValueshowError {
  width: 100%;
  min-width: 0;
}
.freeBox___26HHl {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 10px;
}
.freeBox___26HHl > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox___26HHl > :global(.__wab_flex-container) > *,
.freeBox___26HHl > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___26HHl > :global(.__wab_flex-container) > picture > img,
.freeBox___26HHl
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBoxshowError___26HHl3IpL {
  display: flex;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: var(--token-0UblY07ffKa2jQ);
  flex-shrink: 0;
}
.txtError {
  position: relative;
  color: var(--token-0UblY07ffKa2jQ);
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  min-width: 0;
}
.btnEditA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
  display: none;
}
.btnEditAeditField:global(.__wab_instance) {
  display: flex;
}

.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
}
@media (min-width: 280px) {
  .root {
    display: flex;
    flex-direction: column;
    width: 64px;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-right: 1px solid var(--token-T-yLSgErM);
  }
}
.freeBox__zDlOv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
@media (min-width: 280px) {
  .freeBox__zDlOv {
    justify-content: center;
    align-items: center;
    width: auto;
  }
}
.svg__ruUzl {
  color: var(--token-oWf5ggoEo);
  position: relative;
  object-fit: cover;
  height: 1em;
  flex-shrink: 0;
}
.freeBox__jeiK1 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
@media (min-width: 280px) {
  .slotTargetChildren {
    font-size: 10px;
    color: var(--token-oWf5ggoEo);
  }
}

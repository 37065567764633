.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.freeBox__zigZq {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: var(--token-hKDDQTr7l);
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox___15RP {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: auto;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  border-radius: 4px;
  padding: 8px;
}
.freeBox___15RP > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox___15RP > :global(.__wab_flex-container) > *,
.freeBox___15RP > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___15RP > :global(.__wab_flex-container) > picture > img,
.freeBox___15RP
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .freeBox___15RP {
    width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox___15RP > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 20px);
    height: calc(100% + 20px);
  }
}
@media (min-width: 768px) {
  .freeBox___15RP > :global(.__wab_flex-container) > *,
  .freeBox___15RP > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___15RP > :global(.__wab_flex-container) > picture > img,
  .freeBox___15RP
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 20px;
  }
}
.freeBox__pImG {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__pImG > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__pImG > :global(.__wab_flex-container) > *,
.freeBox__pImG > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pImG > :global(.__wab_flex-container) > picture > img,
.freeBox__pImG
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.iconRound:global(.__wab_instance) {
  position: relative;
  width: 35px;
  height: 35px;
  flex-shrink: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.text {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: center;
  background: none;
  min-width: 0;
  border-radius: 4px;
}
@media (min-width: 380px) {
  .text {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: var(--token-vYO6h4FW3);
    position: relative;
    width: 100%;
    height: auto;
    align-self: center;
    background: none;
    min-width: 0;
    border-radius: 4px;
  }
}
.freeBox__pKtz6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__k9I3W {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__k9I3W > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.freeBox__k9I3W > :global(.__wab_flex-container) > *,
.freeBox__k9I3W > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__k9I3W > :global(.__wab_flex-container) > picture > img,
.freeBox__k9I3W
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
@media (min-width: 280px) {
  .freeBox__k9I3W {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__k9I3W > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 10px);
    height: calc(100% + 10px);
  }
}
@media (min-width: 280px) {
  .freeBox__k9I3W > :global(.__wab_flex-container) > *,
  .freeBox__k9I3W > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__k9I3W > :global(.__wab_flex-container) > picture > img,
  .freeBox__k9I3W
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 10px;
  }
}
.nameOnCard:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.cardType:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .cardType:global(.__wab_instance) {
    max-width: 100%;
    position: relative;
  }
}
.cardNo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .cardNo:global(.__wab_instance) {
    max-width: 100%;
    position: relative;
  }
}
.expiryDate:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .expiryDate:global(.__wab_instance) {
    max-width: 100%;
    position: relative;
  }
}
.cvv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .cvv:global(.__wab_instance) {
    max-width: 100%;
    position: relative;
  }
}
.freeBox__p8U2K {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__ofEVy {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__ofEVy > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.freeBox__ofEVy > :global(.__wab_flex-container) > *,
.freeBox__ofEVy > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ofEVy > :global(.__wab_flex-container) > picture > img,
.freeBox__ofEVy
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.sendCodeBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn3 {
  border-width: 1px;
  border-style: none;
}
.code:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.submitBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn5 {
  border-width: 1px;
  border-style: none;
}
.freeBox__tcadM {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.backBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn4 {
  border-width: 1px;
  border-style: none;
}

.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: var(--token-1Q1zU_qER8IeSd);
  overflow: auto;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox___1OfgC {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: none;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .freeBox___1OfgC {
    background: none;
    height: auto;
  }
}
@media (min-width: 768px) {
  .freeBox___1OfgC {
    background: none;
  }
}
@media (min-width: 1045px) {
  .freeBox___1OfgC {
    background: none;
    height: 100%;
    min-height: 0;
  }
}
.freeBox__desKh {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: none;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .freeBox__desKh {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: url("./../copy_of_imbewu_admin/images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .freeBox__desKh {
    display: flex;
    flex-direction: column;
    background: none;
  }
}
@media (min-width: 1045px) {
  .freeBox__desKh {
    background: none;
    height: 100%;
    min-height: 0;
  }
}
.navPageTitle:global(.__wab_instance) {
  position: relative;
}
.freeBox___418P0 {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  display: none;
  padding: 8px;
}
@media (min-width: 1045px) {
  .freeBox___418P0 {
    display: flex;
  }
}
.freeBox__scp2G {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.sideNavA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 1045px) {
  .sideNavA:global(.__wab_instance) {
    max-width: none;
    width: auto;
  }
}
.freeBox__hmXw1 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox__ttFqb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__ttFqb {
    justify-content: center;
    align-items: flex-start;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
}
.text__cAm4F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .text__cAm4F {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
  }
}
.freeBox___2Qs68 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox___2Qs68 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: none;
    background: var(--token-v5v4b5h_hjqci9);
    padding: 0px;
  }
}
.search2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .search2 {
    display: flex;
    flex-direction: row;
    width: 400px;
    flex-shrink: 0;
  }
}
.inputSearch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.btnSearch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.inputStringA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .inputStringA:global(.__wab_instance) {
    display: none;
  }
}
.freeBox__pvViH {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__pvViH {
    display: flex;
    flex-direction: row;
    width: auto;
  }
}
.btnAddUser:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .btnAddUser:global(.__wab_instance) {
    display: none;
  }
}
.text__ki8S2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__ki8S2 {
    display: none;
  }
}
.userList {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: auto;
  background: var(--token-v5v4b5h_hjqci9);
  min-width: 0;
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.userList > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.userList > :global(.__wab_flex-container) > *,
.userList > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.userList > :global(.__wab_flex-container) > picture > img,
.userList
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
@media (min-width: 768px) {
  .userList {
    padding: 16px;
  }
}
@media (min-width: 768px) {
  .userList > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
  }
}
.listUser:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUser:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__jIgs7:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__jIgs7:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__vt0H2:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__vt0H2:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__wHe4:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__wHe4:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__xnR2:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__xnR2:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers___7XRy1:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers___7XRy1:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__iVkSb:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__iVkSb:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__kGw2U:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__kGw2U:global(.__wab_instance) {
    position: relative;
  }
}

.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #000000b5;
  min-width: 0;
  min-height: 0;
  border-radius: 0px;
}
.freeBox__n6Ydj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  align-self: center;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
  border-radius: 0em;
}
.freeBox__rbknI {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.freeBox__gtHzW {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 45px;
  height: 45px;
  color: var(--token-G3sstVR3F);
  flex-shrink: 0;
}
.freeBox__j47Pe {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.txtModal {
  position: relative;
  color: #ffffff;
  align-self: center;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 10px;
}
.btnClose {
  position: relative;
  object-fit: cover;
  width: 30px;
  height: 30px;
  color: #ffffff;
  align-self: center;
  cursor: pointer;
  top: auto;
  left: auto;
  z-index: 1;
  right: auto;
  flex-shrink: 0;
}

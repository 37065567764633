.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__k3Jk {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .freeBox__k3Jk {
    background: url("./images/lightgreensvg.svg") 0% 0% / contain repeat-x;
  }
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
.freeBox__dwpci {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: 100%;
  min-height: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__dwpci {
    align-self: center;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: none;
    height: 100%;
    min-height: 0;
    padding: 8px;
  }
}
.freeBox__fzA41 {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__fzA41 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__fzA41 > :global(.__wab_flex-container) > *,
.freeBox__fzA41 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fzA41 > :global(.__wab_flex-container) > picture > img,
.freeBox__fzA41
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 280px) {
  .freeBox__fzA41 > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .freeBox__fzA41 {
    width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__fzA41 > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .freeBox__fzA41 > :global(.__wab_flex-container) > *,
  .freeBox__fzA41 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__fzA41 > :global(.__wab_flex-container) > picture > img,
  .freeBox__fzA41
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.freeBox__ej4U1 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__ej4U1 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__ej4U1 > :global(.__wab_flex-container) > *,
.freeBox__ej4U1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ej4U1 > :global(.__wab_flex-container) > picture > img,
.freeBox__ej4U1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.freeBox__w5SIo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.iconRound:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
@media (min-width: 280px) {
  .iconRound:global(.__wab_instance) {
    position: relative;
    flex-shrink: 0;
  }
}
.svg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.text__lgo4B {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 280px) {
  .text__lgo4B {
    text-align: center;
  }
}
.inputId:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.dateOfBirth:global(.__wab_instance) {
  position: relative;
}
.checkTerms:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__lRwX1 {
  font-family: "Poppins", sans-serif;
  width: 100%;
  white-space: pre-wrap;
  font-size: 11px;
  min-width: 0;
}
.checkMarketing:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__qyHu7 {
  font-family: "Poppins", sans-serif;
  width: 100%;
  white-space: pre-wrap;
  font-size: 11px;
  min-width: 0;
}
.freeBox__mdKiS {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.submitBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn3 {
  padding-right: 0px;
  border-width: 1px;
  border-style: none;
}
.progressBar:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.footerWidget:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}

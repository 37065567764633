.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #000000b5;
  min-width: 0;
  min-height: 0;
  border-radius: 0px;
}
.freeBox___2Ed6L {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  align-self: center;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
  border-radius: 0em;
}
.freeBox__mObea {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.freeBox__buY6 {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__buY6 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.freeBox__buY6 > :global(.__wab_flex-container) > *,
.freeBox__buY6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__buY6 > :global(.__wab_flex-container) > picture > img,
.freeBox__buY6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 30px;
}
.iconRoundA__iZfSp:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
.iconRoundApageStatus_success__iZfSp32YV6:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg___7Alrh {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-quptvOxLbYUbhC);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.errorTxt {
  position: relative;
  color: #ffffff;
  align-self: center;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.errorTxtpageStatus_successDependant {
  display: none;
}
.txtReason {
  position: relative;
  width: 100%;
  height: 200px;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  flex-shrink: 0;
}
.btnDecline:global(.__wab_instance) {
  position: relative;
}
.text__wzTa {
  height: auto;
}
.btnClose:global(.__wab_instance) {
  position: relative;
  width: auto;
}
.btnClosepageStatus_successDependant:global(.__wab_instance) {
  display: none;
}
.freeBox__rvcT {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.freeBox__rvcT > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__rvcT > :global(.__wab_flex-container) > *,
.freeBox__rvcT > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__rvcT > :global(.__wab_flex-container) > picture > img,
.freeBox__rvcT
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.iconRoundA__dmRc5:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.svg__qIrVw {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-quptvOxLbYUbhC);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.freeBox__jQs0N {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.errorTxt2 {
  position: relative;
  color: #ffffff;
  align-self: center;
  width: 100%;
  height: auto;
  text-align: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__yCjiM {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 8px;
}
.btnAddAnother:global(.__wab_instance) {
  position: relative;
}
.text__xRdf9 {
  height: auto;
}
.freeBox___71NPb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 8px;
}
.btnClose2:global(.__wab_instance) {
  position: relative;
}
.text__p1Gzd {
  height: auto;
}

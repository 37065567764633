.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 10px;
}
.freeBox___5Sjwd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-width: 0;
  padding: 4px;
}
.text {
  position: relative;
  color: var(--token-wNURBLgcg);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 280px) {
  .text {
    text-align: left;
    position: relative;
    color: var(--token-wNURBLgcg);
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .text {
    text-align: left;
  }
}
.freeBox__joY7Z {
  display: flex;
  position: relative;
  flex-direction: row;
  align-self: center;
  width: 100%;
  min-width: 0;
}
.freeBox__joY7Z > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__joY7Z > :global(.__wab_flex-container) > *,
.freeBox__joY7Z > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__joY7Z > :global(.__wab_flex-container) > picture > img,
.freeBox__joY7Z
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.cardCvv {
  position: relative;
  width: 53px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  flex-shrink: 0;
  border-radius: 5px;
}
.btnHelp:global(.__wab_instance) {
  position: relative;
}
.freeBox___3Cb8R {
  position: relative;
  flex-direction: row;
  display: none;
  padding: 10px;
}
.freeBox___3Cb8R > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox___3Cb8R > :global(.__wab_flex-container) > *,
.freeBox___3Cb8R > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___3Cb8R > :global(.__wab_flex-container) > picture > img,
.freeBox___3Cb8R
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBoxshowError___3Cb8Ry5A5D {
  display: flex;
}
.freeBoxhelpmodal___3Cb8RXjuof {
  display: none;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: var(--token-cE0h0jVeC);
  flex-shrink: 0;
}
.txtError {
  position: relative;
  color: var(--token-cE0h0jVeC);
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  min-width: 0;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  display: none !important;
}
.img > picture > img {
  object-fit: cover;
}
.imghelpmodal {
  width: 162px;
  min-height: 155px;
  position: absolute;
  left: -3px;
  top: -153px;
  flex-shrink: 0;
  display: block !important;
}

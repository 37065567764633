.root {
  background: var(--token-vYO6h4FW3);
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  min-width: 0;
  border-radius: 4px;
  padding: 12px;
}
.root > :global(.__wab_flex-container) {
  justify-content: center;
  flex-direction: row;
  min-width: 0;
}
.rootghostGreen {
  background: none;
  border: 1px solid var(--token-vYO6h4FW3);
}
.rootghostWhite {
  background: none;
  border: 1px solid var(--token-oWf5ggoEo);
}
.rootloading > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootloading > :global(.__wab_flex-container) > *,
.rootloading > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rootloading > :global(.__wab_flex-container) > picture > img,
.rootloading
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
  margin-top: 0px;
}
.rootdisabled {
  background: var(--token-hKDDQTr7l);
}
.rootbtnState_disabled {
  background: var(--token-hKDDQTr7l);
}
.rootbtnState_success {
  background: #67bda4;
}
.rootbtnState_success > :global(.__wab_flex-container) {
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootbtnState_success > :global(.__wab_flex-container) > *,
.rootbtnState_success
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootbtnState_success > :global(.__wab_flex-container) > picture > img,
.rootbtnState_success
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.rootsmall_green {
  background: var(--token-h1nwR9emp);
  width: auto;
  display: inline-flex;
  padding: 4px 8px;
}
.rootsmall_brown {
  width: auto;
  height: auto;
  background: var(--token-vYO6h4FW3);
  flex-direction: row;
  display: inline-flex;
  border-radius: 4px;
  padding: 4px 8px;
}
.rootsmall_brown > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootsmall_brown > :global(.__wab_flex-container) > *,
.rootsmall_brown > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rootsmall_brown > :global(.__wab_flex-container) > picture > img,
.rootsmall_brown
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.rootsmall_lightGreen {
  width: auto;
  height: auto;
  background: var(--token-G3sstVR3F);
  flex-direction: row;
  display: inline-flex;
  border-radius: 4px;
  padding: 4px 8px;
}
.rootsmall_lightGreen > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootsmall_lightGreen > :global(.__wab_flex-container) > *,
.rootsmall_lightGreen
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootsmall_lightGreen > :global(.__wab_flex-container) > picture > img,
.rootsmall_lightGreen
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.root:hover {
  background: var(--token-c79ojIUd1);
}
.slotTargetTxt {
  color: var(--token-oWf5ggoEo);
  text-align: center;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 11px;
  font-weight: 700;
}
.slotTargetTxtghostGreen {
  color: var(--token-vYO6h4FW3);
}
.slotTargetTxtdisabled {
  color: var(--token-hl3Zddiwv);
}
.slotTargetTxtsmall_green {
  font-size: 9px;
}
.slotTargetTxtsmall_brown {
  font-size: 9px;
}
.slotTargetTxtsmall_lightGreen {
  font-size: 9px;
  color: var(--token-vYO6h4FW3);
}
.svg__tji7G {
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svgbtnState_success__tji7GAeDv {
  color: var(--token-oWf5ggoEo);
}
.svg___2Yyf0 {
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svgloading___2Yyf0Xrubq {
  color: var(--token-oWf5ggoEo);
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.freeBox___0T0QJ {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-B2pEY5bfC);
  min-width: 0;
  padding: 8px;
}
.freeBox___0T0QJ > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.freeBox___0T0QJ > :global(.__wab_flex-container) > *,
.freeBox___0T0QJ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___0T0QJ > :global(.__wab_flex-container) > picture > img,
.freeBox___0T0QJ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.text__hdXyq {
  position: relative;
  width: auto;
  height: auto;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.latestPayment {
  position: relative;
  width: auto;
  height: auto;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.text__lwEK {
  position: relative;
  width: auto;
  height: auto;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.nextPayment {
  position: relative;
  width: auto;
  height: auto;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.text__voDR {
  position: relative;
  width: auto;
  height: auto;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.nextPayout {
  position: relative;
  width: auto;
  height: auto;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.btnActive:global(.__wab_instance) {
  max-width: 120px;
  position: absolute;
  left: auto;
  top: auto;
  right: 10px;
}
@media (min-width: 280px) {
  .btnActive:global(.__wab_instance) {
    align-self: center;
    width: 100%;
    max-width: 100%;
    position: relative;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .btnActive:global(.__wab_instance) {
    align-self: center;
    width: 100%;
    max-width: 100%;
    position: relative;
    min-width: 0;
  }
}
@media (min-width: 1045px) {
  .btnActive:global(.__wab_instance) {
    max-width: 80px;
  }
}
@media (min-width: 768px) {
  .text__hana8 {
    padding-left: 0px;
  }
}
.freeBox__pl8A {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 16px;
  border-bottom: 1px solid var(--token-QC3013x5Iygz2_);
}
.text__aF3Hl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 18px;
  min-width: 0;
}
.addClaim:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.scroller {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: auto;
  max-height: 480px;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.scroller > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.scroller > :global(.__wab_flex-container) > *,
.scroller > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.scroller > :global(.__wab_flex-container) > picture > img,
.scroller
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
@media (min-width: 768px) {
  .scroller > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
  }
}
.listItemDependentBeneficicaryA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}

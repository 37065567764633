.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 10px;
}
.freeBox__h405J {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 4px;
}
.freeBox__h405J > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__h405J > :global(.__wab_flex-container) > *,
.freeBox__h405J > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__h405J > :global(.__wab_flex-container) > picture > img,
.freeBox__h405J
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.freeBox__egUzZ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.text {
  position: relative;
  color: var(--token-wNURBLgcg);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 280px) {
  .text {
    text-align: left;
    position: relative;
    color: var(--token-wNURBLgcg);
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    min-width: 0;
  }
}
.freeBox__f0AQt {
  display: flex;
  position: relative;
  flex-direction: row;
  align-self: center;
  width: 100%;
  min-width: 0;
}
.freeBox__f0AQt > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__f0AQt > :global(.__wab_flex-container) > *,
.freeBox__f0AQt > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__f0AQt > :global(.__wab_flex-container) > picture > img,
.freeBox__f0AQt
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.cardFirst4 {
  position: relative;
  width: 53px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  flex-shrink: 0;
  border-radius: 5px;
}
.cardSecond4 {
  position: relative;
  width: 53px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  flex-shrink: 0;
  border-radius: 5px;
}
.cardThird4 {
  position: relative;
  width: 53px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  flex-shrink: 0;
  border-radius: 5px;
}
.cardFourth4 {
  position: relative;
  width: 53px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  flex-shrink: 0;
  border-radius: 5px;
}
.freeBox__lq8Bv {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 10px;
}
.freeBox__lq8Bv > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__lq8Bv > :global(.__wab_flex-container) > *,
.freeBox__lq8Bv > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lq8Bv > :global(.__wab_flex-container) > picture > img,
.freeBox__lq8Bv
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBoxshowError__lq8BvKpIx5 {
  display: none;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: var(--token-cE0h0jVeC);
  flex-shrink: 0;
}
.txtError {
  position: relative;
  color: var(--token-cE0h0jVeC);
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  min-width: 0;
}

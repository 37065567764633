.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__rS4Om {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .freeBox__rS4Om {
    background: url("./images/lightgreensvg.svg") 0% 0% / contain repeat-x;
  }
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
.freeBox__kLzJc {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: 100%;
  min-height: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__kLzJc {
    align-self: center;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: none;
    height: 100%;
    min-height: 0;
    padding: 8px;
  }
}
.freeBox__l3NsZ {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__l3NsZ > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__l3NsZ > :global(.__wab_flex-container) > *,
.freeBox__l3NsZ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__l3NsZ > :global(.__wab_flex-container) > picture > img,
.freeBox__l3NsZ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 280px) {
  .freeBox__l3NsZ {
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    height: 100%;
    box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__l3NsZ > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 280px) {
  .freeBox__l3NsZ > :global(.__wab_flex-container) > *,
  .freeBox__l3NsZ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__l3NsZ > :global(.__wab_flex-container) > picture > img,
  .freeBox__l3NsZ
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
@media (min-width: 768px) {
  .freeBox__l3NsZ {
    width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__l3NsZ > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 768px) {
  .freeBox__l3NsZ > :global(.__wab_flex-container) > *,
  .freeBox__l3NsZ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__l3NsZ > :global(.__wab_flex-container) > picture > img,
  .freeBox__l3NsZ
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.freeBox__xttv4 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__xttv4 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__xttv4 > :global(.__wab_flex-container) > *,
.freeBox__xttv4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xttv4 > :global(.__wab_flex-container) > picture > img,
.freeBox__xttv4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
@media (min-width: 280px) {
  .freeBox__xttv4 {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__xttv4 > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__xttv4 > :global(.__wab_flex-container) > *,
  .freeBox__xttv4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__xttv4 > :global(.__wab_flex-container) > picture > img,
  .freeBox__xttv4
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.text__aK4Qm {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__aK4Qm {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 21px;
  }
}
.freeBox___14CoF {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
@media (min-width: 280px) {
  .freeBox___14CoF {
    justify-content: center;
    align-items: center;
    align-self: center;
    display: flex;
    position: relative;
    flex-direction: column;
  }
}
.iconRound:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
@media (min-width: 280px) {
  .iconRound:global(.__wab_instance) {
    position: relative;
    flex-shrink: 0;
  }
}
.svg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.text__ir8VK {
  position: relative;
  text-align: center;
}
@media (min-width: 280px) {
  .text__ir8VK {
    width: 100%;
    position: relative;
    text-align: center;
    min-width: 0;
  }
}
.accountName:global(.__wab_instance) {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .accountName:global(.__wab_instance) {
    height: auto;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
.accountNo:global(.__wab_instance) {
  position: relative;
  display: none;
}
@media (min-width: 280px) {
  .accountNo:global(.__wab_instance) {
    display: flex;
  }
}
.selectBank:global(.__wab_instance) {
  position: relative;
  display: none;
}
@media (min-width: 280px) {
  .selectBank:global(.__wab_instance) {
    display: flex;
  }
}
.accountType:global(.__wab_instance) {
  position: relative;
  display: none;
}
@media (min-width: 280px) {
  .accountType:global(.__wab_instance) {
    display: flex;
  }
}
.branchCode:global(.__wab_instance) {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .branchCode:global(.__wab_instance) {
    height: auto;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
.freeBox__dv7A {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__dv7A > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__dv7A > :global(.__wab_flex-container) > *,
.freeBox__dv7A > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dv7A > :global(.__wab_flex-container) > picture > img,
.freeBox__dv7A
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.submitBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn3 {
  border-width: 1px;
  border-style: none;
}
.btnSkip:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__cRfCv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__fQr4O {
  height: auto;
}
.svg__gw3J8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.progressBar:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.footerWidget:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}

.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  background: var(--token-oWf5ggoEo);
  box-shadow: 0px 0px 12px 2px #00000033;
  min-width: 4px;
  max-width: 300px;
  border-radius: 8px;
  padding: 16px;
}
.freeBox__t27PA {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 16px;
}
.freeBox___1Epm3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.freeBox___2W96 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.freeBox___2W96 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox___2W96 > :global(.__wab_flex-container) > *,
.freeBox___2W96 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___2W96 > :global(.__wab_flex-container) > picture > img,
.freeBox___2W96
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.freeBox__mcAEz {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--token-G3sstVR3F);
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 50%;
}
.slotTargetSlot {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: var(--token-wNURBLgcg);
}
.freeBox___9DwOv {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0px;
}
@media (min-width: 380px) {
  .freeBox___9DwOv {
    position: relative;
    padding: 0px;
  }
}
@media (min-width: 768px) {
  .freeBox___9DwOv {
    position: relative;
    padding: 0px;
  }
}
.slotTargetChildren {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 600;
  color: var(--token-wNURBLgcg);
}
@media (min-width: 380px) {
  .slotTargetChildren {
    font-family: "Poppins", sans-serif;
    font-size: 1em;
    font-weight: 600;
    color: var(--token-wNURBLgcg);
  }
}
@media (min-width: 768px) {
  .slotTargetChildren {
    font-family: "Poppins", sans-serif;
    font-size: 1.5em;
    font-weight: 600;
    color: var(--token-wNURBLgcg);
  }
}
.freeBox__wbe7T {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px;
}
@media (min-width: 280px) {
  .freeBox__wbe7T {
    padding: 16px;
  }
}
.img__cvNUx {
  position: relative;
  object-fit: cover;
}
.img__cvNUx > picture > img {
  object-fit: cover;
}
.freeBox__aQsNw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0px;
}
.slotTargetSlot3 {
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  color: var(--token-vYO6h4FW3);
}
@media (min-width: 280px) {
  .slotTargetSlot3 {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }
}

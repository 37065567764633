.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
  padding: 16px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
.profileImg {
  position: relative;
  object-fit: cover;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 50%;
}
.profileImg > picture > img {
  object-fit: cover;
}
.freeBox__g1O2C {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: none;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-width: 0;
}
.name {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: var(--token-vYO6h4FW3);
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  min-width: 0;
  min-height: 0;
}
.memberType {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 700;
  color: var(--token-c79ojIUd1);
  min-width: 0;
  min-height: 0;
}
.freeBox___2VgXi {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
@media (min-width: 280px) {
  .freeBox___2VgXi {
    display: flex;
  }
}
.text__mn3Ph {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
@media (min-width: 280px) {
  .text__mn3Ph {
    font-size: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    width: auto;
    padding-right: 10px;
  }
}
.btnProfile:global(.__wab_instance) {
  position: relative;
}
.text__oH0Hl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-size: 9px;
  min-width: 0;
}

.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  background: none;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox {
    background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 768px) {
  .freeBox {
    background: url("./images/lightgreensvg.svg") 0% 0% / contain repeat-x;
  }
}
.navPageTitle:global(.__wab_instance) {
  position: relative;
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 700px;
  min-width: 0;
  padding: 32px 0px 16px;
}
@media (min-width: 280px) {
  .outer {
    height: 100%;
    min-height: 0;
  }
}
.faqList {
  display: flex;
  position: relative;
  flex-direction: column;
  background: none;
  height: auto;
  padding: 8px;
}
.faqList > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.faqList > :global(.__wab_flex-container) > *,
.faqList > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.faqList > :global(.__wab_flex-container) > picture > img,
.faqList
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
@media (min-width: 280px) {
  .faqList {
    height: auto;
  }
}
.faqMainItem___8OZv:global(.__wab_instance) {
  position: relative;
}
.faqMainItem__h9ARd:global(.__wab_instance) {
  position: relative;
}
.faqMainItem__zlEu:global(.__wab_instance) {
  position: relative;
}
.footerWidget:global(.__wab_instance) {
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 1;
}

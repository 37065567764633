.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: 100%;
  min-height: 0;
}
.navHolder {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: var(--token-h1nwR9emp);
  width: 300px;
}
.menuItem__irhtW:global(.__wab_instance) {
  position: relative;
}
.menuItem__qhOi2:global(.__wab_instance) {
  position: relative;
}
.menuItem__t8Wt9:global(.__wab_instance) {
  position: relative;
}
.menuItem__ng8Ku:global(.__wab_instance) {
  position: relative;
}
.freeBox {
  display: flex;
  position: sticky;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-h1nwR9emp);
  left: 0px;
  top: auto;
  z-index: 1;
  bottom: 0px;
  min-width: 0;
  padding: 8px;
}
.versionTxt {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  padding-right: 0px;
}

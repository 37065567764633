.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 768px) {
  .root {
    display: flex;
    flex-direction: row;
    max-width: none;
    background: var(--token-v5v4b5h_hjqci9);
    width: 100%;
    height: auto;
    min-width: 0;
    padding: 8px 4px 0px 8px;
  }
}
@media (min-width: 768px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-width: 0;
    margin-left: calc(0px - 2px);
    width: calc(100% + 2px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 2px;
    margin-top: 0px;
  }
}
.userProfileTab:global(.__wab_instance) {
  position: relative;
}
.dependentsTab:global(.__wab_instance) {
  position: relative;
}
.beneficiariesTab:global(.__wab_instance) {
  position: relative;
}
.claimsTab:global(.__wab_instance) {
  position: relative;
}
.payoutsTab:global(.__wab_instance) {
  position: relative;
}
.paymentsTab:global(.__wab_instance) {
  position: relative;
}

.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.planHolder {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: 100%;
  align-self: flex-start;
  overflow: hidden;
  min-height: 0;
  padding: 16px;
}
.planHolder > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.planHolder > :global(.__wab_flex-container) > *,
.planHolder > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.planHolder > :global(.__wab_flex-container) > picture > img,
.planHolder
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
@media (min-width: 768px) {
  .planHolder {
    width: 3540px;
  }
}
.cardPlanA__cgcxX:global(.__wab_instance) {
  position: relative;
}
.img__wsVhL {
  position: relative;
  object-fit: contain;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.img__wsVhL > picture > img {
  object-fit: contain;
}
.cardPlanA__k75Xz:global(.__wab_instance) {
  position: relative;
}
.img__cBnQk {
  position: relative;
  object-fit: contain;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.img__cBnQk > picture > img {
  object-fit: contain;
}
.cardPlanA___9BhfV:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 280px) {
  .cardPlanA___9BhfV:global(.__wab_instance) {
    display: none;
  }
}
.img__j7ZIi {
  position: relative;
  object-fit: contain;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.img__j7ZIi > picture > img {
  object-fit: contain;
}

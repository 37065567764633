.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-G3sstVR3F);
  position: relative;
  min-width: 0;
  padding: 8px;
}
.paymentDate {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 600;
  min-width: 0;
}
.amountTxt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: right;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  min-width: 0;
}

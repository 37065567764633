.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.freeBox__v3Lg7 {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: 0px;
  top: 0px;
  background: var(--token-hKDDQTr7l);
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__v3Lg7 {
    justify-content: center;
    align-items: center;
  }
}
.freeBox__p1I8S {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: auto;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__p1I8S {
    align-self: center;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: none;
    height: 100%;
    min-height: 0;
    padding: 8px;
  }
}
.freeBox__jiilw {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__jiilw > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__jiilw > :global(.__wab_flex-container) > *,
.freeBox__jiilw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jiilw > :global(.__wab_flex-container) > picture > img,
.freeBox__jiilw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .freeBox__jiilw {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
    max-width: 90%;
    max-height: 90%;
    min-width: 0;
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__jiilw > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 0;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 20px);
    height: calc(100% + 20px);
  }
}
@media (min-width: 768px) {
  .freeBox__jiilw > :global(.__wab_flex-container) > *,
  .freeBox__jiilw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__jiilw > :global(.__wab_flex-container) > picture > img,
  .freeBox__jiilw
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 20px;
  }
}
.freeBox__nGcRc {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__nGcRc > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__nGcRc > :global(.__wab_flex-container) > *,
.freeBox__nGcRc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__nGcRc > :global(.__wab_flex-container) > picture > img,
.freeBox__nGcRc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: center;
  background: none;
  min-width: 0;
  border-radius: 4px;
}
@media (min-width: 380px) {
  .text {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: var(--token-vYO6h4FW3);
    position: relative;
    width: 100%;
    height: auto;
    align-self: center;
    background: none;
    min-width: 0;
    border-radius: 4px;
  }
}
.imageHolder {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .imageHolder {
    height: 100%;
    max-height: 700px;
    max-width: 700px;
    justify-content: center;
    align-items: center;
    min-height: 0;
  }
}
.freeBox__wQe1K {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.backBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn4 {
  border-width: 1px;
  border-style: none;
}

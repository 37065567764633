.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__fu8Kj {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  background: none;
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid var(--token-1Q1zU_qER8IeSd);
}
.svg__twuU3 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.btnMenuItem {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  background: none;
  cursor: pointer;
  min-width: 0;
  padding: 10px;
  border-bottom: 1px solid var(--token-1Q1zU_qER8IeSd);
}
.slotTargetTxtMenu {
  color: var(--token-quptvOxLbYUbhC);
}
.freeBox__mQ1Xd {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  background: none;
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid var(--token-1Q1zU_qER8IeSd);
}
.iconNumberA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
  display: none;
}
@media (min-width: 1045px) {
  .iconNumberA:global(.__wab_instance) {
    flex-shrink: 0;
  }
}

.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: var(--token-1Q1zU_qER8IeSd);
  overflow: auto;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__fRon6 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: none;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .freeBox__fRon6 {
    background: none;
    height: auto;
  }
}
@media (min-width: 768px) {
  .freeBox__fRon6 {
    background: none;
  }
}
@media (min-width: 1045px) {
  .freeBox__fRon6 {
    background: none;
    height: 100%;
    min-height: 0;
  }
}
.freeBox__gLoAb {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: none;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .freeBox__gLoAb {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .freeBox__gLoAb {
    display: flex;
    flex-direction: column;
    background: none;
  }
}
@media (min-width: 1045px) {
  .freeBox__gLoAb {
    background: none;
    height: 100%;
    min-height: 0;
  }
}
.navPageTitle:global(.__wab_instance) {
  position: relative;
}
.freeBox__fsuoO {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  display: none;
  padding: 8px;
}
@media (min-width: 1045px) {
  .freeBox__fsuoO {
    display: flex;
  }
}
.freeBox__zUq1R {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.sideNavA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 1045px) {
  .sideNavA:global(.__wab_instance) {
    max-width: none;
    width: auto;
  }
}
.freeBox__uyBoZ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox___0IT0V {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox___0IT0V {
    justify-content: center;
    align-items: flex-start;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
}
.text___0Z3Mz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .text___0Z3Mz {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
  }
}
.freeBox__zr5QE {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__zr5QE {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: none;
    background: var(--token-v5v4b5h_hjqci9);
    padding: 0px;
  }
}
.search2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .search2 {
    display: flex;
    flex-direction: row;
    width: 400px;
    flex-shrink: 0;
  }
}
.inputSearch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.btnSearch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.inputStringA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .inputStringA:global(.__wab_instance) {
    display: none;
  }
}
.freeBox__qzhlt {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__qzhlt {
    flex-direction: row;
    width: auto;
    display: none;
  }
}
.btnBulk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__tKsVo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__isXyo {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__isXyo > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 768px) {
  .freeBox__isXyo {
    display: flex;
    flex-direction: row;
    width: auto;
  }
}
@media (min-width: 768px) {
  .freeBox__isXyo > :global(.__wab_flex-container) {
    flex-direction: row;
    margin-left: calc(0px - 10px);
    width: calc(100% + 10px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .freeBox__isXyo > :global(.__wab_flex-container) > *,
  .freeBox__isXyo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__isXyo > :global(.__wab_flex-container) > picture > img,
  .freeBox__isXyo
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 10px;
    margin-top: 0px;
  }
}
.btnExport:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__us5Kx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__us5Kx {
    width: 100px;
    flex-shrink: 0;
  }
}
.btnAddUser2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__en6Hf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.userList {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: auto;
  background: var(--token-v5v4b5h_hjqci9);
  min-width: 0;
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.userList > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.userList > :global(.__wab_flex-container) > *,
.userList > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.userList > :global(.__wab_flex-container) > picture > img,
.userList
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
@media (min-width: 768px) {
  .userList {
    padding: 16px;
  }
}
@media (min-width: 768px) {
  .userList > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
  }
}
.listUser:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUser:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers___5JsJj:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers___5JsJj:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__ceq0P:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__ceq0P:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__hPl00:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__hPl00:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__f7TTo:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__f7TTo:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__t7IMz:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__t7IMz:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__aCB:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__aCB:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__xEzQm:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__xEzQm:global(.__wab_instance) {
    position: relative;
  }
}

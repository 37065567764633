.root {
  display: flex;
  flex-direction: row;
  position: relative;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
.slotTargetChildren {
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 500;
  color: var(--token-hl3Zddiwv);
}
.iconRound:global(.__wab_instance) {
  position: relative;
  width: 38px;
  height: 38px;
  flex-shrink: 0;
}
.svg__i41Pe {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}

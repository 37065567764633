.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.trigger {
  position: relative;
  display: flex;
  flex-direction: row;
  background: #fafafa;
  border-radius: 4px;
  padding: 3px 8px;
  border: 1px solid #e5e5ea;
}
.trigger > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.trigger > :global(.__wab_flex-container) > *,
.trigger > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.trigger > :global(.__wab_flex-container) > picture > img,
.trigger
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.triggerisOpen {
  background: #f2f2f7;
}
.triggerisDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.root:focus-within .trigger {
  box-shadow: 0px 0px 0px 2px #007aff80;
  outline: none;
}
.root .trigger___focusVisibleWithin {
  outline: none;
  border-color: #007aff;
}
.root:hover:not(:focus-within) .trigger {
  background: #f2f2f7;
  outline: none;
}
.contentContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.slotTargetSelectedContent {
  white-space: pre;
}
.slotTargetSelectedContent > :global(.__wab_text),
.slotTargetSelectedContent > :global(.__wab_expr_html_text),
.slotTargetSelectedContent > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetSelectedContent > *,
.slotTargetSelectedContent > :global(.__wab_slot) > *,
.slotTargetSelectedContent > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetSelectedContent > picture > img,
.slotTargetSelectedContent > :global(.__wab_slot) > picture > img,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.root:focus-within .slotTargetSelectedContent > *,
.root:focus-within .slotTargetSelectedContent > :global(.__wab_slot) > *,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within .slotTargetSelectedContent > picture > img,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.slotTargetPlaceholder {
  white-space: pre;
  color: #00000080;
}
.slotTargetPlaceholder > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_expr_html_text),
.slotTargetPlaceholder > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetPlaceholder > *,
.slotTargetPlaceholder > :global(.__wab_slot) > *,
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetPlaceholder > picture > img,
.slotTargetPlaceholder > :global(.__wab_slot) > picture > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.dropdownIcon {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.root .dropdownIcon___focusVisibleWithin {
  outline: none;
}
.overlay:global(.__wab_instance) {
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: 1000;
}
.optionsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  overflow: auto;
}
.optionA__eeh7A:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.optionA__ho6Ts:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}

.root {
  display: flex;
  flex-direction: row;
  background: var(--token-quptvOxLbYUbhC);
  box-shadow: none;
  position: relative;
  width: 100%;
  border-bottom-width: 1px;
  min-width: 0;
  padding: 8px;
  border-style: solid;
  border-color: var(--token-quptvOxLbYUbhC);
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
  margin-top: 0px;
}
@media (min-width: 280px) {
  .root > :global(.__wab_flex-container) {
    margin-left: calc(0px - 20px);
    width: calc(100% + 20px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 20px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .root {
    box-shadow: none;
    border-bottom-width: 1px;
    background: var(--token-quptvOxLbYUbhC);
    padding: 8px;
    border-style: solid;
    border-color: var(--token-quptvOxLbYUbhC);
  }
}
@media (min-width: 380px) {
  .root > :global(.__wab_flex-container) {
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
@media (min-width: 280px) {
  .freeBox > :global(.__wab_flex-container) {
    margin-left: calc(0px - 10px);
    width: calc(100% + 10px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox > :global(.__wab_flex-container) > *,
  .freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox > :global(.__wab_flex-container) > picture > img,
  .freeBox
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 10px;
    margin-top: 0px;
  }
}
.title {
  position: relative;
  color: var(--token-1Q1zU_qER8IeSd);
  font-size: 12px;
}

.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__g2K4K {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 768px) {
  .freeBox__g2K4K {
    background: url("./images/lightgreensvg.svg") 0% 0% / contain repeat-x;
  }
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
.freeBox__qxbap {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: 100%;
  min-height: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__qxbap {
    align-self: center;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: none;
    height: 100%;
    min-height: 0;
    padding: 8px;
  }
}
.freeBox__mplgn {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__mplgn > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__mplgn > :global(.__wab_flex-container) > *,
.freeBox__mplgn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mplgn > :global(.__wab_flex-container) > picture > img,
.freeBox__mplgn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .freeBox__mplgn {
    width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__mplgn > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .freeBox__mplgn > :global(.__wab_flex-container) > *,
  .freeBox__mplgn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__mplgn > :global(.__wab_flex-container) > picture > img,
  .freeBox__mplgn
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.text__gBrW9 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.inputFirstName:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.inputLastName:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.inputGender:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.text___1CVDb {
  position: relative;
  margin-top: calc(10px + 20px) !important;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  color: var(--token-T-yLSgErM);
  font-size: 14px;
}
.inputCell:global(.__wab_instance) {
  position: relative;
  align-self: center;
  width: 100%;
  min-width: 0;
}
.inputEmail:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.inputPassword:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.inputConfirm:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__c5299 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.submitBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn2 {
  border-width: 1px;
  border-style: none;
}
.freeBox__t8Bav {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.backBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn4 {
  border-width: 1px;
  border-style: none;
}
.freeBox__ncXij {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__ncXij {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: 0;
    padding: 8px;
  }
}
.forgotBtn:global(.__wab_instance) {
  position: relative;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.footerWidget:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}

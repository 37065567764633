.root {
  background: var(--token-quptvOxLbYUbhC);
  width: auto;
  height: auto;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 4px 8px;
}
.root > :global(.__wab_flex-container) {
  justify-content: center;
  flex-direction: row;
}
@media (min-width: 380px) {
  .root {
    background: var(--token-1IgY_610qjxdAO);
  }
}
.rootghostGreen {
  background: none;
  border: 1px solid var(--token-quptvOxLbYUbhC);
}
.rootghostWhite {
  background: none;
  border: 1px solid var(--token-1Q1zU_qER8IeSd);
}
.rootloading > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootloading > :global(.__wab_flex-container) > *,
.rootloading > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rootloading > :global(.__wab_flex-container) > picture > img,
.rootloading
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.rootdisabled {
  background: var(--token-Zqimzys_LRL3mw);
}
.rootbtnState_disabled {
  background: var(--token-Zqimzys_LRL3mw);
}
.rootbtnState_success {
  background: #67bda4;
}
.rootbtnState_success > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootbtnState_success > :global(.__wab_flex-container) > *,
.rootbtnState_success
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootbtnState_success > :global(.__wab_flex-container) > picture > img,
.rootbtnState_success
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.rootbtnState_failed {
  background: #ff8900;
}
.rootbtnState_failed > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: flex-start;
}
.rootbtnState_declined {
  background: var(--token-eJM0EpiBiFJp2s);
}
.root:hover {
  background: var(--token-1IgY_610qjxdAO);
}
.slotTargetTxt {
  color: var(--token-1Q1zU_qER8IeSd);
  text-align: center;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 9px;
  font-weight: 500;
}
.slotTargetTxtghostGreen {
  color: var(--token-quptvOxLbYUbhC);
}
.slotTargetTxtdisabled {
  color: var(--token-TZwOGIB9DUOwST);
}
.slotTargetTxtbtnState_success {
  line-height: 1;
}
.svg__mt9Wn {
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svgbtnState_success__mt9Wnp8D {
  color: var(--token-1Q1zU_qER8IeSd);
}
.svgbtnState_failed__mt9Wn6JC6S {
  color: var(--token-1Q1zU_qER8IeSd);
  display: block;
}
.svg__l90Nb {
  position: relative;
  object-fit: cover;
  height: 1em;
}
.svgloading__l90NbMtU {
  color: var(--token-1Q1zU_qER8IeSd);
  width: 24px;
  height: 8px;
  flex-shrink: 0;
}

.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.img {
  position: relative;
  object-fit: cover;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 50%;
}
.img > picture > img {
  object-fit: cover;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.nameTxt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 11px;
  min-width: 0;
}
.coverTxt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 9px;
  min-width: 0;
}
.btnEdit:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.btnEditeditAndSave:global(.__wab_instance) {
  display: none;
}
.btnDelete:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.btnDeleteeditAndSave:global(.__wab_instance) {
  display: none;
}
.btnDeletesaved:global(.__wab_instance) {
  display: none;
}
.text {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 11px;
  text-align: right;
  margin-left: calc(0px + 8px) !important;
  min-width: 40px;
}
.texteditAndSave {
  margin-left: calc(0px + 8px) !important;
}
.saved2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.saved2editAndSave:global(.__wab_instance) {
  flex-shrink: 0;
  display: flex;
}
.saved2saved:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: var(--token-oWf5ggoEo);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.saved:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: none;
}
.savededitAndSave:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.savedsaved:global(.__wab_instance) {
  flex-shrink: 0;
  display: flex;
}
.svg__syk9S {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-oWf5ggoEo);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}

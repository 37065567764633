.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  background: #ffffff;
  min-width: 0;
  padding: 16px;
  border-bottom: 1px solid var(--token-gmc_Ctzg2);
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.freeBox__kqXqo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__ooId7 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBoxclosed__ooId7BKjsO {
  height: 100%;
  min-height: 0;
}
.headline {
  position: relative;
  width: 100%;
  height: 20px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  min-width: 0;
}
.headlineclosed {
  height: 100%;
  min-height: 0;
}
.btnOpen:global(.__wab_instance) {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: var(--token-oWf5ggoEo);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.freeBox__fm1NN {
  position: relative;
  flex-direction: row;
  background: var(--token-h1nwR9emp);
  width: 360px;
  display: none;
  padding: 16px;
  border-bottom: 1px solid var(--token-gmc_Ctzg2);
}
.freeBox__fm1NN > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__fm1NN > :global(.__wab_flex-container) > *,
.freeBox__fm1NN > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fm1NN > :global(.__wab_flex-container) > picture > img,
.freeBox__fm1NN
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBoxclosed__fm1NNbKjsO {
  display: flex;
}
.content {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--token-vYO6h4FW3);
  min-width: 0;
  min-height: 0;
}

.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  overflow: auto;
  min-width: 0;
}
.cardHolder {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-self: center;
  overflow: auto;
  min-width: 0;
  padding: 16px;
}
.cardHolder > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.cardHolder > :global(.__wab_flex-container) > *,
.cardHolder > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.cardHolder > :global(.__wab_flex-container) > picture > img,
.cardHolder
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
@media (min-width: 380px) {
  .cardHolder {
    width: 100%;
    align-self: flex-start;
    min-width: 0;
  }
}
@media (min-width: 380px) {
  .cardHolder > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .cardHolder {
    width: 3540px;
  }
}
.cardHowtoItem__wPj5C:global(.__wab_instance) {
  position: relative;
  width: 290px;
  height: 100%;
  left: auto;
  top: auto;
  flex-shrink: 0;
  min-height: 0;
}
.img {
  position: relative;
  object-fit: cover;
}
.img > picture > img {
  object-fit: cover;
}
.cardHowtoItem__l95Qi:global(.__wab_instance) {
  position: relative;
  width: 290px;
  height: 100%;
  left: auto;
  top: auto;
  flex-shrink: 0;
  min-height: 0;
}
.img__fgcde {
  position: relative;
  object-fit: cover;
}
.img__fgcde > picture > img {
  object-fit: cover;
}
.cardHowtoItem__wu6Wc:global(.__wab_instance) {
  position: relative;
  width: 290px;
  height: 100%;
  left: auto;
  top: auto;
  flex-shrink: 0;
  min-height: 0;
}
.text {
  width: 117px;
}
.img__iyc9K {
  position: relative;
  object-fit: cover;
}
.img__iyc9K > picture > img {
  object-fit: cover;
}
.freeBox__o97 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 16px;
}
.freeBox__o97 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__o97 > :global(.__wab_flex-container) > *,
.freeBox__o97 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__o97 > :global(.__wab_flex-container) > picture > img,
.freeBox__o97
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.dotSliderItem__quh7F:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
.dotSliderItem__k6N9N:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
.dotSliderItem___57Vx:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
.freeBox___1MNj3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 0;
  padding: 16px;
}
.btnSignup:global(.__wab_instance) {
  position: relative;
  width: auto;
}

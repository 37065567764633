.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__mlU8P {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/headerBGsliceLrGsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .freeBox__mlU8P {
    background: none;
    height: auto;
  }
}
@media (min-width: 1045px) {
  .freeBox__mlU8P {
    background: url("./images/sectionBGsvg.svg") 0% 0% / 100% 100% repeat;
  }
}
.freeBox__gFgea {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/headerBGsliceLrGsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .freeBox__gFgea {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 1045px) {
  .freeBox__gFgea {
    background: none;
  }
}
.navPageTitle:global(.__wab_instance) {
  position: relative;
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
@media (min-width: 280px) {
  .outer {
    height: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: 0;
    padding: 63px 8px 8px;
  }
}
.freeBox___4BTsN {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 0px 0px 4px 2px #00000014;
  margin-bottom: 53px;
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox___4BTsN > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox___4BTsN > :global(.__wab_flex-container) > *,
.freeBox___4BTsN > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___4BTsN > :global(.__wab_flex-container) > picture > img,
.freeBox___4BTsN
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
@media (min-width: 280px) {
  .freeBox___4BTsN {
    height: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox___4BTsN > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox___4BTsN > :global(.__wab_flex-container) > *,
  .freeBox___4BTsN > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___4BTsN > :global(.__wab_flex-container) > picture > img,
  .freeBox___4BTsN
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
@media (min-width: 1045px) {
  .freeBox___4BTsN {
    width: 600px;
    align-self: center;
    background: var(--token-oWf5ggoEo);
  }
}
.freeBox___7SfDr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: var(--token-oWf5ggoEo);
  height: 237px;
  flex-shrink: 0;
  border-radius: 4px;
  padding: 8px;
}
@media (min-width: 280px) {
  .freeBox___7SfDr {
    height: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--token-oWf5ggoEo);
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 1045px) {
  .freeBox___7SfDr {
    width: 100%;
    align-self: center;
    min-width: 0;
  }
}
.text__rn6SW {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.profileArea:global(.__wab_instance) {
  position: relative;
}
.freeBox__hs3L0 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-vYO6h4FW3);
  border-radius: 8px;
  padding: 16px;
}
.freeBox__rKyjA {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
}
.activeTxt {
  position: relative;
  font-family: "Poppins", sans-serif;
  color: var(--token-oWf5ggoEo);
  height: auto;
  width: 100%;
  align-self: center;
  font-weight: 700;
  min-width: 0;
}
.freeBox__jVi9L {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.iconRound:global(.__wab_instance) {
  position: relative;
  width: 45px;
  height: 45px;
  flex-shrink: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.freeBox__hJmp7 {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__hJmp7 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__hJmp7 > :global(.__wab_flex-container) > *,
.freeBox__hJmp7 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__hJmp7 > :global(.__wab_flex-container) > picture > img,
.freeBox__hJmp7
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__bqUN {
  position: relative;
  color: var(--token-oWf5ggoEo);
  font-size: 12px;
}
.policyPaymentDate {
  position: relative;
  font-weight: 400;
  color: var(--token-G3sstVR3F);
  font-size: 12px;
}
.yearDateBlocks:global(.__wab_instance) {
  position: relative;
  width: auto;
}
@media (min-width: 280px) {
  .yearDateBlocks:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
.freeBox__tJwca {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-gmc_Ctzg2);
  min-width: 0;
  border-radius: 8px;
  padding: 8px;
}
.freeBox__md2Sw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  padding: 8px;
  border-bottom: 1px solid var(--token-T-yLSgErM);
}
.text__n1Hvt {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: var(--token-hl3Zddiwv);
  position: relative;
  width: auto;
  height: auto;
  align-self: flex-start;
  padding: 4px;
}
.btnAddDependant:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.dependantList {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.listitemDependency:global(.__wab_instance) {
  position: relative;
}
.listItemDependentBeneficicary__rVmo:global(.__wab_instance) {
  position: relative;
}
.listItemDependentBeneficicary__rLsXn:global(.__wab_instance) {
  position: relative;
}
.freeBox__e36Gc {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-gmc_Ctzg2);
  min-width: 0;
  border-radius: 8px;
  padding: 8px;
}
.freeBox__phajK {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
  border-bottom: 1px solid var(--token-T-yLSgErM);
}
.text__bM1XM {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: var(--token-hl3Zddiwv);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-width: 0;
  padding: 4px;
}
.btnAddBeneficiary:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.beneficiaryList {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.listitemBeneficiary:global(.__wab_instance) {
  position: relative;
}
.freeBox__cOqmY {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-T-yLSgErM);
  margin-top: calc(16px + 8px) !important;
  border-radius: 4px;
  padding: 16px;
}
@media (min-width: 280px) {
  .freeBox__cOqmY {
    margin-top: calc(16px + 8px) !important;
  }
}
.freeBox__ewdJ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}
.text__vVaFu {
  position: relative;
  font-family: "Poppins", sans-serif;
  color: var(--token-oWf5ggoEo);
  height: auto;
  width: auto;
  align-self: center;
  font-weight: 700;
  font-size: 14px;
}
.cashPayoutValue {
  position: relative;
  font-family: "Poppins", sans-serif;
  color: var(--token-G3sstVR3F);
  height: auto;
  width: auto;
  align-self: center;
  font-weight: 700;
  font-size: 14px;
}
.freeBox__utZw5 {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__utZw5 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__utZw5 > :global(.__wab_flex-container) > *,
.freeBox__utZw5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__utZw5 > :global(.__wab_flex-container) > picture > img,
.freeBox__utZw5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text___2RfwC {
  position: relative;
  color: var(--token-oWf5ggoEo);
  font-size: 10px;
}
.bonusDate {
  position: relative;
  font-weight: 400;
  color: var(--token-oWf5ggoEo);
  font-size: 10px;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  background: var(--token-1Q1zU_qER8IeSd);
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .root {
    display: inline-flex;
    flex-direction: row;
    width: auto;
    background: var(--token-1Q1zU_qER8IeSd);
    cursor: pointer;
  }
}
.rootunselected {
  background: none;
  border-right: 1px solid var(--token-QC3013x5Iygz2_);
}
.freeBox {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}

.root {
  display: flex;
  flex-direction: column;
  background: var(--token-1Q1zU_qER8IeSd);
  height: 100%;
  box-shadow: 2px 4px 4px 2px var(--token-MA0AO2-MybYxFZ);
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
@media (min-width: 280px) {
  .root {
    display: flex;
    flex-direction: column;
    background: var(--token-1Q1zU_qER8IeSd);
    height: 100%;
    box-shadow: 2px 4px 4px 2px var(--token-MA0AO2-MybYxFZ);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 280px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
@media (min-width: 380px) {
  .root {
    display: flex;
    flex-direction: column;
    background: var(--token-1Q1zU_qER8IeSd);
    height: 100%;
    box-shadow: 2px 4px 4px 2px var(--token-MA0AO2-MybYxFZ);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 380px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 380px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
@media (min-width: 768px) {
  .root {
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--token-1Q1zU_qER8IeSd);
    box-shadow: 2px 4px 4px 2px var(--token-MA0AO2-MybYxFZ);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 768px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
@media (min-width: 1045px) {
  .root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--token-1Q1zU_qER8IeSd);
    box-shadow: none;
    overflow: auto;
    max-height: 480px;
    min-width: 0;
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 1045px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 0;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 1045px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.rootpageName_userProfileTab {
  width: 100%;
  min-width: 0;
}
.rootpageName_userProfileTab > :global(.__wab_flex-container) {
  min-width: 0;
}
.rootpageName_beneficiariesTab {
  width: 100%;
  min-width: 0;
}
.rootpageName_beneficiariesTab > :global(.__wab_flex-container) {
  min-width: 0;
}
.rootpageName_dependentsTab {
  width: 100%;
  min-width: 0;
}
.rootpageName_dependentsTab > :global(.__wab_flex-container) {
  min-width: 0;
}
.freeBox__dSvPl {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__dSvPl > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__dSvPl {
    background: #eaf0e5;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    display: none;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__dSvPl > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__dSvPl > :global(.__wab_flex-container) > *,
  .freeBox__dSvPl > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__dSvPl > :global(.__wab_flex-container) > picture > img,
  .freeBox__dSvPl
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.freeBoxpageName_userProfileTab__dSvPl6GLtB {
  background: var(--token-B2pEY5bfC);
  display: flex;
  border-radius: 10px;
}
.text__ekcon {
  position: relative;
  color: var(--token-quptvOxLbYUbhC);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__ekcon {
    text-align: center;
    position: relative;
    color: var(--token-quptvOxLbYUbhC);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
.textpageName_userProfileTab__ekcon6GLtB {
  color: var(--token-TZwOGIB9DUOwST);
}
.planName {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.planNamepageName_userProfileTab {
  color: var(--token-TZwOGIB9DUOwST);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.planCost {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.planCostpageName_userProfileTab {
  color: var(--token-TZwOGIB9DUOwST);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.planPayout {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.planPayoutpageName_userProfileTab {
  color: var(--token-TZwOGIB9DUOwST);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.planCover {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.planCoverpageName_userProfileTab {
  color: var(--token-TZwOGIB9DUOwST);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.text__poXps {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.textpageName_userProfileTab__poXps6GLtB {
  display: none;
}
.text__db884 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.textpageName_userProfileTab__db8846GLtB {
  display: none;
}
.text__hk9A0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.textpageName_userProfileTab__hk9A06GLtB {
  display: none;
}
.planSelector2:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 280px) {
  .planSelector2:global(.__wab_instance) {
    position: relative;
  }
}
.planSelector2pageName_userProfileTab:global(.__wab_instance) {
  display: none;
}
.planExtended {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.planExtendedpageName_userProfileTab {
  color: var(--token-TZwOGIB9DUOwST);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.freeBox__zOwmD {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBoxpageName_userProfileTab__zOwmD6GLtB {
  display: flex;
}
.freeBox__fxdYm {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-r40xO_peDMY0Sw);
  min-width: 0;
  border-radius: 5px;
  padding: 8px;
}
.text__nG1Rh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-quptvOxLbYUbhC);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  min-width: 0;
}
.freeBox__gtZyA {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 20px;
}
.freeBox__gtZyA > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__gtZyA > :global(.__wab_flex-container) > *,
.freeBox__gtZyA > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__gtZyA > :global(.__wab_flex-container) > picture > img,
.freeBox__gtZyA
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.text___8D52I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.inputFirstName:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__wNxpl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.inputLastName:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.inputGender:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__zWs2A {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.inputCell:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__ekWie {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-bottom: 0px;
  min-width: 0;
}
.inputEmail:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__kj4I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.inputPassword:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.inputPasswordpageName_userProfileTab:global(.__wab_instance) {
  display: none;
}
.inputConfirm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.inputConfirmpageName_userProfileTab:global(.__wab_instance) {
  display: none;
}
.inputId:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dateOfBirth:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__r6FuF {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-r40xO_peDMY0Sw);
  min-width: 0;
  border-radius: 5px;
  padding: 8px;
}
.text___0Wlc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-quptvOxLbYUbhC);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  min-width: 0;
}
.text__h46Zh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.accountName:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.accountNo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.selectBank:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.accountType:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__h8NkB {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.branchCode:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.submitBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn4 {
  border-width: 1px;
  border-style: none;
}
.adminBeneficiaries:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.adminBeneficiariespageName_beneficiariesTab:global(.__wab_instance) {
  display: flex;
}
.adminDependants:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.adminDependantspageName_dependentsTab:global(.__wab_instance) {
  display: flex;
}
.adminClaims:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.adminClaimspageName_claimsTab:global(.__wab_instance) {
  display: flex;
}
.adminPayments:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.adminPaymentspageName_paymentsTab:global(.__wab_instance) {
  display: flex;
}
.adminPayouts:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.adminPayoutspageName_payoutsTab:global(.__wab_instance) {
  display: flex;
}

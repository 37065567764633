.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
}
@media (min-width: 280px) {
  .root {
    align-items: center;
    justify-content: center;
    background: var(--token-hl3Zddiwv);
    padding: 4px;
  }
}
.btnDash:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 280px) {
  .btnDash:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (min-width: 380px) {
  .btnDash:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg__vkOOn {
  color: var(--token-oWf5ggoEo);
  position: relative;
  object-fit: cover;
  height: 1em;
}
.btnPayout:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 280px) {
  .btnPayout:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg___9IS6B {
  color: var(--token-oWf5ggoEo);
  position: relative;
  object-fit: cover;
  height: 1em;
}
.btnPayment:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 280px) {
  .btnPayment:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg___6Xq2V {
  color: var(--token-oWf5ggoEo);
  position: relative;
  object-fit: cover;
  height: 1em;
}
.btnClaims:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 280px) {
  .btnClaims:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (min-width: 380px) {
  .btnClaims:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (min-width: 1045px) {
  .btnClaims:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.svg__r83C0 {
  color: var(--token-oWf5ggoEo);
  position: relative;
  object-fit: cover;
  height: 1em;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C700%3B0%2C900%3B1%2C400%3B1%2C500%3B1%2C700%3B1%2C900&family=Inter%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Poppins%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900%3B1%2C400%3B1%2C500%3B1%2C600%3B1%2C700%3B1%2C900&display=swap");

.plasmic_tokens {
  --token-TZwOGIB9DUOwST: #535353;
  --plasmic-token-dark-brown: var(--token-TZwOGIB9DUOwST);
  --token-1IgY_610qjxdAO: #b6b69a;
  --plasmic-token-light-brown: var(--token-1IgY_610qjxdAO);
  --token-quptvOxLbYUbhC: #754c28;
  --plasmic-token-dark-green: var(--token-quptvOxLbYUbhC);
  --token-r40xO_peDMY0Sw: #a2d181;
  --plasmic-token-light-green: var(--token-r40xO_peDMY0Sw);
  --token-cY0jcsTj1DCRqX: #e4eaa2;
  --plasmic-token-olive-green: var(--token-cY0jcsTj1DCRqX);
  --token-UBnxw8i7y6mIsU: #242a2a;
  --plasmic-token-black-90: var(--token-UBnxw8i7y6mIsU);
  --token-CJlCnGc1IsIX1v: #92938f;
  --plasmic-token-dark-grey: var(--token-CJlCnGc1IsIX1v);
  --token-QC3013x5Iygz2_: #c5c6c3;
  --plasmic-token-light-grey: var(--token-QC3013x5Iygz2_);
  --token-1Q1zU_qER8IeSd: #ffffff;
  --plasmic-token-white: var(--token-1Q1zU_qER8IeSd);
  --token-Zqimzys_LRL3mw: #0000004d;
  --plasmic-token-blackoverlay-30: var(--token-Zqimzys_LRL3mw);
  --token-Fjf38iz-HREKc5: #efefef;
  --plasmic-token-light-bg: var(--token-Fjf38iz-HREKc5);
  --token-I5FwEAiWu-K0fc: #f8f8f8;
  --plasmic-token-b-ggrey-lite: var(--token-I5FwEAiWu-K0fc);
  --token-MA0AO2-MybYxFZ: #00000033;
  --plasmic-token-dropshadow: var(--token-MA0AO2-MybYxFZ);
  --token-0UblY07ffKa2jQ: #ecba77;
  --plasmic-token-error: var(--token-0UblY07ffKa2jQ);
  --token-v5v4b5h_hjqci9: #eaf0e5;
  --plasmic-token-light-green-2: var(--token-v5v4b5h_hjqci9);
  --token-eJM0EpiBiFJp2s: #d80202;
  --plasmic-token-red: var(--token-eJM0EpiBiFJp2s);
  --token-B2pEY5bfC: #eaf0e5;
  --plasmic-token-lightgreen-2: var(--token-B2pEY5bfC);
}

@media (min-width: 768px) {
  .plasmic_tokens {
    --token-v5v4b5h_hjqci9: #eaf0e5;
    --plasmic-token-light-green-2: var(--token-v5v4b5h_hjqci9);
  }
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-J7GrPBpKNR4pTO_font-family: "Roboto", sans-serif;
  --mixin-J7GrPBpKNR4pTO_font-size: 16px;
  --mixin-J7GrPBpKNR4pTO_font-weight: 400;
  --mixin-J7GrPBpKNR4pTO_font-style: normal;
  --mixin-J7GrPBpKNR4pTO_color: #535353;
  --mixin-J7GrPBpKNR4pTO_text-align: left;
  --mixin-J7GrPBpKNR4pTO_text-transform: none;
  --mixin-J7GrPBpKNR4pTO_line-height: 1.5;
  --mixin-J7GrPBpKNR4pTO_letter-spacing: normal;
  --mixin-J7GrPBpKNR4pTO_white-space: pre-wrap;
  --mixin-J7GrPBpKNR4pTO_user-select: text;
  --mixin-J7GrPBpKNR4pTO_text-decoration-line: none;
  --mixin-J7GrPBpKNR4pTO_text-overflow: clip;
  --mixin-f_1NOUtaZgdd05_font-family: "Inter", sans-serif;
  --mixin-f_1NOUtaZgdd05_color: #000000;
  --mixin-f_1NOUtaZgdd05_font-size: 72px;
  --mixin-f_1NOUtaZgdd05_font-weight: 900;
  --mixin-f_1NOUtaZgdd05_letter-spacing: -4px;
  --mixin-f_1NOUtaZgdd05_line-height: 1;
  --mixin-f_1NOUtaZgdd05_white-space: pre-wrap;
  --mixin-8PN4xm5dkaKvay_font-family: "Inter", sans-serif;
  --mixin-8PN4xm5dkaKvay_color: #000000;
  --mixin-8PN4xm5dkaKvay_font-size: 48px;
  --mixin-8PN4xm5dkaKvay_font-weight: 700;
  --mixin-8PN4xm5dkaKvay_letter-spacing: -1px;
  --mixin-8PN4xm5dkaKvay_line-height: 1.1;
  --mixin-8PN4xm5dkaKvay_white-space: pre-wrap;
  --mixin-sEeOxiaHDSPbjq_font-family: "Inter", sans-serif;
  --mixin-sEeOxiaHDSPbjq_color: #000000;
  --mixin-sEeOxiaHDSPbjq_font-size: 32px;
  --mixin-sEeOxiaHDSPbjq_font-weight: 600;
  --mixin-sEeOxiaHDSPbjq_letter-spacing: -0.8px;
  --mixin-sEeOxiaHDSPbjq_line-height: 1.2;
  --mixin-sEeOxiaHDSPbjq_white-space: pre-wrap;
  --mixin-YwZd9ZUKdeWvvJ_font-family: "Inter", sans-serif;
  --mixin-YwZd9ZUKdeWvvJ_color: #000000;
  --mixin-YwZd9ZUKdeWvvJ_font-size: 24px;
  --mixin-YwZd9ZUKdeWvvJ_font-weight: 600;
  --mixin-YwZd9ZUKdeWvvJ_letter-spacing: -0.5px;
  --mixin-YwZd9ZUKdeWvvJ_line-height: 1.3;
  --mixin-YwZd9ZUKdeWvvJ_white-space: pre-wrap;
  --mixin-Q9z31NIfNkQje2_font-family: "Inter", sans-serif;
  --mixin-Q9z31NIfNkQje2_color: #000000;
  --mixin-Q9z31NIfNkQje2_font-size: 20px;
  --mixin-Q9z31NIfNkQje2_font-weight: 600;
  --mixin-Q9z31NIfNkQje2_letter-spacing: -0.3px;
  --mixin-Q9z31NIfNkQje2_line-height: 1.5;
  --mixin-Q9z31NIfNkQje2_white-space: pre-wrap;
  --mixin-SKD1zIMvOECPe3_font-family: "Inter", sans-serif;
  --mixin-SKD1zIMvOECPe3_color: #000000;
  --mixin-SKD1zIMvOECPe3_font-size: 16px;
  --mixin-SKD1zIMvOECPe3_font-weight: 600;
  --mixin-SKD1zIMvOECPe3_line-height: 1.5;
  --mixin-SKD1zIMvOECPe3_white-space: pre-wrap;
  --mixin-np1-x9CdOaHwlI_border-left-color: #dddddd;
  --mixin-np1-x9CdOaHwlI_border-left-style: solid;
  --mixin-np1-x9CdOaHwlI_border-left-width: 3px;
  --mixin-np1-x9CdOaHwlI_color: #888888;
  --mixin-np1-x9CdOaHwlI_padding-left: 10px;
  --mixin-np1-x9CdOaHwlI_white-space: pre-wrap;
  --mixin-R3ncC7bnAhmfmG_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-R3ncC7bnAhmfmG_border-bottom-color: #dddddd;
  --mixin-R3ncC7bnAhmfmG_border-bottom-style: solid;
  --mixin-R3ncC7bnAhmfmG_border-bottom-width: 1px;
  --mixin-R3ncC7bnAhmfmG_border-left-color: #dddddd;
  --mixin-R3ncC7bnAhmfmG_border-left-style: solid;
  --mixin-R3ncC7bnAhmfmG_border-left-width: 1px;
  --mixin-R3ncC7bnAhmfmG_border-right-color: #dddddd;
  --mixin-R3ncC7bnAhmfmG_border-right-style: solid;
  --mixin-R3ncC7bnAhmfmG_border-right-width: 1px;
  --mixin-R3ncC7bnAhmfmG_border-top-color: #dddddd;
  --mixin-R3ncC7bnAhmfmG_border-top-style: solid;
  --mixin-R3ncC7bnAhmfmG_border-top-width: 1px;
  --mixin-R3ncC7bnAhmfmG_border-bottom-left-radius: 3px;
  --mixin-R3ncC7bnAhmfmG_border-bottom-right-radius: 3px;
  --mixin-R3ncC7bnAhmfmG_border-top-left-radius: 3px;
  --mixin-R3ncC7bnAhmfmG_border-top-right-radius: 3px;
  --mixin-R3ncC7bnAhmfmG_font-family: "Inconsolata";
  --mixin-R3ncC7bnAhmfmG_padding-bottom: 1px;
  --mixin-R3ncC7bnAhmfmG_padding-left: 4px;
  --mixin-R3ncC7bnAhmfmG_padding-right: 4px;
  --mixin-R3ncC7bnAhmfmG_padding-top: 1px;
  --mixin-R3ncC7bnAhmfmG_white-space: pre-wrap;
  --mixin-xjPHWrg5Zlvf2s_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-xjPHWrg5Zlvf2s_border-bottom-color: #dddddd;
  --mixin-xjPHWrg5Zlvf2s_border-bottom-style: solid;
  --mixin-xjPHWrg5Zlvf2s_border-bottom-width: 1px;
  --mixin-xjPHWrg5Zlvf2s_border-left-color: #dddddd;
  --mixin-xjPHWrg5Zlvf2s_border-left-style: solid;
  --mixin-xjPHWrg5Zlvf2s_border-left-width: 1px;
  --mixin-xjPHWrg5Zlvf2s_border-right-color: #dddddd;
  --mixin-xjPHWrg5Zlvf2s_border-right-style: solid;
  --mixin-xjPHWrg5Zlvf2s_border-right-width: 1px;
  --mixin-xjPHWrg5Zlvf2s_border-top-color: #dddddd;
  --mixin-xjPHWrg5Zlvf2s_border-top-style: solid;
  --mixin-xjPHWrg5Zlvf2s_border-top-width: 1px;
  --mixin-xjPHWrg5Zlvf2s_border-bottom-left-radius: 3px;
  --mixin-xjPHWrg5Zlvf2s_border-bottom-right-radius: 3px;
  --mixin-xjPHWrg5Zlvf2s_border-top-left-radius: 3px;
  --mixin-xjPHWrg5Zlvf2s_border-top-right-radius: 3px;
  --mixin-xjPHWrg5Zlvf2s_font-family: "Inconsolata";
  --mixin-xjPHWrg5Zlvf2s_padding-bottom: 3px;
  --mixin-xjPHWrg5Zlvf2s_padding-left: 6px;
  --mixin-xjPHWrg5Zlvf2s_padding-right: 6px;
  --mixin-xjPHWrg5Zlvf2s_padding-top: 3px;
  --mixin-xjPHWrg5Zlvf2s_white-space: pre-wrap;
  --mixin-NoKDrpEjTDPWvZ_display: flex;
  --mixin-NoKDrpEjTDPWvZ_flex-direction: column;
  --mixin-NoKDrpEjTDPWvZ_align-items: stretch;
  --mixin-NoKDrpEjTDPWvZ_justify-content: flex-start;
  --mixin-NoKDrpEjTDPWvZ_list-style-position: outside;
  --mixin-NoKDrpEjTDPWvZ_padding-left: 40px;
  --mixin-NoKDrpEjTDPWvZ_position: relative;
  --mixin-NoKDrpEjTDPWvZ_list-style-type: decimal;
  --mixin-NoKDrpEjTDPWvZ_white-space: pre-wrap;
  --mixin-FZci-0NWZJYmVc_display: flex;
  --mixin-FZci-0NWZJYmVc_flex-direction: column;
  --mixin-FZci-0NWZJYmVc_align-items: stretch;
  --mixin-FZci-0NWZJYmVc_justify-content: flex-start;
  --mixin-FZci-0NWZJYmVc_list-style-position: outside;
  --mixin-FZci-0NWZJYmVc_padding-left: 40px;
  --mixin-FZci-0NWZJYmVc_position: relative;
  --mixin-FZci-0NWZJYmVc_list-style-type: disc;
  --mixin-FZci-0NWZJYmVc_white-space: pre-wrap;
}

.plasmic_mixins {
  --mixin-q_ecmqcuH6B6EW_white-space: pre-wrap;
  --plasmic-mixin-formfields_white-space: var(
    --mixin-q_ecmqcuH6B6EW_white-space
  );
  --mixin-UT4B14UnzqKerL_white-space: pre-wrap;
  --plasmic-mixin-h-2-white_white-space: var(
    --mixin-UT4B14UnzqKerL_white-space
  );
  --mixin-BEiP13VwVUekHi_white-space: pre-wrap;
  --plasmic-mixin-cardheader_white-space: var(
    --mixin-BEiP13VwVUekHi_white-space
  );
  --mixin-uCPlgq04HEPguw_box-shadow: 0px 0px 4px 2px var(--token-MA0AO2-MybYxFZ);
  --plasmic-mixin-dropshadow_box-shadow: var(--mixin-uCPlgq04HEPguw_box-shadow);
  --mixin-uCPlgq04HEPguw_white-space: pre-wrap;
  --plasmic-mixin-dropshadow_white-space: var(
    --mixin-uCPlgq04HEPguw_white-space
  );
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-J7GrPBpKNR4pTO_font-family);
  font-size: var(--mixin-J7GrPBpKNR4pTO_font-size);
  font-weight: var(--mixin-J7GrPBpKNR4pTO_font-weight);
  font-style: var(--mixin-J7GrPBpKNR4pTO_font-style);
  color: var(--mixin-J7GrPBpKNR4pTO_color);
  text-align: var(--mixin-J7GrPBpKNR4pTO_text-align);
  text-transform: var(--mixin-J7GrPBpKNR4pTO_text-transform);
  line-height: var(--mixin-J7GrPBpKNR4pTO_line-height);
  letter-spacing: var(--mixin-J7GrPBpKNR4pTO_letter-spacing);
  white-space: var(--mixin-J7GrPBpKNR4pTO_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  font-family: var(--mixin-f_1NOUtaZgdd05_font-family);
  color: var(--mixin-f_1NOUtaZgdd05_color);
  font-size: var(--mixin-f_1NOUtaZgdd05_font-size);
  font-weight: var(--mixin-f_1NOUtaZgdd05_font-weight);
  letter-spacing: var(--mixin-f_1NOUtaZgdd05_letter-spacing);
  line-height: var(--mixin-f_1NOUtaZgdd05_line-height);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  font-family: var(--mixin-8PN4xm5dkaKvay_font-family);
  color: var(--mixin-8PN4xm5dkaKvay_color);
  font-size: var(--mixin-8PN4xm5dkaKvay_font-size);
  font-weight: var(--mixin-8PN4xm5dkaKvay_font-weight);
  letter-spacing: var(--mixin-8PN4xm5dkaKvay_letter-spacing);
  line-height: var(--mixin-8PN4xm5dkaKvay_line-height);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  font-family: var(--mixin-sEeOxiaHDSPbjq_font-family);
  color: var(--mixin-sEeOxiaHDSPbjq_color);
  font-size: var(--mixin-sEeOxiaHDSPbjq_font-size);
  font-weight: var(--mixin-sEeOxiaHDSPbjq_font-weight);
  letter-spacing: var(--mixin-sEeOxiaHDSPbjq_letter-spacing);
  line-height: var(--mixin-sEeOxiaHDSPbjq_line-height);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  font-family: var(--mixin-YwZd9ZUKdeWvvJ_font-family);
  color: var(--mixin-YwZd9ZUKdeWvvJ_color);
  font-size: var(--mixin-YwZd9ZUKdeWvvJ_font-size);
  font-weight: var(--mixin-YwZd9ZUKdeWvvJ_font-weight);
  letter-spacing: var(--mixin-YwZd9ZUKdeWvvJ_letter-spacing);
  line-height: var(--mixin-YwZd9ZUKdeWvvJ_line-height);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  font-family: var(--mixin-Q9z31NIfNkQje2_font-family);
  color: var(--mixin-Q9z31NIfNkQje2_color);
  font-size: var(--mixin-Q9z31NIfNkQje2_font-size);
  font-weight: var(--mixin-Q9z31NIfNkQje2_font-weight);
  letter-spacing: var(--mixin-Q9z31NIfNkQje2_letter-spacing);
  line-height: var(--mixin-Q9z31NIfNkQje2_line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  font-family: var(--mixin-SKD1zIMvOECPe3_font-family);
  color: var(--mixin-SKD1zIMvOECPe3_color);
  font-size: var(--mixin-SKD1zIMvOECPe3_font-size);
  font-weight: var(--mixin-SKD1zIMvOECPe3_font-weight);
  line-height: var(--mixin-SKD1zIMvOECPe3_line-height);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-np1-x9CdOaHwlI_color);
  padding-left: var(--mixin-np1-x9CdOaHwlI_padding-left);
  border-left: var(--mixin-np1-x9CdOaHwlI_border-left-width)
    var(--mixin-np1-x9CdOaHwlI_border-left-style)
    var(--mixin-np1-x9CdOaHwlI_border-left-color);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-R3ncC7bnAhmfmG_font-family);
  border-radius: var(--mixin-R3ncC7bnAhmfmG_border-top-left-radius)
    var(--mixin-R3ncC7bnAhmfmG_border-top-right-radius)
    var(--mixin-R3ncC7bnAhmfmG_border-bottom-right-radius)
    var(--mixin-R3ncC7bnAhmfmG_border-bottom-left-radius);
  padding: var(--mixin-R3ncC7bnAhmfmG_padding-top)
    var(--mixin-R3ncC7bnAhmfmG_padding-right)
    var(--mixin-R3ncC7bnAhmfmG_padding-bottom)
    var(--mixin-R3ncC7bnAhmfmG_padding-left);
  border-top: var(--mixin-R3ncC7bnAhmfmG_border-top-width)
    var(--mixin-R3ncC7bnAhmfmG_border-top-style)
    var(--mixin-R3ncC7bnAhmfmG_border-top-color);
  border-right: var(--mixin-R3ncC7bnAhmfmG_border-right-width)
    var(--mixin-R3ncC7bnAhmfmG_border-right-style)
    var(--mixin-R3ncC7bnAhmfmG_border-right-color);
  border-bottom: var(--mixin-R3ncC7bnAhmfmG_border-bottom-width)
    var(--mixin-R3ncC7bnAhmfmG_border-bottom-style)
    var(--mixin-R3ncC7bnAhmfmG_border-bottom-color);
  border-left: var(--mixin-R3ncC7bnAhmfmG_border-left-width)
    var(--mixin-R3ncC7bnAhmfmG_border-left-style)
    var(--mixin-R3ncC7bnAhmfmG_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-xjPHWrg5Zlvf2s_font-family);
  border-radius: var(--mixin-xjPHWrg5Zlvf2s_border-top-left-radius)
    var(--mixin-xjPHWrg5Zlvf2s_border-top-right-radius)
    var(--mixin-xjPHWrg5Zlvf2s_border-bottom-right-radius)
    var(--mixin-xjPHWrg5Zlvf2s_border-bottom-left-radius);
  padding: var(--mixin-xjPHWrg5Zlvf2s_padding-top)
    var(--mixin-xjPHWrg5Zlvf2s_padding-right)
    var(--mixin-xjPHWrg5Zlvf2s_padding-bottom)
    var(--mixin-xjPHWrg5Zlvf2s_padding-left);
  border-top: var(--mixin-xjPHWrg5Zlvf2s_border-top-width)
    var(--mixin-xjPHWrg5Zlvf2s_border-top-style)
    var(--mixin-xjPHWrg5Zlvf2s_border-top-color);
  border-right: var(--mixin-xjPHWrg5Zlvf2s_border-right-width)
    var(--mixin-xjPHWrg5Zlvf2s_border-right-style)
    var(--mixin-xjPHWrg5Zlvf2s_border-right-color);
  border-bottom: var(--mixin-xjPHWrg5Zlvf2s_border-bottom-width)
    var(--mixin-xjPHWrg5Zlvf2s_border-bottom-style)
    var(--mixin-xjPHWrg5Zlvf2s_border-bottom-color);
  border-left: var(--mixin-xjPHWrg5Zlvf2s_border-left-width)
    var(--mixin-xjPHWrg5Zlvf2s_border-left-style)
    var(--mixin-xjPHWrg5Zlvf2s_border-left-color);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-NoKDrpEjTDPWvZ_display);
  flex-direction: var(--mixin-NoKDrpEjTDPWvZ_flex-direction);
  align-items: var(--mixin-NoKDrpEjTDPWvZ_align-items);
  justify-content: var(--mixin-NoKDrpEjTDPWvZ_justify-content);
  list-style-position: var(--mixin-NoKDrpEjTDPWvZ_list-style-position);
  padding-left: var(--mixin-NoKDrpEjTDPWvZ_padding-left);
  position: var(--mixin-NoKDrpEjTDPWvZ_position);
  list-style-type: var(--mixin-NoKDrpEjTDPWvZ_list-style-type);
  flex-column-gap: var(--mixin-NoKDrpEjTDPWvZ_flex-column-gap);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-FZci-0NWZJYmVc_display);
  flex-direction: var(--mixin-FZci-0NWZJYmVc_flex-direction);
  align-items: var(--mixin-FZci-0NWZJYmVc_align-items);
  justify-content: var(--mixin-FZci-0NWZJYmVc_justify-content);
  list-style-position: var(--mixin-FZci-0NWZJYmVc_list-style-position);
  padding-left: var(--mixin-FZci-0NWZJYmVc_padding-left);
  position: var(--mixin-FZci-0NWZJYmVc_position);
  list-style-type: var(--mixin-FZci-0NWZJYmVc_list-style-type);
  flex-column-gap: var(--mixin-FZci-0NWZJYmVc_flex-column-gap);
}

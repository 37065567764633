.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__aT2Tm {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 380px) {
  .freeBox__aT2Tm {
    background: url("./images/lightgreensvg.svg") 0% 0% / contain repeat-x;
  }
}
.navPageTitle:global(.__wab_instance) {
  position: relative;
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
.freeBox__qxU1T {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: 100%;
  min-height: 0;
  padding: 0px;
}
.slider:global(.__wab_instance) {
  position: relative;
}

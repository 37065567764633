.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--token-c79ojIUd1);
  position: relative;
  border-radius: 50%;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
  flex-shrink: 0;
}

.root {
  display: flex;
  width: 3em;
  height: 3em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--token-cY0jcsTj1DCRqX);
  position: relative;
  border-radius: 50%;
  padding: 8px;
}
@media (min-width: 380px) {
  .root {
    width: 3em;
    height: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 768px) {
  .root {
    width: 5em;
    height: 5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.rootlightgreen {
  background: var(--token-r40xO_peDMY0Sw);
}
.rootdarkGreen {
  background: var(--token-quptvOxLbYUbhC);
}
.rootwhite {
  background: var(--token-1Q1zU_qER8IeSd);
}
.rootstates_selected {
  background: var(--token-r40xO_peDMY0Sw);
  box-shadow: inset 2px 2px 4px 2px #00000080;
  border: 1px solid var(--token-1IgY_610qjxdAO);
}
.rootstates_failed {
  background: var(--token-0UblY07ffKa2jQ);
}
.svg__xBeFm {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
  flex-shrink: 0;
}

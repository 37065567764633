.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__bbnFa {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 768px) {
  .freeBox__bbnFa {
    background: url("./images/lightgreensvg.svg") 0% 0% / contain repeat-x;
  }
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
.freeBox__w98W9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: 100%;
  min-height: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__w98W9 {
    align-self: center;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: none;
    height: 100%;
    min-height: 0;
    padding: 8px;
  }
}
.freeBox__qXnTn {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__qXnTn > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__qXnTn > :global(.__wab_flex-container) > *,
.freeBox__qXnTn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__qXnTn > :global(.__wab_flex-container) > picture > img,
.freeBox__qXnTn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 280px) {
  .freeBox__qXnTn > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__qXnTn > :global(.__wab_flex-container) > *,
  .freeBox__qXnTn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__qXnTn > :global(.__wab_flex-container) > picture > img,
  .freeBox__qXnTn
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__qXnTn {
    width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__qXnTn > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .freeBox__qXnTn > :global(.__wab_flex-container) > *,
  .freeBox__qXnTn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__qXnTn > :global(.__wab_flex-container) > picture > img,
  .freeBox__qXnTn
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.text__kTMuA {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.profileArea:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
@media (min-width: 280px) {
  .profileArea:global(.__wab_instance) {
    display: flex;
  }
}
.freeBox__m95JB {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
@media (min-width: 280px) {
  .freeBox__m95JB {
    background: var(--token-G3sstVR3F);
    display: flex;
  }
}
.text__asu2M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
@media (min-width: 280px) {
  .text__asu2M {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 12px;
    text-align: right;
    align-self: center;
    padding-right: 10px;
  }
}
.btnAdd:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
@media (min-width: 280px) {
  .btnAdd:global(.__wab_instance) {
    display: flex;
  }
}
.freeBox___9XW4H {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBox___9XW4H > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox___9XW4H {
    background: #eaf0e5;
    display: flex;
    border-radius: 4px;
  }
}
@media (min-width: 280px) {
  .freeBox___9XW4H > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox___9XW4H > :global(.__wab_flex-container) > *,
  .freeBox___9XW4H > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___9XW4H > :global(.__wab_flex-container) > picture > img,
  .freeBox___9XW4H
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.freeBox___1KQdc {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 280px) {
  .freeBox___1KQdc {
    align-items: center;
    justify-content: space-between;
    padding: 0px;
  }
}
.text__wcu0E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 12px;
  min-width: 0;
}
.claimList {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.claimList > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .claimList {
    display: flex;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .claimList > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 15px);
    height: calc(100% + 15px);
  }
}
@media (min-width: 280px) {
  .claimList > :global(.__wab_flex-container) > *,
  .claimList > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .claimList > :global(.__wab_flex-container) > picture > img,
  .claimList
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 15px;
  }
}
.paymentListItem__vTQt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
@media (min-width: 280px) {
  .paymentListItem__vTQt:global(.__wab_instance) {
    display: flex;
  }
}
.paymentListItem__fah9P:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__hIh9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.backBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn4 {
  border-width: 1px;
  border-style: none;
}
.freeBox__iAhDu {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__iAhDu {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: 0;
    padding: 8px;
  }
}
.forgotBtn:global(.__wab_instance) {
  position: relative;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.footerWidget:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}

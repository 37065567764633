.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #000000b5;
  min-width: 0;
  min-height: 0;
  border-radius: 0px;
}
.freeBox__c4Qhp {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  align-self: center;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
  border-radius: 0em;
}
.freeBox__c3LKp {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.freeBox__vBZm9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.svg___8Qc {
  position: relative;
  object-fit: cover;
  color: var(--token-cE0h0jVeC);
  width: 45px;
  height: 45px;
  flex-shrink: 0;
}
.svgpageStatus_success___8QcvHoWi {
  display: none;
}
.svgpageStatus_successDependant___8QcUdTwn {
  display: none;
}
.freeBox__qhrZj {
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 0px 30px;
}
.freeBox__qhrZj > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.freeBox__qhrZj > :global(.__wab_flex-container) > *,
.freeBox__qhrZj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__qhrZj > :global(.__wab_flex-container) > picture > img,
.freeBox__qhrZj
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 30px;
}
.iconRound__bVK:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
.iconRoundpageStatus_success__bVKvHoWi:global(.__wab_instance) {
  flex-shrink: 0;
}
.svg__ywAtW {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.errorTxt {
  position: relative;
  color: #ffffff;
  align-self: center;
  width: 100%;
  height: 100%;
  text-align: center;
  min-width: 0;
  min-height: 0;
}
.errorTxtpageStatus_successDependant {
  display: none;
}
.btnConfirm:global(.__wab_instance) {
  position: relative;
  width: auto;
}
.btnConfirmpageStatus_successDependant:global(.__wab_instance) {
  display: none;
}
.btnClose:global(.__wab_instance) {
  position: relative;
  width: auto;
}
.btnClosepageStatus_successDependant:global(.__wab_instance) {
  display: none;
}
.freeBox__wtxNm {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.freeBox__wtxNm > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__wtxNm > :global(.__wab_flex-container) > *,
.freeBox__wtxNm > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wtxNm > :global(.__wab_flex-container) > picture > img,
.freeBox__wtxNm
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.iconRound__l4JLx:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.svg___47BFq {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.freeBox__zjSD {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.errorTxt2 {
  position: relative;
  color: #ffffff;
  align-self: center;
  width: 100%;
  height: auto;
  text-align: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__mUxQe {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 8px;
}
.btnAddAnother:global(.__wab_instance) {
  position: relative;
}
.text__yxrTa {
  height: auto;
}
.freeBox__j2Dxe {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 8px;
}
.btnClose2:global(.__wab_instance) {
  position: relative;
}
.text__l9Tz8 {
  height: auto;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
}
.freeBox__xv6Kg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox___25FNd {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox___25FNd > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media (min-width: 280px) {
  .freeBox___25FNd {
    width: 100%;
    flex-direction: row;
    min-width: 0;
    padding: 4px;
  }
}
@media (min-width: 280px) {
  .freeBox___25FNd > :global(.__wab_flex-container) {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-width: 0;
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox___25FNd > :global(.__wab_flex-container) > *,
  .freeBox___25FNd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___25FNd > :global(.__wab_flex-container) > picture > img,
  .freeBox___25FNd
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
.txtValue {
  position: relative;
  width: 100%;
  border-top-width: 0px;
  border-top-style: solid;
  border-left-width: 0px;
  border-left-style: solid;
  border-right-width: 0px;
  border-right-style: solid;
  font-size: 16px;
  min-width: 0;
}
.btnEyeClosed {
  position: relative;
  object-fit: cover;
  width: 150px;
  height: 150px;
  flex-shrink: 0;
}
.btnEyeClosedshowPassword {
  height: 16px;
  width: 16px;
  flex-shrink: 0;
}
.btnEye {
  position: sticky;
  object-fit: cover;
  width: 16px;
  height: 16px;
  left: auto;
  top: 0px;
  z-index: 1;
  right: 0px;
  flex-shrink: 0;
}
.btnEyeshowPassword {
  display: none;
}
.freeBox__rt75H {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 10px;
}
.freeBox__rt75H > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__rt75H > :global(.__wab_flex-container) > *,
.freeBox__rt75H > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__rt75H > :global(.__wab_flex-container) > picture > img,
.freeBox__rt75H
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBoxshowError__rt75H6Q7Pf {
  display: none;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 30px;
  height: 16px;
  color: var(--token-cE0h0jVeC);
  flex-shrink: 0;
}
.txtError {
  position: relative;
  color: var(--token-cE0h0jVeC);
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  min-width: 0;
}

.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__pCQuz {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: none;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 380px) {
  .freeBox__pCQuz {
    background: none;
  }
}
@media (min-width: 768px) {
  .freeBox__pCQuz {
    background: url("./images/lightgreensvg.svg") 0% 0% / contain repeat-x;
  }
}
@media (min-width: 1045px) {
  .freeBox__pCQuz {
    background: none;
  }
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
@media (min-width: 380px) {
  .outer {
    background: var(--token-hKDDQTr7l);
  }
}
.freeBox__qhecZ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: 100%;
  min-height: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__qhecZ {
    align-self: center;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: none;
    height: 100%;
    min-height: 0;
    padding: 8px;
  }
}
.freeBox__tGv8P {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  overflow: auto;
  max-height: 760px;
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__tGv8P > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__tGv8P > :global(.__wab_flex-container) > *,
.freeBox__tGv8P > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tGv8P > :global(.__wab_flex-container) > picture > img,
.freeBox__tGv8P
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
@media (min-width: 380px) {
  .freeBox__tGv8P {
    max-height: 760px;
  }
}
@media (min-width: 380px) {
  .freeBox__tGv8P > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 380px) {
  .freeBox__tGv8P > :global(.__wab_flex-container) > *,
  .freeBox__tGv8P > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__tGv8P > :global(.__wab_flex-container) > picture > img,
  .freeBox__tGv8P
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__tGv8P {
    width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__tGv8P > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .freeBox__tGv8P > :global(.__wab_flex-container) > *,
  .freeBox__tGv8P > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__tGv8P > :global(.__wab_flex-container) > picture > img,
  .freeBox__tGv8P
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.text__rEfMn {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__oDsu {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBox__oDsu > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__oDsu {
    background: #eaf0e5;
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__oDsu > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__oDsu > :global(.__wab_flex-container) > *,
  .freeBox__oDsu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__oDsu > :global(.__wab_flex-container) > picture > img,
  .freeBox__oDsu
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.freeBox__ds3Vs {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__ds3Vs > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__ds3Vs {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
  }
}
@media (min-width: 280px) {
  .freeBox__ds3Vs > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__ds3Vs > :global(.__wab_flex-container) > *,
  .freeBox__ds3Vs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__ds3Vs > :global(.__wab_flex-container) > picture > img,
  .freeBox__ds3Vs
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__ds3Vs > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__ds3Vs > :global(.__wab_flex-container) > *,
  .freeBox__ds3Vs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__ds3Vs > :global(.__wab_flex-container) > picture > img,
  .freeBox__ds3Vs
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__carvU:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 280px) {
  .iconNumber__carvU:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (min-width: 380px) {
  .iconNumber__carvU:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__p46Fl {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__p46Fl {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__p46Fl {
    font-size: 11px;
  }
}
.dependantName {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  min-width: 0;
}
.freeBox__poGwb {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__poGwb > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__poGwb {
    background: var(--token-gmc_Ctzg2);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__poGwb > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__poGwb > :global(.__wab_flex-container) > *,
  .freeBox__poGwb > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__poGwb > :global(.__wab_flex-container) > picture > img,
  .freeBox__poGwb
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox__poGwb {
    background: var(--token-gmc_Ctzg2);
    display: flex;
  }
}
.freeBox__bbHm8 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__bbHm8 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__bbHm8 {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__bbHm8 > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__bbHm8 > :global(.__wab_flex-container) > *,
  .freeBox__bbHm8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__bbHm8 > :global(.__wab_flex-container) > picture > img,
  .freeBox__bbHm8
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__bbHm8 > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__bbHm8 > :global(.__wab_flex-container) > *,
  .freeBox__bbHm8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__bbHm8 > :global(.__wab_flex-container) > picture > img,
  .freeBox__bbHm8
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__f6KmL:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber__f6KmL:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__xHJ0 {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__xHJ0 {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__xHJ0 {
    font-size: 11px;
  }
}
.natureOfDeath {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  min-width: 0;
}
.freeBox__uG6Al {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__uG6Al > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__uG6Al {
    background: var(--token-gmc_Ctzg2);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__uG6Al > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__uG6Al > :global(.__wab_flex-container) > *,
  .freeBox__uG6Al > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__uG6Al > :global(.__wab_flex-container) > picture > img,
  .freeBox__uG6Al
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox__uG6Al {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox__clKz {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__clKz > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__clKz {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__clKz > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__clKz > :global(.__wab_flex-container) > *,
  .freeBox__clKz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__clKz > :global(.__wab_flex-container) > picture > img,
  .freeBox__clKz
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__clKz > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__clKz > :global(.__wab_flex-container) > *,
  .freeBox__clKz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__clKz > :global(.__wab_flex-container) > picture > img,
  .freeBox__clKz
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber___93Acl:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber___93Acl:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (min-width: 280px) {
  .text__nfjKc {
    width: auto;
  }
}
.text__r6Pzt {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__r6Pzt {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__r6Pzt {
    font-size: 11px;
  }
}
.street {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  min-width: 0;
}
.city {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  min-width: 0;
}
.province {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  min-width: 0;
}
.freeBox___73BGj {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox___73BGj > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox___73BGj {
    background: linear-gradient(var(--token-gmc_Ctzg2), var(--token-gmc_Ctzg2)),
      #eaf0e5;
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox___73BGj > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox___73BGj > :global(.__wab_flex-container) > *,
  .freeBox___73BGj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___73BGj > :global(.__wab_flex-container) > picture > img,
  .freeBox___73BGj
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox___73BGj {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox__b3YKp {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__b3YKp > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__b3YKp {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__b3YKp > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__b3YKp > :global(.__wab_flex-container) > *,
  .freeBox__b3YKp > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__b3YKp > :global(.__wab_flex-container) > picture > img,
  .freeBox__b3YKp
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__b3YKp > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__b3YKp > :global(.__wab_flex-container) > *,
  .freeBox__b3YKp > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__b3YKp > :global(.__wab_flex-container) > picture > img,
  .freeBox__b3YKp
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__eQqNn:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber__eQqNn:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__tzmN3 {
  padding-right: 0px;
}
.text__sk5Et {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__sk5Et {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__sk5Et {
    font-size: 11px;
  }
}
.resStreet {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  min-width: 0;
}
.resCity {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  min-width: 0;
}
.resProvince {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  min-width: 0;
}
.freeBox__n9Mhi {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__n9Mhi > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__n9Mhi {
    background: var(--token-gmc_Ctzg2);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__n9Mhi > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__n9Mhi > :global(.__wab_flex-container) > *,
  .freeBox__n9Mhi > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__n9Mhi > :global(.__wab_flex-container) > picture > img,
  .freeBox__n9Mhi
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox__n9Mhi {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox___3P3Od {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox___3P3Od > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox___3P3Od {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox___3P3Od > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox___3P3Od > :global(.__wab_flex-container) > *,
  .freeBox___3P3Od > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___3P3Od > :global(.__wab_flex-container) > picture > img,
  .freeBox___3P3Od
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox___3P3Od > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox___3P3Od > :global(.__wab_flex-container) > *,
  .freeBox___3P3Od > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___3P3Od > :global(.__wab_flex-container) > picture > img,
  .freeBox___3P3Od
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__ktqo4:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber__ktqo4:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__e2YxU {
  padding-right: 0px;
}
.text__cKrwV {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__cKrwV {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__cKrwV {
    font-size: 11px;
  }
}
.deceasedId:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 380px) {
  .deceasedId:global(.__wab_instance) {
    display: none;
  }
}
@media (min-width: 280px) {
  .text__kseg8 {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__kseg8 {
    font-size: 11px;
  }
}
.freeBox__mj7Bw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__f8SGo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
@media (min-width: 380px) {
  .text__f8SGo {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    align-self: center;
  }
}
.btnDeceasedId:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.deathNotice:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 380px) {
  .deathNotice:global(.__wab_instance) {
    display: none;
  }
}
@media (min-width: 280px) {
  .text__siq6S {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__siq6S {
    font-size: 11px;
  }
}
.freeBox__me6Fo {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__gIp1N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
@media (min-width: 380px) {
  .text__gIp1N {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    align-self: center;
  }
}
.btnDeathNotice:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.bankStatements:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 380px) {
  .bankStatements:global(.__wab_instance) {
    display: none;
  }
}
@media (min-width: 280px) {
  .text__eEZkD {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__eEZkD {
    font-size: 11px;
  }
}
.freeBox__dFwOd {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__op4I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
@media (min-width: 380px) {
  .text__op4I {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    align-self: center;
  }
}
.btnBank:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.claimForm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 380px) {
  .claimForm:global(.__wab_instance) {
    display: none;
  }
}
@media (min-width: 280px) {
  .text__zjkdW {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__zjkdW {
    font-size: 11px;
  }
}
.freeBox___7Bdiy {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__mV0UY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
@media (min-width: 380px) {
  .text__mV0UY {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    align-self: center;
  }
}
.btnClaim:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.memberId:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 380px) {
  .memberId:global(.__wab_instance) {
    display: none;
  }
}
@media (min-width: 280px) {
  .text___6TBfY {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text___6TBfY {
    font-size: 11px;
  }
}
.freeBox___34Te3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__b7RG {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
@media (min-width: 380px) {
  .text__b7RG {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    align-self: center;
  }
}
.btnMemberId:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.deathCertificate:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 380px) {
  .deathCertificate:global(.__wab_instance) {
    display: none;
  }
}
@media (min-width: 280px) {
  .text__stJnd {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__stJnd {
    font-size: 11px;
  }
}
.freeBox__gZxJ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__etGPq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
@media (min-width: 380px) {
  .text__etGPq {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    align-self: center;
  }
}
.btnDeathCert:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__ndKvP {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
@media (min-width: 380px) {
  .freeBox__ndKvP {
    display: flex;
  }
}
.paymentType:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__moXp6 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__moXp6 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 380px) {
  .freeBox__moXp6 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 10px);
    height: calc(100% + 10px);
  }
}
@media (min-width: 380px) {
  .freeBox__moXp6 > :global(.__wab_flex-container) > *,
  .freeBox__moXp6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__moXp6 > :global(.__wab_flex-container) > picture > img,
  .freeBox__moXp6
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 10px;
  }
}
.sendCodeBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn2 {
  border-width: 1px;
  border-style: none;
}
.code:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
@media (min-width: 380px) {
  .code:global(.__wab_instance) {
    display: flex;
  }
}
.approveBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn3 {
  border-width: 1px;
  border-style: none;
}
.declineBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn5 {
  border-width: 1px;
  border-style: none;
}
.btnBack:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 380px) {
  .btnBack:global(.__wab_instance) {
    position: relative;
  }
}
.submitBtn6 {
  border-width: 1px;
  border-style: none;
}

.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: var(--token-1Q1zU_qER8IeSd);
  overflow: auto;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__dNbVo {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: none;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .freeBox__dNbVo {
    background: none;
    height: auto;
  }
}
@media (min-width: 768px) {
  .freeBox__dNbVo {
    background: none;
  }
}
@media (min-width: 1045px) {
  .freeBox__dNbVo {
    background: none;
    height: 100%;
    min-height: 0;
  }
}
.freeBox__xjCke {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: none;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .freeBox__xjCke {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: url("./../copy_of_imbewu_admin/images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .freeBox__xjCke {
    display: flex;
    flex-direction: column;
    background: none;
  }
}
@media (min-width: 1045px) {
  .freeBox__xjCke {
    background: none;
    height: 100%;
    min-height: 0;
  }
}
.navPageTitle:global(.__wab_instance) {
  position: relative;
}
.freeBox__bhwjl {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  display: none;
  padding: 8px;
}
@media (min-width: 1045px) {
  .freeBox__bhwjl {
    display: flex;
  }
}
.freeBox___2Wu2L {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.sideNavA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 1045px) {
  .sideNavA:global(.__wab_instance) {
    max-width: none;
    width: auto;
  }
}
.freeBox__bBjkh {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox__gOhRv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__gOhRv {
    justify-content: center;
    align-items: flex-start;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
}
.text__tnLS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .text__tnLS {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
  }
}
.freeBox___5SScl {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox___5SScl {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: none;
    background: var(--token-v5v4b5h_hjqci9);
    padding: 0px;
  }
}
.search2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .search2 {
    display: flex;
    flex-direction: row;
    width: 400px;
    flex-shrink: 0;
  }
}
.inputSearch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.btnSearch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.inputStringA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .inputStringA:global(.__wab_instance) {
    display: none;
  }
}
.freeBox___1M9L5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox___1M9L5 {
    display: flex;
    flex-direction: row;
    width: auto;
  }
}
.btnAddUser:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.text__aZray {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
  display: none;
}
.userList {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: auto;
  background: var(--token-v5v4b5h_hjqci9);
  min-width: 0;
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.userList > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.userList > :global(.__wab_flex-container) > *,
.userList > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.userList > :global(.__wab_flex-container) > picture > img,
.userList
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
@media (min-width: 768px) {
  .userList {
    padding: 16px;
  }
}
@media (min-width: 768px) {
  .userList > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
  }
}
.listUser:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUser:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__nv6Qx:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__nv6Qx:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__rQ2Gm:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__rQ2Gm:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__aRhle:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__aRhle:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__qf000:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__qf000:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__yQQhq:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__yQQhq:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers___3Mei7:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers___3Mei7:global(.__wab_instance) {
    position: relative;
  }
}
.listUsers__geDqo:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .listUsers__geDqo:global(.__wab_instance) {
    position: relative;
  }
}

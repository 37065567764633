.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__oiZad {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: none;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.navPageTitle:global(.__wab_instance) {
  position: relative;
}
.freeBox__tnrfA {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-gmc_Ctzg2);
  position: relative;
  padding: 16px;
}
.text__oc7Gw {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  padding: 8px;
}
.text__fYgw2 {
  position: relative;
  font-size: 14px;
  height: 100%;
  min-height: 0;
  padding: 8px;
}
.freeBox__orOgt {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: auto;
  background: url("./images/lightgreensvg.svg") 0% 0% / cover repeat;
  min-height: 0;
  padding: 16px;
}
.freeBox__orOgt > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__orOgt > :global(.__wab_flex-container) > *,
.freeBox__orOgt > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__orOgt > :global(.__wab_flex-container) > picture > img,
.freeBox__orOgt
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.text__i4Wp {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-oWf5ggoEo);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__bdzQh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: 100%;
  min-height: 0;
}
.freeBox__cQzaB {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__cQzaB > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__cQzaB > :global(.__wab_flex-container) > *,
.freeBox__cQzaB > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__cQzaB > :global(.__wab_flex-container) > picture > img,
.freeBox__cQzaB
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.svg__cvnq7 {
  position: relative;
  object-fit: cover;
  color: var(--token-vYO6h4FW3);
  height: 1em;
}
.text__cxF43 {
  position: relative;
  font-size: 12px;
  color: var(--token-oWf5ggoEo);
}
.freeBox__lJec9 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__lJec9 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__lJec9 > :global(.__wab_flex-container) > *,
.freeBox__lJec9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lJec9 > :global(.__wab_flex-container) > picture > img,
.freeBox__lJec9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.svg__rykRm {
  position: relative;
  object-fit: cover;
  color: var(--token-vYO6h4FW3);
  height: 1em;
}
.text__tJRvT {
  position: relative;
  font-size: 12px;
  color: var(--token-oWf5ggoEo);
}
.freeBox__opIaz {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__opIaz > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__opIaz > :global(.__wab_flex-container) > *,
.freeBox__opIaz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__opIaz > :global(.__wab_flex-container) > picture > img,
.freeBox__opIaz
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.svg__qpO2D {
  position: relative;
  object-fit: cover;
  color: var(--token-vYO6h4FW3);
  height: 1em;
}
.text__ha3Qn {
  position: relative;
  font-size: 12px;
  color: var(--token-oWf5ggoEo);
}
.freeBox__skLi6 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 131px;
  min-width: 0;
  flex-shrink: 0;
  padding: 16px;
}
.freeBox__skLi6 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__skLi6 > :global(.__wab_flex-container) > *,
.freeBox__skLi6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__skLi6 > :global(.__wab_flex-container) > picture > img,
.freeBox__skLi6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.img {
  position: relative;
  object-fit: cover;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 50%;
}
.img > picture > img {
  object-fit: cover;
}
.freeBox__ePFx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: none;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-width: 0;
}
.text__kAb69 {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: var(--token-vYO6h4FW3);
  font-weight: 700;
  min-width: 0;
  min-height: 0;
}
.text__eqmjb {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 9px;
  font-weight: 700;
  color: var(--token-c79ojIUd1);
  min-width: 0;
  min-height: 0;
}
.text__cnfFm {
  position: relative;
  font-size: 12px;
  font-style: italic;
  height: 100%;
  width: 100%;
  color: var(--token-vYO6h4FW3);
  min-width: 0;
  min-height: 0;
}
.freeBox__zTiZ {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 16px;
}
.freeBox__zTiZ > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__zTiZ > :global(.__wab_flex-container) > *,
.freeBox__zTiZ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zTiZ > :global(.__wab_flex-container) > picture > img,
.freeBox__zTiZ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.dotSliderItem__pToFq:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.dotSliderItem___6G3Qk:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.dotSliderItem___6EVrj:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.freeBox___3I7Fm {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  width: auto;
  padding: 16px;
}
.btnSignup:global(.__wab_instance) {
  position: relative;
}
.footerWidget:global(.__wab_instance) {
  position: relative;
}

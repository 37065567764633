.root {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media (min-width: 280px) {
  .root {
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .root > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    margin-left: calc(0px - 2px);
    width: calc(100% + 2px);
    margin-top: calc(0px - 2px);
    height: calc(100% + 2px);
  }
}
@media (min-width: 280px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 2px;
    margin-top: 2px;
  }
}
.freeBox__xSn4N {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBoxstatus_active__xSn4NLgd {
  display: flex;
}
.freeBoxstatus_failed__xSn4NHg0FA {
  display: flex;
}
.freeBox__g7SGp {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBoxstatus_active__g7SGpLgd {
  display: flex;
}
.freeBoxstatus_failed__g7SGpHg0FA {
  display: flex;
}
.jan:global(.__wab_instance) {
  position: relative;
}
.feb:global(.__wab_instance) {
  position: relative;
}
.mar:global(.__wab_instance) {
  position: relative;
}
.apr:global(.__wab_instance) {
  position: relative;
}
.may:global(.__wab_instance) {
  position: relative;
}
.jun:global(.__wab_instance) {
  position: relative;
}
.jul:global(.__wab_instance) {
  position: relative;
}
.aug:global(.__wab_instance) {
  position: relative;
}
.sep:global(.__wab_instance) {
  position: relative;
}
.oct:global(.__wab_instance) {
  position: relative;
}
.nov:global(.__wab_instance) {
  position: relative;
}
.dec:global(.__wab_instance) {
  position: relative;
}
.freeBox__vw0GA {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__vw0GA > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__vw0GA > :global(.__wab_flex-container) > *,
.freeBox__vw0GA > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__vw0GA > :global(.__wab_flex-container) > picture > img,
.freeBox__vw0GA
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBoxstatus_active__vw0GALgd {
  display: none;
}
.freeBox__jtDtV {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-c79ojIUd1);
  min-width: 0;
  border-radius: 10px;
  padding: 8px;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: var(--token-oWf5ggoEo);
  min-width: 0;
}
.btnPayNow:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__rvQHc {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}

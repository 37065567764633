.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.freeBox__eFfJb {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: 0px;
  top: 0px;
  background: var(--token-hKDDQTr7l);
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox__iqwms {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: auto;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  width: auto;
  max-height: 500px;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__iqwms > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__iqwms > :global(.__wab_flex-container) > *,
.freeBox__iqwms > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__iqwms > :global(.__wab_flex-container) > picture > img,
.freeBox__iqwms
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .freeBox__iqwms {
    width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__iqwms > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .freeBox__iqwms > :global(.__wab_flex-container) > *,
  .freeBox__iqwms > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__iqwms > :global(.__wab_flex-container) > picture > img,
  .freeBox__iqwms
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.freeBoxstatus_csvUpload__iqwmshE6B {
  width: 440px;
}
.freeBoxstatus_csvProcess__iqwmsvUFu {
  width: 400px;
  min-height: 500px;
}
.freeBoxstatus_csvComplete__iqwmscwOhr {
  width: 400px;
  min-height: 500px;
}
.text__i0Uxv {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__pjqe6 {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  overflow: auto;
  width: 100%;
  min-width: 0;
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__pjqe6 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__pjqe6 > :global(.__wab_flex-container) > *,
.freeBox__pjqe6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pjqe6 > :global(.__wab_flex-container) > picture > img,
.freeBox__pjqe6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .freeBox__pjqe6 {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    min-width: 0;
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__pjqe6 > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 0;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .freeBox__pjqe6 > :global(.__wab_flex-container) > *,
  .freeBox__pjqe6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__pjqe6 > :global(.__wab_flex-container) > picture > img,
  .freeBox__pjqe6
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.inputFirstName:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.inputLastName:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.inputGender:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__iXvzz {
  position: relative;
  margin-top: calc(10px + 20px) !important;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  color: var(--token-T-yLSgErM);
  font-size: 14px;
}
.inputCell:global(.__wab_instance) {
  position: relative;
  align-self: center;
  width: 100%;
  min-width: 0;
}
.inputEmail:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.adminSwitch:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.adminSwitchstatus_csvUpload:global(.__wab_instance) {
  display: none;
}
.adminSwitchstatus_csvProcess:global(.__wab_instance) {
  display: none;
}
.adminSwitchstatus_successSingle:global(.__wab_instance) {
  display: none;
}
.adminSwitchstatus_csvComplete:global(.__wab_instance) {
  display: none;
}
.freeBox__w1TJw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__w1TJw > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.freeBox__w1TJw > :global(.__wab_flex-container) > *,
.freeBox__w1TJw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__w1TJw > :global(.__wab_flex-container) > picture > img,
.freeBox__w1TJw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.freeBoxstatus_csvProcess__w1TJWvUFu {
  height: 100%;
  min-height: 0;
}
.freeBoxstatus_csvProcess__w1TJWvUFu > :global(.__wab_flex-container) {
  min-height: 0;
}
.freeBoxstatus_csvComplete__w1TJWcwOhr {
  height: 100%;
  min-height: 0;
}
.freeBoxstatus_csvComplete__w1TJWcwOhr > :global(.__wab_flex-container) {
  min-height: 0;
}
.processList {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: auto;
  min-width: 0;
  min-height: 0;
  display: none;
  padding: 8px;
}
.processListstatus_csvProcess {
  display: flex;
}
.processListstatus_csvComplete {
  display: flex;
}
.processedItem:global(.__wab_instance) {
  position: relative;
}
.btnProcess:global(.__wab_instance) {
  position: relative;
}
.btnProcessstatus_csvProcess:global(.__wab_instance) {
  display: none;
}
.btnProcessstatus_successSingle:global(.__wab_instance) {
  display: none;
}
.btnProcessstatus_csvComplete:global(.__wab_instance) {
  display: none;
}
.submitBtn6 {
  border-width: 1px;
  border-style: none;
}
.btnUpload:global(.__wab_instance) {
  position: relative;
}
.btnUploadstatus_csvUpload:global(.__wab_instance) {
  display: none;
}
.btnUploadstatus_csvProcess:global(.__wab_instance) {
  display: none;
}
.btnUploadstatus_successSingle:global(.__wab_instance) {
  display: none;
}
.btnUploadstatus_csvComplete:global(.__wab_instance) {
  display: none;
}
.submitBtn8 {
  border-width: 1px;
  border-style: none;
}
.freeBox__u33D {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.btnBack:global(.__wab_instance) {
  position: relative;
}
.btnBackstatus_csvProcess:global(.__wab_instance) {
  display: none;
}
.submitBtn7 {
  border-width: 1px;
  border-style: none;
}
.btnCancel:global(.__wab_instance) {
  position: relative;
}
.submitBtn9 {
  border-width: 1px;
  border-style: none;
}
.freeBox___4Qc19 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox___4Qc19 {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: 0;
    padding: 8px;
  }
}
.btnHelp:global(.__wab_instance) {
  position: relative;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.text__eg8Lb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 278px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: calc(20px + 20px) !important;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  min-width: 0;
  display: none;
}
.textstatus_successSingle__eg8LbZ31Kx {
  margin-top: calc(20px + 20px) !important;
  display: block;
}
.freeBox__wsYzi {
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBoxstatus_csvUpload__wsYzIhE6B {
  display: flex;
}
.txtInput {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-right: 10px;
  background: var(--token-DTIYECEe2);
  margin-right: 10px;
  padding-left: 10px;
  min-width: 0;
  border-radius: 5px;
  border-width: 1px;
}
.btnChoose:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 113px;
  flex-shrink: 0;
}

.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__nZvc {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/lightgreensvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.navPageTitle:global(.__wab_instance) {
  position: relative;
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
.freeBox__jdu2D {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: auto;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__jdu2D {
    align-self: center;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: none;
    height: 100%;
    min-height: 0;
    padding: 8px;
  }
}
.freeBox__ovzWd {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__ovzWd > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__ovzWd > :global(.__wab_flex-container) > *,
.freeBox__ovzWd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ovzWd > :global(.__wab_flex-container) > picture > img,
.freeBox__ovzWd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .freeBox__ovzWd {
    width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__ovzWd > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .freeBox__ovzWd > :global(.__wab_flex-container) > *,
  .freeBox__ovzWd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__ovzWd > :global(.__wab_flex-container) > picture > img,
  .freeBox__ovzWd
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.freeBox__zwm4E {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__zwm4E > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__zwm4E > :global(.__wab_flex-container) > *,
.freeBox__zwm4E > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zwm4E > :global(.__wab_flex-container) > picture > img,
.freeBox__zwm4E
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.iconRound:global(.__wab_instance) {
  position: relative;
  width: 35px;
  height: 35px;
  flex-shrink: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.text___6OFiS {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: center;
  background: none;
  min-width: 0;
  border-radius: 4px;
}
@media (min-width: 380px) {
  .text___6OFiS {
    color: var(--token-vYO6h4FW3);
  }
}
.inputFirstName:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.inputLastName:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.inputGender:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.inputId:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.dateOfBirth:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__q8Niw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__q8Niw > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.freeBox__q8Niw > :global(.__wab_flex-container) > *,
.freeBox__q8Niw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__q8Niw > :global(.__wab_flex-container) > picture > img,
.freeBox__q8Niw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.text__nWWcQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-T-yLSgErM);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  min-width: 0;
}
.inputCellNumber:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__tXUq {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.submitBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn2 {
  border-width: 1px;
  border-style: none;
}
.freeBox__c46Ba {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.backBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn4 {
  border-width: 1px;
  border-style: none;
}
.footerWidget:global(.__wab_instance) {
  position: relative;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-oWf5ggoEo);
  position: relative;
  width: 100%;
  min-width: 280px;
  max-width: 280px;
  border-radius: 8px;
  padding: 8px;
}
.img___4YnEw {
  position: relative;
  object-fit: contain;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.img___4YnEw > picture > img {
  object-fit: contain;
}
.freeBox__acNmr {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__vjEVz {
  display: flex;
  position: relative;
  width: 90px;
  flex-shrink: 0;
}
.slotTargetSlot4 {
  font-family: "Poppins", sans-serif;
  font-size: 21px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
}
.freeBox__sqzTd {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  min-width: 0;
}
.slotTargetSlot5 {
  font-size: 14px;
}
.freeBox__i4Lwf {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  padding: 8px;
  border-bottom: 1px solid var(--token-DTIYECEe2);
}
.freeBox__ig9C6 {
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
}
.slotTargetChildren {
  font-family: "Poppins", sans-serif;
  color: var(--token-vYO6h4FW3);
}
.slotTargetSlot {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: var(--token-vYO6h4FW3);
}
.freeBox__hkY5U {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  padding: 8px;
  border-bottom: 1px solid var(--token-DTIYECEe2);
}
.freeBox___4SNtA {
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
}
.slotTargetChildren2 {
  font-family: "Poppins", sans-serif;
  color: var(--token-vYO6h4FW3);
}
.slotTargetSlot2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: var(--token-vYO6h4FW3);
}
.freeBox__bQfBv {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  padding: 8px;
  border-bottom: 1px solid var(--token-DTIYECEe2);
}
.freeBox__spSg {
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
}
.slotTargetChildren3 {
  font-family: "Poppins", sans-serif;
  color: var(--token-vYO6h4FW3);
}
.slotTargetSlot3 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: var(--token-vYO6h4FW3);
}
.text {
  position: relative;
  font-size: 12px;
  font-style: italic;
  padding: 8px;
}

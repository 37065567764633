.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: var(--token-oWf5ggoEo);
  min-width: 0;
  border-radius: 4px;
  padding: 4px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
}
.rooteditField {
  display: flex;
  flex-direction: row;
}
.rooteditField > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rooteditField > :global(.__wab_flex-container) > *,
.rooteditField > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rooteditField > :global(.__wab_flex-container) > picture > img,
.rooteditField
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.freeBox__cbjGa {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__uz7Pf {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  background: none;
  min-width: 0;
}
.freeBox__uz7Pf > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
}
@media (min-width: 380px) {
  .freeBox__uz7Pf > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__uz7Pf > :global(.__wab_flex-container) > *,
  .freeBox__uz7Pf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__uz7Pf > :global(.__wab_flex-container) > picture > img,
  .freeBox__uz7Pf
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
.freeBox__p6Ar6 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
}
.btnUpload:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 380px) {
  .btnUpload:global(.__wab_instance) {
    max-width: none;
    width: auto;
    height: auto;
  }
}
@media (min-width: 280px) {
  .text {
    width: auto;
  }
}
.svg__dHOj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-h1nwR9emp);
  height: 1em;
}
.svgcompleted__dHOjOn0Eb {
  display: block;
}
.freeBox__rpjb3 {
  position: relative;
  flex-direction: row;
  display: none;
  padding: 10px;
}
.freeBox__rpjb3 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__rpjb3 > :global(.__wab_flex-container) > *,
.freeBox__rpjb3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__rpjb3 > :global(.__wab_flex-container) > picture > img,
.freeBox__rpjb3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBoxshowError__rpjb3MJgh {
  display: flex;
}
.svg__riGqE {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: var(--token-cE0h0jVeC);
  flex-shrink: 0;
}
.txtError {
  position: relative;
  color: var(--token-cE0h0jVeC);
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  min-width: 0;
}
.progress {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 4px;
  max-width: 100%;
  background: var(--token-h1nwR9emp);
  left: 0px;
  top: auto;
  max-height: 21px;
  z-index: 1;
  bottom: 0px;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
}
.btnEdit:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: center;
  display: none;
}
.btnEditeditField:global(.__wab_instance) {
  display: flex;
}

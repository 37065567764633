.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: auto;
  align-items: center;
  min-width: 0;
}
.rootisDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.root___focusVisibleWithin {
  outline: none;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transition-property: all;
  transition-duration: 0.2s;
  background: #fafafa;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.2s;
  border-radius: 4px;
  border: 1px solid #e5e5ea;
}
.root:hover .freeBox {
  background: #f2f2f7;
}
.root:focus-within .freeBox {
  outline: none;
  border-color: #007aff;
}
.root .freeBox___focusVisibleWithin {
  outline: none;
  border-style: dashed;
  border-color: #007aff;
}
.svg {
  position: relative;
  object-fit: cover;
  color: transparent;
  height: 1em;
  width: auto;
}
.svgisChecked {
  color: var(--token-c79ojIUd1);
}
.svgisIndeterminate {
  color: var(--token-c79ojIUd1);
}
.root:focus-within .svg {
  outline: none;
}
.root .svg___focusVisibleWithin {
  outline: none;
}
.labelContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: 8px;
}
.root .labelContainer___focusVisibleWithin {
  outline: none;
}
.slotTargetChildren {
  white-space: pre;
}
.slotTargetChildren > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_expr_html_text),
.slotTargetChildren > :global(.__wab_slot-string-wrapper),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetChildren > *,
.slotTargetChildren > :global(.__wab_slot) > *,
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetChildren > picture > img,
.slotTargetChildren > :global(.__wab_slot) > picture > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.root .slotTargetChildren___focusVisibleWithin > *,
.root .slotTargetChildren___focusVisibleWithin > :global(.__wab_slot) > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root .slotTargetChildren___focusVisibleWithin > picture > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}

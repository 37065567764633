.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #000000b5;
  min-width: 0;
  min-height: 0;
  border-radius: 0px;
}
.freeBox__y1Kj9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  align-self: center;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
  border-radius: 0em;
}
.freeBox__tz5FT {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.freeBox__tz5FT > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__tz5FT > :global(.__wab_flex-container) > *,
.freeBox__tz5FT > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tz5FT > :global(.__wab_flex-container) > picture > img,
.freeBox__tz5FT
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBox___4SEx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 45px;
  height: 45px;
  color: var(--token-G3sstVR3F);
  flex-shrink: 0;
}
.svgerror {
  color: var(--token-cE0h0jVeC);
}
.svgstatus_failed {
  color: var(--token-cE0h0jVeC);
}
.svgstatus_loading {
  display: none;
}
.freeBox__znAsr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 80%;
  padding: 16px;
}
.txtModal {
  position: relative;
  color: #ffffff;
  align-self: center;
  width: 100%;
  height: 100%;
  text-align: center;
  min-width: 0;
  min-height: 0;
}
.linkerror {
  color: var(--token-h1nwR9emp);
}
._continue:global(.__wab_instance) {
  position: relative;
  width: auto;
}
._continuestatus_loading:global(.__wab_instance) {
  display: none;
}
.skip:global(.__wab_instance) {
  position: relative;
  width: auto;
}

.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.freeBox__eSqZ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.freeBox__l0Whn {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.freeBox__dJkNv {
  display: flex;
  position: relative;
  flex-direction: row;
  align-self: center;
}
.freeBox__dJkNv > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__dJkNv > :global(.__wab_flex-container) > *,
.freeBox__dJkNv > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dJkNv > :global(.__wab_flex-container) > picture > img,
.freeBox__dJkNv
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBox__tRAaf {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.day {
  position: relative;
  width: 53px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
}
.freeBox__kFaEj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.month {
  position: relative;
  width: 70px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
}
.freeBox__hAsaw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.year {
  position: relative;
  width: 91px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
}
.freeBox__eK9Dn {
  position: relative;
  flex-direction: row;
  display: none;
  padding: 10px;
}
.freeBox__eK9Dn > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__eK9Dn > :global(.__wab_flex-container) > *,
.freeBox__eK9Dn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__eK9Dn > :global(.__wab_flex-container) > picture > img,
.freeBox__eK9Dn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBoxshowError__eK9DNuA1JQ {
  display: flex;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: var(--token-0UblY07ffKa2jQ);
  flex-shrink: 0;
}
.txtError {
  position: relative;
  color: var(--token-0UblY07ffKa2jQ);
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  min-width: 0;
}

.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #000000b5;
  min-width: 0;
  min-height: 0;
  border-radius: 8px;
}
.freeBox__beAzc {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  align-self: center;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 30px;
}
.btnClose {
  position: absolute;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: #ffffff;
  align-self: flex-end;
  cursor: pointer;
  bottom: auto;
  top: 5px;
  left: auto;
  right: 5px;
  flex-shrink: 0;
}
.freeBox__ijNoS {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.freeBox__ijNoS > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__ijNoS > :global(.__wab_flex-container) > *,
.freeBox__ijNoS > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ijNoS > :global(.__wab_flex-container) > picture > img,
.freeBox__ijNoS
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.iconRound:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.freeBox__oxYs {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.errorTxt {
  position: relative;
  color: #ffffff;
  align-self: center;
  width: 100%;
  height: auto;
  text-align: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__rfZq6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 8px;
}
.btnAdd:global(.__wab_instance) {
  position: relative;
}
.text___2W7W {
  height: auto;
}
.freeBox__g0Kk {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 8px;
}
.btnCloseMain:global(.__wab_instance) {
  position: relative;
}
.text___1LJta {
  height: auto;
}

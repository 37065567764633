.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__v5UO2 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 768px) {
  .freeBox__v5UO2 {
    background: url("./images/lightgreensvg.svg") 0% 0% / contain repeat-x;
  }
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
.outer2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
.freeBox__bpVa4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: 100%;
  min-height: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__bpVa4 {
    align-self: center;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: none;
    height: 100%;
    min-height: 0;
    padding: 8px;
  }
}
.freeBox__pddus {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__pddus > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__pddus > :global(.__wab_flex-container) > *,
.freeBox__pddus > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pddus > :global(.__wab_flex-container) > picture > img,
.freeBox__pddus
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
@media (min-width: 380px) {
  .freeBox__pddus > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 380px) {
  .freeBox__pddus > :global(.__wab_flex-container) > *,
  .freeBox__pddus > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__pddus > :global(.__wab_flex-container) > picture > img,
  .freeBox__pddus
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__pddus {
    width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__pddus > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .freeBox__pddus > :global(.__wab_flex-container) > *,
  .freeBox__pddus > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__pddus > :global(.__wab_flex-container) > picture > img,
  .freeBox__pddus
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.text__tJKs1 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__bnaJx {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBox__bnaJx > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__bnaJx {
    background: #eaf0e5;
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__bnaJx > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__bnaJx > :global(.__wab_flex-container) > *,
  .freeBox__bnaJx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__bnaJx > :global(.__wab_flex-container) > picture > img,
  .freeBox__bnaJx
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.freeBox___6Apaf {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox___6Apaf > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox___6Apaf {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
  }
}
@media (min-width: 280px) {
  .freeBox___6Apaf > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox___6Apaf > :global(.__wab_flex-container) > *,
  .freeBox___6Apaf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___6Apaf > :global(.__wab_flex-container) > picture > img,
  .freeBox___6Apaf
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox___6Apaf > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox___6Apaf > :global(.__wab_flex-container) > *,
  .freeBox___6Apaf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___6Apaf > :global(.__wab_flex-container) > picture > img,
  .freeBox___6Apaf
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__lyVQn:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 280px) {
  .iconNumber__lyVQn:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (min-width: 380px) {
  .iconNumber__lyVQn:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text___7GWbh {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text___7GWbh {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text___7GWbh {
    font-size: 11px;
  }
}
.selectDependant:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__gXy54 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__gXy54 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__gXy54 {
    background: var(--token-gmc_Ctzg2);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__gXy54 > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__gXy54 > :global(.__wab_flex-container) > *,
  .freeBox__gXy54 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__gXy54 > :global(.__wab_flex-container) > picture > img,
  .freeBox__gXy54
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox__gXy54 {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox__lxh2B {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__lxh2B > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__lxh2B {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__lxh2B > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__lxh2B > :global(.__wab_flex-container) > *,
  .freeBox__lxh2B > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__lxh2B > :global(.__wab_flex-container) > picture > img,
  .freeBox__lxh2B
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__lxh2B > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__lxh2B > :global(.__wab_flex-container) > *,
  .freeBox__lxh2B > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__lxh2B > :global(.__wab_flex-container) > picture > img,
  .freeBox__lxh2B
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber___8VSjW:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber___8VSjW:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__iR3Av {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__iR3Av {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__iR3Av {
    font-size: 11px;
  }
}
.svg__rva9O {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
  display: none;
}
@media (min-width: 280px) {
  .svg__rva9O {
    display: none;
  }
}
@media (min-width: 380px) {
  .svg__rva9O {
    display: none;
  }
}
.btnInfo {
  position: absolute;
  object-fit: cover;
  color: var(--token-vYO6h4FW3);
  width: auto;
  height: 30px;
  left: auto;
  top: -4px;
  right: 1px;
}
@media (min-width: 280px) {
  .btnInfo {
    display: block;
  }
}
@media (min-width: 380px) {
  .btnInfo {
    display: block;
  }
}
.selectNatureofDeath:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__c2T3 {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__c2T3 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__c2T3 {
    background: var(--token-gmc_Ctzg2);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__c2T3 > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__c2T3 > :global(.__wab_flex-container) > *,
  .freeBox__c2T3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__c2T3 > :global(.__wab_flex-container) > picture > img,
  .freeBox__c2T3
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox__c2T3 {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox__yVjeK {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__yVjeK > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__yVjeK {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__yVjeK > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__yVjeK > :global(.__wab_flex-container) > *,
  .freeBox__yVjeK > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__yVjeK > :global(.__wab_flex-container) > picture > img,
  .freeBox__yVjeK
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__yVjeK > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__yVjeK > :global(.__wab_flex-container) > *,
  .freeBox__yVjeK > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__yVjeK > :global(.__wab_flex-container) > picture > img,
  .freeBox__yVjeK
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__bGmuN:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber__bGmuN:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (min-width: 280px) {
  .text__zm7Ep {
    width: auto;
  }
}
.text__g4X9X {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__g4X9X {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__g4X9X {
    font-size: 11px;
  }
}
.inputStreet:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.selectCity:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.selectProvince:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__xShc {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__xShc > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__xShc {
    background: linear-gradient(var(--token-gmc_Ctzg2), var(--token-gmc_Ctzg2)),
      #eaf0e5;
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__xShc > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__xShc > :global(.__wab_flex-container) > *,
  .freeBox__xShc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__xShc > :global(.__wab_flex-container) > picture > img,
  .freeBox__xShc
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox__xShc {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox__hgRc1 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__hgRc1 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__hgRc1 {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__hgRc1 > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__hgRc1 > :global(.__wab_flex-container) > *,
  .freeBox__hgRc1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__hgRc1 > :global(.__wab_flex-container) > picture > img,
  .freeBox__hgRc1
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__hgRc1 > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__hgRc1 > :global(.__wab_flex-container) > *,
  .freeBox__hgRc1 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__hgRc1 > :global(.__wab_flex-container) > picture > img,
  .freeBox__hgRc1
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber___7Yu8F:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber___7Yu8F:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__nz10P {
  padding-right: 0px;
}
.text__q6CwG {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__q6CwG {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__q6CwG {
    font-size: 11px;
  }
}
.postalCheck:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__yw4BR {
  font-family: "Poppins", sans-serif;
  width: 100%;
  white-space: pre-wrap;
  font-size: 11px;
  min-width: 0;
}
.residentialStreet:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.residentialCity:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.residentialProvince:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__btD4O {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__btD4O > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__btD4O {
    background: linear-gradient(var(--token-gmc_Ctzg2), var(--token-gmc_Ctzg2)),
      #eaf0e5;
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__btD4O > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__btD4O > :global(.__wab_flex-container) > *,
  .freeBox__btD4O > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__btD4O > :global(.__wab_flex-container) > picture > img,
  .freeBox__btD4O
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox__btD4O {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox__ydi9U {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__ydi9U > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__ydi9U {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__ydi9U > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__ydi9U > :global(.__wab_flex-container) > *,
  .freeBox__ydi9U > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__ydi9U > :global(.__wab_flex-container) > picture > img,
  .freeBox__ydi9U
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__ydi9U > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__ydi9U > :global(.__wab_flex-container) > *,
  .freeBox__ydi9U > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__ydi9U > :global(.__wab_flex-container) > picture > img,
  .freeBox__ydi9U
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__ugorx:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber__ugorx:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__sL1N {
  padding-right: 0px;
}
.text__byRGw {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
  height: auto;
}
@media (min-width: 280px) {
  .text__byRGw {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__byRGw {
    font-size: 11px;
  }
}
.downloadForm:global(.__wab_instance) {
  position: relative;
}
.submitBtn5 {
  border-width: 1px;
  border-style: none;
}
.freeBox___9So6E {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox___9So6E > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox___9So6E {
    background: var(--token-gmc_Ctzg2);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox___9So6E > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox___9So6E > :global(.__wab_flex-container) > *,
  .freeBox___9So6E > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___9So6E > :global(.__wab_flex-container) > picture > img,
  .freeBox___9So6E
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox___9So6E {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox__r1Si9 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__r1Si9 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__r1Si9 {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__r1Si9 > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__r1Si9 > :global(.__wab_flex-container) > *,
  .freeBox__r1Si9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__r1Si9 > :global(.__wab_flex-container) > picture > img,
  .freeBox__r1Si9
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__r1Si9 > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__r1Si9 > :global(.__wab_flex-container) > *,
  .freeBox__r1Si9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__r1Si9 > :global(.__wab_flex-container) > picture > img,
  .freeBox__r1Si9
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__pb2Cp:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber__pb2Cp:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__cBqwW {
  padding-right: 0px;
}
.text__jGsxe {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__jGsxe {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__jGsxe {
    font-size: 11px;
  }
}
.deceasedId:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .text___9Lf {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text___9Lf {
    font-size: 11px;
  }
}
.deathNotice:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .text__tmwry {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__tmwry {
    font-size: 11px;
  }
}
.bankStatements:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .text__a2CKf {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__a2CKf {
    font-size: 11px;
  }
}
.claimForm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .text___5P3Al {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text___5P3Al {
    font-size: 11px;
  }
}
.memberId:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .text__c0Epd {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__c0Epd {
    font-size: 11px;
  }
}
.deathCertificate:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .text__f5WsR {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__f5WsR {
    font-size: 11px;
  }
}
.freeBox__fgeUe {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__fgeUe > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__fgeUe {
    background: var(--token-gmc_Ctzg2);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__fgeUe > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__fgeUe > :global(.__wab_flex-container) > *,
  .freeBox__fgeUe > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__fgeUe > :global(.__wab_flex-container) > picture > img,
  .freeBox__fgeUe
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox__fgeUe {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox__nSun {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__nSun > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__nSun {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__nSun > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    min-width: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__nSun > :global(.__wab_flex-container) > *,
  .freeBox__nSun > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__nSun > :global(.__wab_flex-container) > picture > img,
  .freeBox__nSun
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__nSun > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__nSun > :global(.__wab_flex-container) > *,
  .freeBox__nSun > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__nSun > :global(.__wab_flex-container) > picture > img,
  .freeBox__nSun
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__kfdTb:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber__kfdTb:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__fPk3Q {
  padding-right: 0px;
}
.text__nd1Re {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__nd1Re {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__nd1Re {
    font-size: 11px;
  }
}
.freeBox__nzCls {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 280px) {
  .freeBox__nzCls {
    background: var(--token-oWf5ggoEo);
    max-height: 100px;
    overflow: auto;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
  }
}
@media (min-width: 380px) {
  .freeBox__nzCls {
    background: var(--token-oWf5ggoEo);
    overflow: auto;
    max-height: 100px;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
  }
}
.text___743P {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
@media (min-width: 280px) {
  .text___743P {
    font-size: 11px;
    padding-top: 0px;
  }
}
@media (min-width: 380px) {
  .text___743P {
    font-size: 9px;
  }
}
.declaration:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 380px) {
  .declaration:global(.__wab_instance) {
    display: none;
  }
}
.text___6OlPm {
  font-family: "Poppins", sans-serif;
  width: 100%;
  white-space: pre-wrap;
  font-size: 11px;
  min-width: 0;
}
.freeBox__hJIt {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.submitBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn2 {
  border-width: 1px;
  border-style: none;
}
.freeBox__pYjzo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.backBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn4 {
  border-width: 1px;
  border-style: none;
}
.freeBox__oCh8 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__oCh8 {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: 0;
    padding: 8px;
  }
}
.forgotBtn:global(.__wab_instance) {
  position: relative;
}
.svg__h64I8 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}

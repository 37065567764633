.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #cacaca7a;
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: 50%;
  padding: 4px;
}
.freeBox {
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: var(--token-vYO6h4FW3);
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  display: none;
  border-radius: 50%;
  padding: 4px;
}
.freeBoxcurrentSlide {
  background: var(--token-c79ojIUd1);
  height: 12px;
  width: 12px;
  flex-shrink: 0;
  display: flex;
}
.root:active .freeBox {
  width: 12px;
  height: 12px;
  background: var(--token-c79ojIUd1);
  flex-shrink: 0;
  display: flex;
}

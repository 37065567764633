.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 10px;
}
.freeBox__y2Fck {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.freeBox__umAnV {
  display: flex;
  position: relative;
  flex-direction: row;
  align-self: center;
}
.freeBox__umAnV > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__umAnV > :global(.__wab_flex-container) > *,
.freeBox__umAnV > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__umAnV > :global(.__wab_flex-container) > picture > img,
.freeBox__umAnV
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.text {
  position: relative;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}
.txtCell1 {
  position: relative;
  width: 53px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  flex-shrink: 0;
  border-radius: 5px;
}
.txtCell2 {
  position: relative;
  width: 70px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  flex-shrink: 0;
  border-radius: 5px;
}
.txtCell3 {
  position: relative;
  width: 91px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  flex-shrink: 0;
  border-radius: 5px;
}
.freeBox___3EJNz {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 10px;
}
.freeBox___3EJNz > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox___3EJNz > :global(.__wab_flex-container) > *,
.freeBox___3EJNz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___3EJNz > :global(.__wab_flex-container) > picture > img,
.freeBox___3EJNz
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBoxshowError___3EJNz7Pn4N {
  display: none;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 16px;
  height: 16px;
  color: var(--token-cE0h0jVeC);
  flex-shrink: 0;
}
.txtError {
  position: relative;
  color: var(--token-cE0h0jVeC);
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  min-width: 0;
}

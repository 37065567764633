.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
}
@media (min-width: 280px) {
  .root {
    width: 100%;
    min-width: 0;
    border-radius: 4px;
  }
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-GIpM-YWb9);
  align-self: center;
  min-width: 0;
  border-radius: 5px;
  padding: 8px;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBoxstatus_failed {
  background: var(--token-cE0h0jVeC);
}
.txtDate {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  text-align: left;
  min-width: 70px;
}
.txtAmount {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  min-width: 0;
}
.txtRef {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  text-align: center;
  min-width: 100px;
}
.btnFailed:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}

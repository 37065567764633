.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  background: var(--token-gmc_Ctzg2);
  height: 16px;
  position: relative;
  min-width: 0;
  border-radius: 2px;
  padding: 4px;
}
.progress {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 8px;
  background: var(--token-h1nwR9emp);
  flex-shrink: 0;
}

.root {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--token-h1nwR9emp);
  width: auto;
  height: auto;
  position: relative;
  padding: 4px;
}
.rootstatus_failed {
  background: var(--token-DQAUoP4aB);
}
.rootstatus_inactive {
  background: var(--token-vYO6h4FW3);
}
.slotTargetChildren {
  font-size: 8px;
  font-weight: 700;
}
.slotTargetChildrenstatus_failed {
  color: var(--token-oWf5ggoEo);
}
.slotTargetChildrenstatus_inactive {
  color: var(--token-oWf5ggoEo);
}

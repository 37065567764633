.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  position: relative;
  background: var(--token-quptvOxLbYUbhC);
  min-width: 0;
  border-radius: 8px 8px 0px 0px;
  padding: 8px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
@media (min-width: 380px) {
  .root {
    width: 100%;
    height: auto;
    background: var(--token-quptvOxLbYUbhC);
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 380px) {
  .root > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .root {
    width: 100%;
    min-width: 0;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .root > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: space-around;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
@media (min-width: 1045px) {
  .root > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: center;
  }
}
.rootmenuOpen {
  background: var(--token-quptvOxLbYUbhC);
}
.freeBox__nOghE {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 380px) {
  .freeBox__nOghE {
    align-items: center;
    justify-content: center;
    width: 242px;
    flex-shrink: 0;
  }
}
@media (min-width: 768px) {
  .freeBox__nOghE {
    width: 368px;
    flex-shrink: 0;
  }
}
@media (min-width: 1045px) {
  .freeBox__nOghE {
    width: 890px;
    flex-shrink: 0;
  }
}
.svg__kDkF {
  position: relative;
  object-fit: cover;
  width: 300px;
  height: 33px;
  color: var(--token-1Q1zU_qER8IeSd);
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .svg__kDkF {
    width: 246px;
    flex-shrink: 0;
  }
}
.freeBox__gIzAe {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.freeBox__bOdjL {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.svg__ulJeV {
  position: relative;
  object-fit: cover;
  color: var(--token-1Q1zU_qER8IeSd);
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

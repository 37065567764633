.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: var(--token-oWf5ggoEo);
  height: auto;
  box-shadow: 0px 0px 4px 2px #00000014;
  position: relative;
  border-radius: 4px;
  padding: 8px;
}
.mainTxt {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.itemList {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.faqItem__jbgIk:global(.__wab_instance) {
  position: relative;
}
.faqItem__uxSn4:global(.__wab_instance) {
  position: relative;
}
.faqItem__nlDW:global(.__wab_instance) {
  position: relative;
}
.faqItem__wJ3YA:global(.__wab_instance) {
  position: relative;
}

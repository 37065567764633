.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  position: relative;
  border-radius: 50%;
  padding: 0px;
}
@media (min-width: 1045px) {
  .root {
    cursor: pointer;
  }
}
.rootremove {
  background: none;
  padding: 0px;
}
.rootdisabled {
  background: none;
}
.btnAddBenficiary {
  position: relative;
  object-fit: cover;
  width: 30px;
  height: 30px;
  color: var(--token-quptvOxLbYUbhC);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  flex-shrink: 0;
}
.btnAddBenficiaryremove {
  color: var(--token-CJlCnGc1IsIX1v);
}
.btnAddBenficiarydisabled {
  color: var(--token-MA0AO2-MybYxFZ);
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.btnAddBenficiarydownload {
  display: none;
}
.svg__ehhRn {
  position: relative;
  object-fit: cover;
  max-width: 50px;
  color: var(--token-quptvOxLbYUbhC);
  height: 50px;
  width: 30px;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .svg__ehhRn {
    height: 30px;
    flex-shrink: 0;
  }
}
.svgdownload__ehhRnCje7S {
  display: block;
}
.svg__nWqx {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
  flex-shrink: 0;
}
.svgdownload__nWqxCje7S {
  display: none;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
}
.svg {
  position: relative;
  object-fit: cover;
  color: var(--token-TZwOGIB9DUOwST);
  cursor: pointer;
  height: 1em;
  flex-shrink: 0;
}

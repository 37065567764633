@import url("https://fonts.googleapis.com/css2?family=Roboto%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C700%3B0%2C900%3B1%2C400%3B1%2C500%3B1%2C700%3B1%2C900&family=Inter%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Poppins%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900%3B1%2C400%3B1%2C500%3B1%2C600%3B1%2C700%3B1%2C900&display=swap");

.plasmic_tokens {
  --token-hl3Zddiwv: #535353;
  --plasmic-token-dark-brown: var(--token-hl3Zddiwv);
  --token-c79ojIUd1: #b6b69a;
  --plasmic-token-light-brown: var(--token-c79ojIUd1);
  --token-vYO6h4FW3: #754c28;
  --plasmic-token-dark-green: var(--token-vYO6h4FW3);
  --token-h1nwR9emp: #a2d181;
  --plasmic-token-light-green: var(--token-h1nwR9emp);
  --token-G3sstVR3F: #e4eaa2;
  --plasmic-token-olive-green: var(--token-G3sstVR3F);
  --token-wNURBLgcg: #242a2a;
  --plasmic-token-black-90: var(--token-wNURBLgcg);
  --token-T-yLSgErM: #92938f;
  --plasmic-token-dark-grey: var(--token-T-yLSgErM);
  --token-DTIYECEe2: #c5c6c3;
  --plasmic-token-light-grey: var(--token-DTIYECEe2);
  --token-oWf5ggoEo: #ffffff;
  --plasmic-token-white: var(--token-oWf5ggoEo);
  --token-hKDDQTr7l: #0000004d;
  --plasmic-token-blackoverlay-30: var(--token-hKDDQTr7l);
  --token-gmc_Ctzg2: #efefef;
  --plasmic-token-light-bg: var(--token-gmc_Ctzg2);
  --token-m_h0UjzhR: #f8f8f8;
  --plasmic-token-b-ggrey-lite: var(--token-m_h0UjzhR);
  --token-0Uwcv5K4y: #00000033;
  --plasmic-token-dropshadow: var(--token-0Uwcv5K4y);
  --token-cE0h0jVeC: #ecba77;
  --plasmic-token-error: var(--token-cE0h0jVeC);
  --token-GIpM-YWb9: #eaf0e5;
  --plasmic-token-light-green-2: var(--token-GIpM-YWb9);
  --token-DQAUoP4aB: #d80202;
  --plasmic-token-red: var(--token-DQAUoP4aB);
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-wnIJR2VIQa_font-family: "Roboto", sans-serif;
  --mixin-wnIJR2VIQa_font-size: 16px;
  --mixin-wnIJR2VIQa_font-weight: 400;
  --mixin-wnIJR2VIQa_font-style: normal;
  --mixin-wnIJR2VIQa_color: #535353;
  --mixin-wnIJR2VIQa_text-align: left;
  --mixin-wnIJR2VIQa_text-transform: none;
  --mixin-wnIJR2VIQa_line-height: 1.5;
  --mixin-wnIJR2VIQa_letter-spacing: normal;
  --mixin-wnIJR2VIQa_white-space: pre-wrap;
  --mixin-wnIJR2VIQa_user-select: text;
  --mixin-wnIJR2VIQa_text-decoration-line: none;
  --mixin-wnIJR2VIQa_text-overflow: clip;
  --mixin-kByUqI4bM_font-family: "Inter", sans-serif;
  --mixin-kByUqI4bM_color: #000000;
  --mixin-kByUqI4bM_font-size: 72px;
  --mixin-kByUqI4bM_font-weight: 900;
  --mixin-kByUqI4bM_letter-spacing: -4px;
  --mixin-kByUqI4bM_line-height: 1;
  --mixin-kByUqI4bM_white-space: pre-wrap;
  --mixin-F6WAOVM0HO_font-family: "Inter", sans-serif;
  --mixin-F6WAOVM0HO_color: #000000;
  --mixin-F6WAOVM0HO_font-size: 48px;
  --mixin-F6WAOVM0HO_font-weight: 700;
  --mixin-F6WAOVM0HO_letter-spacing: -1px;
  --mixin-F6WAOVM0HO_line-height: 1.1;
  --mixin-F6WAOVM0HO_white-space: pre-wrap;
  --mixin-Ew7ZhkSy1R_font-family: "Inter", sans-serif;
  --mixin-Ew7ZhkSy1R_color: #000000;
  --mixin-Ew7ZhkSy1R_font-size: 32px;
  --mixin-Ew7ZhkSy1R_font-weight: 600;
  --mixin-Ew7ZhkSy1R_letter-spacing: -0.8px;
  --mixin-Ew7ZhkSy1R_line-height: 1.2;
  --mixin-Ew7ZhkSy1R_white-space: pre-wrap;
  --mixin-hJe-ymSWUQ_font-family: "Inter", sans-serif;
  --mixin-hJe-ymSWUQ_color: #000000;
  --mixin-hJe-ymSWUQ_font-size: 24px;
  --mixin-hJe-ymSWUQ_font-weight: 600;
  --mixin-hJe-ymSWUQ_letter-spacing: -0.5px;
  --mixin-hJe-ymSWUQ_line-height: 1.3;
  --mixin-hJe-ymSWUQ_white-space: pre-wrap;
  --mixin-mcCZpK4yBH_font-family: "Inter", sans-serif;
  --mixin-mcCZpK4yBH_color: #000000;
  --mixin-mcCZpK4yBH_font-size: 20px;
  --mixin-mcCZpK4yBH_font-weight: 600;
  --mixin-mcCZpK4yBH_letter-spacing: -0.3px;
  --mixin-mcCZpK4yBH_line-height: 1.5;
  --mixin-mcCZpK4yBH_white-space: pre-wrap;
  --mixin-PVWcsyBwcJ_font-family: "Inter", sans-serif;
  --mixin-PVWcsyBwcJ_color: #000000;
  --mixin-PVWcsyBwcJ_font-size: 16px;
  --mixin-PVWcsyBwcJ_font-weight: 600;
  --mixin-PVWcsyBwcJ_line-height: 1.5;
  --mixin-PVWcsyBwcJ_white-space: pre-wrap;
  --mixin-v8qkNX2682_border-left-color: #dddddd;
  --mixin-v8qkNX2682_border-left-style: solid;
  --mixin-v8qkNX2682_border-left-width: 3px;
  --mixin-v8qkNX2682_color: #888888;
  --mixin-v8qkNX2682_padding-left: 10px;
  --mixin-v8qkNX2682_white-space: pre-wrap;
  --mixin-zUlKIkO3yJ_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-zUlKIkO3yJ_border-bottom-color: #dddddd;
  --mixin-zUlKIkO3yJ_border-bottom-style: solid;
  --mixin-zUlKIkO3yJ_border-bottom-width: 1px;
  --mixin-zUlKIkO3yJ_border-left-color: #dddddd;
  --mixin-zUlKIkO3yJ_border-left-style: solid;
  --mixin-zUlKIkO3yJ_border-left-width: 1px;
  --mixin-zUlKIkO3yJ_border-right-color: #dddddd;
  --mixin-zUlKIkO3yJ_border-right-style: solid;
  --mixin-zUlKIkO3yJ_border-right-width: 1px;
  --mixin-zUlKIkO3yJ_border-top-color: #dddddd;
  --mixin-zUlKIkO3yJ_border-top-style: solid;
  --mixin-zUlKIkO3yJ_border-top-width: 1px;
  --mixin-zUlKIkO3yJ_border-bottom-left-radius: 3px;
  --mixin-zUlKIkO3yJ_border-bottom-right-radius: 3px;
  --mixin-zUlKIkO3yJ_border-top-left-radius: 3px;
  --mixin-zUlKIkO3yJ_border-top-right-radius: 3px;
  --mixin-zUlKIkO3yJ_font-family: "Inconsolata";
  --mixin-zUlKIkO3yJ_padding-bottom: 1px;
  --mixin-zUlKIkO3yJ_padding-left: 4px;
  --mixin-zUlKIkO3yJ_padding-right: 4px;
  --mixin-zUlKIkO3yJ_padding-top: 1px;
  --mixin-zUlKIkO3yJ_white-space: pre-wrap;
  --mixin-onLPbOd_Db_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-onLPbOd_Db_border-bottom-color: #dddddd;
  --mixin-onLPbOd_Db_border-bottom-style: solid;
  --mixin-onLPbOd_Db_border-bottom-width: 1px;
  --mixin-onLPbOd_Db_border-left-color: #dddddd;
  --mixin-onLPbOd_Db_border-left-style: solid;
  --mixin-onLPbOd_Db_border-left-width: 1px;
  --mixin-onLPbOd_Db_border-right-color: #dddddd;
  --mixin-onLPbOd_Db_border-right-style: solid;
  --mixin-onLPbOd_Db_border-right-width: 1px;
  --mixin-onLPbOd_Db_border-top-color: #dddddd;
  --mixin-onLPbOd_Db_border-top-style: solid;
  --mixin-onLPbOd_Db_border-top-width: 1px;
  --mixin-onLPbOd_Db_border-bottom-left-radius: 3px;
  --mixin-onLPbOd_Db_border-bottom-right-radius: 3px;
  --mixin-onLPbOd_Db_border-top-left-radius: 3px;
  --mixin-onLPbOd_Db_border-top-right-radius: 3px;
  --mixin-onLPbOd_Db_font-family: "Inconsolata";
  --mixin-onLPbOd_Db_padding-bottom: 3px;
  --mixin-onLPbOd_Db_padding-left: 6px;
  --mixin-onLPbOd_Db_padding-right: 6px;
  --mixin-onLPbOd_Db_padding-top: 3px;
  --mixin-onLPbOd_Db_white-space: pre-wrap;
  --mixin-wT-8OLSdkm_display: flex;
  --mixin-wT-8OLSdkm_flex-direction: column;
  --mixin-wT-8OLSdkm_align-items: stretch;
  --mixin-wT-8OLSdkm_justify-content: flex-start;
  --mixin-wT-8OLSdkm_list-style-position: outside;
  --mixin-wT-8OLSdkm_padding-left: 40px;
  --mixin-wT-8OLSdkm_position: relative;
  --mixin-wT-8OLSdkm_list-style-type: decimal;
  --mixin-wT-8OLSdkm_white-space: pre-wrap;
  --mixin-aGWYU-9kbW_display: flex;
  --mixin-aGWYU-9kbW_flex-direction: column;
  --mixin-aGWYU-9kbW_align-items: stretch;
  --mixin-aGWYU-9kbW_justify-content: flex-start;
  --mixin-aGWYU-9kbW_list-style-position: outside;
  --mixin-aGWYU-9kbW_padding-left: 40px;
  --mixin-aGWYU-9kbW_position: relative;
  --mixin-aGWYU-9kbW_list-style-type: disc;
  --mixin-aGWYU-9kbW_white-space: pre-wrap;
}

.plasmic_mixins {
  --mixin-0XEPa_eHm_white-space: pre-wrap;
  --plasmic-mixin-formfields_white-space: var(--mixin-0XEPa_eHm_white-space);
  --mixin-g9SSL5AjC_white-space: pre-wrap;
  --plasmic-mixin-h-2-white_white-space: var(--mixin-g9SSL5AjC_white-space);
  --mixin-8dipnLgKR_white-space: pre-wrap;
  --plasmic-mixin-cardheader_white-space: var(--mixin-8dipnLgKR_white-space);
  --mixin-CYgbkuI-F_box-shadow: 0px 0px 4px 2px var(--token-0Uwcv5K4y);
  --plasmic-mixin-dropshadow_box-shadow: var(--mixin-CYgbkuI-F_box-shadow);
  --mixin-CYgbkuI-F_white-space: pre-wrap;
  --plasmic-mixin-dropshadow_white-space: var(--mixin-CYgbkuI-F_white-space);
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-wnIJR2VIQa_font-family);
  font-size: var(--mixin-wnIJR2VIQa_font-size);
  font-weight: var(--mixin-wnIJR2VIQa_font-weight);
  font-style: var(--mixin-wnIJR2VIQa_font-style);
  color: var(--mixin-wnIJR2VIQa_color);
  text-align: var(--mixin-wnIJR2VIQa_text-align);
  text-transform: var(--mixin-wnIJR2VIQa_text-transform);
  line-height: var(--mixin-wnIJR2VIQa_line-height);
  letter-spacing: var(--mixin-wnIJR2VIQa_letter-spacing);
  white-space: var(--mixin-wnIJR2VIQa_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  font-family: var(--mixin-kByUqI4bM_font-family);
  color: var(--mixin-kByUqI4bM_color);
  font-size: var(--mixin-kByUqI4bM_font-size);
  font-weight: var(--mixin-kByUqI4bM_font-weight);
  letter-spacing: var(--mixin-kByUqI4bM_letter-spacing);
  line-height: var(--mixin-kByUqI4bM_line-height);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  font-family: var(--mixin-F6WAOVM0HO_font-family);
  color: var(--mixin-F6WAOVM0HO_color);
  font-size: var(--mixin-F6WAOVM0HO_font-size);
  font-weight: var(--mixin-F6WAOVM0HO_font-weight);
  letter-spacing: var(--mixin-F6WAOVM0HO_letter-spacing);
  line-height: var(--mixin-F6WAOVM0HO_line-height);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  font-family: var(--mixin-Ew7ZhkSy1R_font-family);
  color: var(--mixin-Ew7ZhkSy1R_color);
  font-size: var(--mixin-Ew7ZhkSy1R_font-size);
  font-weight: var(--mixin-Ew7ZhkSy1R_font-weight);
  letter-spacing: var(--mixin-Ew7ZhkSy1R_letter-spacing);
  line-height: var(--mixin-Ew7ZhkSy1R_line-height);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  font-family: var(--mixin-hJe-ymSWUQ_font-family);
  color: var(--mixin-hJe-ymSWUQ_color);
  font-size: var(--mixin-hJe-ymSWUQ_font-size);
  font-weight: var(--mixin-hJe-ymSWUQ_font-weight);
  letter-spacing: var(--mixin-hJe-ymSWUQ_letter-spacing);
  line-height: var(--mixin-hJe-ymSWUQ_line-height);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  font-family: var(--mixin-mcCZpK4yBH_font-family);
  color: var(--mixin-mcCZpK4yBH_color);
  font-size: var(--mixin-mcCZpK4yBH_font-size);
  font-weight: var(--mixin-mcCZpK4yBH_font-weight);
  letter-spacing: var(--mixin-mcCZpK4yBH_letter-spacing);
  line-height: var(--mixin-mcCZpK4yBH_line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  font-family: var(--mixin-PVWcsyBwcJ_font-family);
  color: var(--mixin-PVWcsyBwcJ_color);
  font-size: var(--mixin-PVWcsyBwcJ_font-size);
  font-weight: var(--mixin-PVWcsyBwcJ_font-weight);
  line-height: var(--mixin-PVWcsyBwcJ_line-height);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-v8qkNX2682_color);
  padding-left: var(--mixin-v8qkNX2682_padding-left);
  border-left: var(--mixin-v8qkNX2682_border-left-width)
    var(--mixin-v8qkNX2682_border-left-style)
    var(--mixin-v8qkNX2682_border-left-color);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-zUlKIkO3yJ_font-family);
  border-radius: var(--mixin-zUlKIkO3yJ_border-top-left-radius)
    var(--mixin-zUlKIkO3yJ_border-top-right-radius)
    var(--mixin-zUlKIkO3yJ_border-bottom-right-radius)
    var(--mixin-zUlKIkO3yJ_border-bottom-left-radius);
  padding: var(--mixin-zUlKIkO3yJ_padding-top)
    var(--mixin-zUlKIkO3yJ_padding-right) var(--mixin-zUlKIkO3yJ_padding-bottom)
    var(--mixin-zUlKIkO3yJ_padding-left);
  border-top: var(--mixin-zUlKIkO3yJ_border-top-width)
    var(--mixin-zUlKIkO3yJ_border-top-style)
    var(--mixin-zUlKIkO3yJ_border-top-color);
  border-right: var(--mixin-zUlKIkO3yJ_border-right-width)
    var(--mixin-zUlKIkO3yJ_border-right-style)
    var(--mixin-zUlKIkO3yJ_border-right-color);
  border-bottom: var(--mixin-zUlKIkO3yJ_border-bottom-width)
    var(--mixin-zUlKIkO3yJ_border-bottom-style)
    var(--mixin-zUlKIkO3yJ_border-bottom-color);
  border-left: var(--mixin-zUlKIkO3yJ_border-left-width)
    var(--mixin-zUlKIkO3yJ_border-left-style)
    var(--mixin-zUlKIkO3yJ_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-onLPbOd_Db_font-family);
  border-radius: var(--mixin-onLPbOd_Db_border-top-left-radius)
    var(--mixin-onLPbOd_Db_border-top-right-radius)
    var(--mixin-onLPbOd_Db_border-bottom-right-radius)
    var(--mixin-onLPbOd_Db_border-bottom-left-radius);
  padding: var(--mixin-onLPbOd_Db_padding-top)
    var(--mixin-onLPbOd_Db_padding-right) var(--mixin-onLPbOd_Db_padding-bottom)
    var(--mixin-onLPbOd_Db_padding-left);
  border-top: var(--mixin-onLPbOd_Db_border-top-width)
    var(--mixin-onLPbOd_Db_border-top-style)
    var(--mixin-onLPbOd_Db_border-top-color);
  border-right: var(--mixin-onLPbOd_Db_border-right-width)
    var(--mixin-onLPbOd_Db_border-right-style)
    var(--mixin-onLPbOd_Db_border-right-color);
  border-bottom: var(--mixin-onLPbOd_Db_border-bottom-width)
    var(--mixin-onLPbOd_Db_border-bottom-style)
    var(--mixin-onLPbOd_Db_border-bottom-color);
  border-left: var(--mixin-onLPbOd_Db_border-left-width)
    var(--mixin-onLPbOd_Db_border-left-style)
    var(--mixin-onLPbOd_Db_border-left-color);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-wT-8OLSdkm_display);
  flex-direction: var(--mixin-wT-8OLSdkm_flex-direction);
  align-items: var(--mixin-wT-8OLSdkm_align-items);
  justify-content: var(--mixin-wT-8OLSdkm_justify-content);
  list-style-position: var(--mixin-wT-8OLSdkm_list-style-position);
  padding-left: var(--mixin-wT-8OLSdkm_padding-left);
  position: var(--mixin-wT-8OLSdkm_position);
  list-style-type: var(--mixin-wT-8OLSdkm_list-style-type);
  flex-column-gap: var(--mixin-wT-8OLSdkm_flex-column-gap);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-aGWYU-9kbW_display);
  flex-direction: var(--mixin-aGWYU-9kbW_flex-direction);
  align-items: var(--mixin-aGWYU-9kbW_align-items);
  justify-content: var(--mixin-aGWYU-9kbW_justify-content);
  list-style-position: var(--mixin-aGWYU-9kbW_list-style-position);
  padding-left: var(--mixin-aGWYU-9kbW_padding-left);
  position: var(--mixin-aGWYU-9kbW_position);
  list-style-type: var(--mixin-aGWYU-9kbW_list-style-type);
  flex-column-gap: var(--mixin-aGWYU-9kbW_flex-column-gap);
}

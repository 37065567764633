.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: var(--token-cY0jcsTj1DCRqX);
  position: relative;
  border-radius: 50%;
  padding: 4px;
}
.freeBox {
  position: relative;
  width: auto;
  height: auto;
  align-self: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.slotTargetChildren {
  font-size: 9px;
  font-weight: 700;
  color: var(--token-TZwOGIB9DUOwST);
  text-align: center;
}
@media (min-width: 380px) {
  .slotTargetChildren {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }
}

.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.nameTxt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-h1nwR9emp);
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.svgstatus_error {
  color: var(--token-DQAUoP4aB);
}

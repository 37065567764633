.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__wyhsG {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 768px) {
  .freeBox__wyhsG {
    background: url("./images/lightgreensvg.svg") 0% 0% / contain repeat-x;
  }
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
.freeBox___0VCzA {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: 100%;
  min-height: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox___0VCzA {
    align-self: center;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: none;
    height: 100%;
    min-height: 0;
    padding: 8px;
  }
}
.freeBox__fyyv0 {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__fyyv0 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__fyyv0 > :global(.__wab_flex-container) > *,
.freeBox__fyyv0 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fyyv0 > :global(.__wab_flex-container) > picture > img,
.freeBox__fyyv0
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .freeBox__fyyv0 {
    width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__fyyv0 > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .freeBox__fyyv0 > :global(.__wab_flex-container) > *,
  .freeBox__fyyv0 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__fyyv0 > :global(.__wab_flex-container) > picture > img,
  .freeBox__fyyv0
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.text__b553X {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.profileArea:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__lktlg {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBox__lktlg > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__lktlg {
    background: #eaf0e5;
    display: flex;
    border-radius: 4px;
  }
}
@media (min-width: 280px) {
  .freeBox__lktlg > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__lktlg > :global(.__wab_flex-container) > *,
  .freeBox__lktlg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__lktlg > :global(.__wab_flex-container) > picture > img,
  .freeBox__lktlg
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.freeBox___4CoTn {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 280px) {
  .freeBox___4CoTn {
    align-items: center;
    justify-content: space-between;
    padding: 0px;
  }
}
.text__ticDh {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__ticDh {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
.gotoPlans:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .gotoPlans:global(.__wab_instance) {
    max-width: none;
  }
}
.planHolder {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 280px) {
  .planHolder {
    align-items: center;
    justify-content: center;
  }
}
.freeBox__jkk5A {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBox__jkk5A > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__jkk5A {
    background: #eaf0e5;
    display: flex;
    border-radius: 4px;
  }
}
@media (min-width: 280px) {
  .freeBox__jkk5A > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__jkk5A > :global(.__wab_flex-container) > *,
  .freeBox__jkk5A > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__jkk5A > :global(.__wab_flex-container) > picture > img,
  .freeBox__jkk5A
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.freeBox__lffxg {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 280px) {
  .freeBox__lffxg {
    align-items: center;
    justify-content: space-between;
    padding: 0px;
  }
}
.text__kbo9R {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
  padding-right: 0px;
}
@media (min-width: 280px) {
  .text__kbo9R {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
.gotoPlans2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .gotoPlans2:global(.__wab_instance) {
    max-width: none;
  }
}
.text__nYa2O {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-vYO6h4FW3);
  font-size: 12px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  min-width: 0;
}
.inputOldPassword:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__jwht8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-vYO6h4FW3);
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  min-width: 0;
}
@media (min-width: 280px) {
  .text__jwht8 {
    font-size: 12px;
  }
}
.inputNewPassword:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__tr7Kb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-vYO6h4FW3);
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  min-width: 0;
}
@media (min-width: 280px) {
  .text__tr7Kb {
    font-size: 12px;
  }
}
.inputConfirmPassword:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.btnChangePassword:global(.__wab_instance) {
  position: relative;
}
.submitBtn5 {
  border-width: 1px;
  border-style: none;
}
.freeBox___9X0Pn {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox___9X0Pn > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox___9X0Pn {
    background: var(--token-gmc_Ctzg2);
    display: flex;
    border-radius: 4px;
  }
}
@media (min-width: 280px) {
  .freeBox___9X0Pn > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox___9X0Pn > :global(.__wab_flex-container) > *,
  .freeBox___9X0Pn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___9X0Pn > :global(.__wab_flex-container) > picture > img,
  .freeBox___9X0Pn
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.text__fOLed {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__fOLed {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
.inputFirstName:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.inputLastName:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.inputGender:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__yZfxL {
  position: relative;
  color: var(--token-T-yLSgErM);
  font-size: 14px;
  margin: 10px;
}
@media (min-width: 280px) {
  .text__yZfxL {
    margin-left: calc(10px + 0px) !important;
    margin-top: calc(10px + 8px) !important;
  }
}
.inputCell:global(.__wab_instance) {
  position: relative;
  align-self: center;
  width: 100%;
  min-width: 0;
}
.text__dgHvk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.inputEmail:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__goPfc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.inputId:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.dob:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.submitBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn2 {
  border-width: 1px;
  border-style: none;
}
.text__xbfE {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__xbfE {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
.freeBox__zxhpq {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__zxhpq > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__zxhpq > :global(.__wab_flex-container) > *,
.freeBox__zxhpq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zxhpq > :global(.__wab_flex-container) > picture > img,
.freeBox__zxhpq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 280px) {
  .freeBox__zxhpq {
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    height: 100%;
    box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
    width: 100%;
    min-width: 0;
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__zxhpq > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 0;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 280px) {
  .freeBox__zxhpq > :global(.__wab_flex-container) > *,
  .freeBox__zxhpq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__zxhpq > :global(.__wab_flex-container) > picture > img,
  .freeBox__zxhpq
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
@media (min-width: 768px) {
  .freeBox__zxhpq {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
    min-width: 0;
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__zxhpq > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 0;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 768px) {
  .freeBox__zxhpq > :global(.__wab_flex-container) > *,
  .freeBox__zxhpq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__zxhpq > :global(.__wab_flex-container) > picture > img,
  .freeBox__zxhpq
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.text___6Fijj {
  position: relative;
  text-align: center;
}
@media (min-width: 280px) {
  .text___6Fijj {
    width: 100%;
    position: relative;
    text-align: center;
    min-width: 0;
  }
}
.accountName:global(.__wab_instance) {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .accountName:global(.__wab_instance) {
    height: auto;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
.accountNo:global(.__wab_instance) {
  position: relative;
  display: none;
}
@media (min-width: 280px) {
  .accountNo:global(.__wab_instance) {
    position: relative;
    display: flex;
  }
}
.selectBank:global(.__wab_instance) {
  position: relative;
  display: none;
}
@media (min-width: 280px) {
  .selectBank:global(.__wab_instance) {
    position: relative;
    display: flex;
  }
}
.accountType:global(.__wab_instance) {
  position: relative;
  display: none;
}
@media (min-width: 280px) {
  .accountType:global(.__wab_instance) {
    position: relative;
    display: flex;
  }
}
.branchCode:global(.__wab_instance) {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .branchCode:global(.__wab_instance) {
    height: auto;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
.changeCard:global(.__wab_instance) {
  position: relative;
}
.submitBtn6 {
  border-width: 1px;
  border-style: none;
}
.text__cJmcE {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__cJmcE {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
.paymentDate:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.changeDate:global(.__wab_instance) {
  position: relative;
}
.submitBtn7 {
  border-width: 1px;
  border-style: none;
}
.freeBox___3RMhl {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__kyqPf {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.backBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn4 {
  border-width: 1px;
  border-style: none;
}
.freeBox__mb023 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__mb023 {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: 0;
    padding: 8px;
  }
}
.forgotBtn:global(.__wab_instance) {
  position: relative;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.footerWidget:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}

.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__te7Ej {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 768px) {
  .freeBox__te7Ej {
    background: url("./images/lightgreensvg.svg") 0% 0% / contain repeat-x;
  }
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
.freeBox___4XLbX {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: 100%;
  min-height: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox___4XLbX {
    align-self: center;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: none;
    height: 100%;
    min-height: 0;
    padding: 8px;
  }
}
.freeBox___0HN3T {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox___0HN3T > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox___0HN3T > :global(.__wab_flex-container) > *,
.freeBox___0HN3T > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___0HN3T > :global(.__wab_flex-container) > picture > img,
.freeBox___0HN3T
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 280px) {
  .freeBox___0HN3T > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox___0HN3T > :global(.__wab_flex-container) > *,
  .freeBox___0HN3T > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___0HN3T > :global(.__wab_flex-container) > picture > img,
  .freeBox___0HN3T
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox___0HN3T {
    width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox___0HN3T > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .freeBox___0HN3T > :global(.__wab_flex-container) > *,
  .freeBox___0HN3T > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___0HN3T > :global(.__wab_flex-container) > picture > img,
  .freeBox___0HN3T
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.text__pvDtm {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.profileArea:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
@media (min-width: 280px) {
  .profileArea:global(.__wab_instance) {
    display: flex;
  }
}
.freeBox__fqPxw {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBox__fqPxw > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__fqPxw {
    background: #eaf0e5;
    display: flex;
    border-radius: 4px;
  }
}
@media (min-width: 280px) {
  .freeBox__fqPxw > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__fqPxw > :global(.__wab_flex-container) > *,
  .freeBox__fqPxw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__fqPxw > :global(.__wab_flex-container) > picture > img,
  .freeBox__fqPxw
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.freeBox__i3Zwz {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 280px) {
  .freeBox__i3Zwz {
    align-items: center;
    justify-content: space-between;
    padding: 0px;
  }
}
.text__gln9F {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__gln9F {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
.paymentList {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.paymentList > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .paymentList {
    display: flex;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .paymentList > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 15px);
    height: calc(100% + 15px);
  }
}
@media (min-width: 280px) {
  .paymentList > :global(.__wab_flex-container) > *,
  .paymentList > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .paymentList > :global(.__wab_flex-container) > picture > img,
  .paymentList
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 15px;
  }
}
.paymentListItem__k8Tm8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
@media (min-width: 280px) {
  .paymentListItem__k8Tm8:global(.__wab_instance) {
    display: flex;
  }
}
.paymentListItem__eMoWn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__u8Scq {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-style: italic;
  font-family: "Poppins", sans-serif;
  font-size: 9px;
  padding-left: 0px;
}
.freeBox__yjs9U {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.backBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn4 {
  border-width: 1px;
  border-style: none;
}
.freeBox__lJkd5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__lJkd5 {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: 0;
    padding: 8px;
  }
}
.supportBtn:global(.__wab_instance) {
  position: relative;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.footerWidget:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}

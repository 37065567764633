.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: var(--token-1Q1zU_qER8IeSd);
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__eRpKb {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: none;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .freeBox__eRpKb {
    background: none;
    height: auto;
  }
}
@media (min-width: 768px) {
  .freeBox__eRpKb {
    background: none;
  }
}
@media (min-width: 1045px) {
  .freeBox__eRpKb {
    background: none;
    height: 100%;
    min-height: 0;
  }
}
.freeBox__hRho3 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: none;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .freeBox__hRho3 {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .freeBox__hRho3 {
    display: flex;
    flex-direction: column;
    background: none;
  }
}
@media (min-width: 1045px) {
  .freeBox__hRho3 {
    background: none;
    height: 100%;
    min-height: 0;
  }
}
.navPageTitleA:global(.__wab_instance) {
  position: relative;
}
.freeBox__q2R3 {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  display: none;
  padding: 8px;
}
@media (min-width: 1045px) {
  .freeBox__q2R3 {
    display: flex;
  }
}
.freeBox__xhC7 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.sideNavA:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 1045px) {
  .sideNavA:global(.__wab_instance) {
    max-width: none;
    width: auto;
  }
}
.freeBox__tiOOp {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  align-self: auto;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox__gdw9A {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: relative;
  align-self: auto;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox__gdw9A > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 768px) {
  .freeBox__gdw9A {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: relative;
    align-self: flex-start;
    min-width: 0;
    min-height: 0;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__gdw9A > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-width: 0;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 768px) {
  .freeBox__gdw9A > :global(.__wab_flex-container) > *,
  .freeBox__gdw9A > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__gdw9A > :global(.__wab_flex-container) > picture > img,
  .freeBox__gdw9A
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.profileArea:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
}
@media (min-width: 768px) {
  .profileArea:global(.__wab_instance) {
    max-width: 100%;
    position: relative;
  }
}
.userTabs:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 768px) {
  .userTabs:global(.__wab_instance) {
    position: relative;
  }
}
.userInfoArea:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 280px) {
  .userInfoArea:global(.__wab_instance) {
    position: relative;
  }
}
@media (min-width: 380px) {
  .userInfoArea:global(.__wab_instance) {
    position: relative;
  }
}
@media (min-width: 768px) {
  .userInfoArea:global(.__wab_instance) {
    position: relative;
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 1045px) {
  .userInfoArea:global(.__wab_instance) {
    position: relative;
    width: 100%;
    min-width: 0;
  }
}

.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__om45L {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/headerBGsliceLrGsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 280px) {
  .freeBox__om45L {
    height: 100%;
    background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
    min-height: 0;
  }
}
.navPageTitle:global(.__wab_instance) {
  position: relative;
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 32px 0px 16px;
}
.outerstatus_complete {
  height: 100%;
  min-height: 0;
}
.outerstatus_start {
  height: 100%;
  min-height: 0;
}
.freeBox___8Uxcd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: 348px;
  flex-shrink: 0;
  padding: 8px;
}
@media (min-width: 280px) {
  .freeBox___8Uxcd {
    height: auto;
  }
}
.freeBox__pM4X {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: auto;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  border-radius: 4px;
  padding: 8px;
}
.freeBox__pM4X > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
@media (min-width: 280px) {
  .freeBox__pM4X > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 20px);
    height: calc(100% + 20px);
  }
}
@media (min-width: 280px) {
  .freeBox__pM4X > :global(.__wab_flex-container) > *,
  .freeBox__pM4X > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__pM4X > :global(.__wab_flex-container) > picture > img,
  .freeBox__pM4X
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 20px;
  }
}
.freeBoxstatus_loading__pM4XpCqDu {
  height: 100%;
  min-height: 0;
}
.freeBoxstatus_loading__pM4XpCqDu > :global(.__wab_flex-container) {
  min-height: 0;
}
.text__n1CU {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.textstatus_complete__n1CUudpzf {
  display: none;
}
.textstatus_loading__n1CUpCqDu {
  display: none;
}
.inputFirstName {
  position: relative;
  width: 100%;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-left-style: none;
  color: var(--token-vYO6h4FW3);
  font-size: 12px;
  min-width: 0;
  padding: 16px;
  border-width: 1px;
}
@media (min-width: 280px) {
  .inputFirstName {
    display: none;
  }
}
.inputFirstNamestatus_complete {
  display: none;
}
.inputFirstNamestatus_loading {
  display: none;
}
.firstName:global(.__wab_instance) {
  position: relative;
}
.lastName:global(.__wab_instance) {
  position: relative;
}
.inputLastName {
  position: relative;
  width: 100%;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-left-style: none;
  color: var(--token-vYO6h4FW3);
  font-size: 12px;
  min-width: 0;
  padding: 16px;
  border-width: 1px;
}
@media (min-width: 280px) {
  .inputLastName {
    display: none;
  }
}
.inputLastNamestatus_complete {
  display: none;
}
.inputLastNamestatus_loading {
  display: none;
}
.inputCell {
  position: relative;
  width: 100%;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-left-style: none;
  color: var(--token-vYO6h4FW3);
  font-size: 12px;
  min-width: 0;
  padding: 16px;
  border-width: 1px;
}
@media (min-width: 280px) {
  .inputCell {
    display: none;
  }
}
.inputCellstatus_complete {
  display: none;
}
.inputCellstatus_loading {
  display: none;
}
.cellNumber:global(.__wab_instance) {
  position: relative;
}
.inputEmail {
  position: relative;
  width: 100%;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-left-style: none;
  color: var(--token-vYO6h4FW3);
  font-size: 12px;
  min-width: 0;
  padding: 16px;
  border-width: 1px;
}
@media (min-width: 280px) {
  .inputEmail {
    display: none;
  }
}
.inputEmailstatus_complete {
  display: none;
}
.inputEmailstatus_loading {
  display: none;
}
.email:global(.__wab_instance) {
  position: relative;
}
.inputComment {
  position: relative;
  width: 100%;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-left-style: none;
  color: var(--token-vYO6h4FW3);
  font-size: 12px;
  min-width: 0;
  padding: 16px;
  border-width: 1px;
}
.inputCommentstatus_complete {
  display: none;
}
.inputCommentstatus_loading {
  display: none;
}
.text__eDtx8 {
  position: relative;
}
.textstatus_complete__eDtx8Udpzf {
  height: auto;
  color: var(--token-c79ojIUd1);
  font-family: "Poppins", sans-serif;
  text-align: center;
}
.submit:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 280px) {
  .submit:global(.__wab_instance) {
    display: none;
  }
}
.submitBtn {
  border-width: 1px;
  border-style: none;
}
.loadingArea {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 40px;
  width: auto;
  flex-shrink: 0;
  padding: 16px;
}
@media (min-width: 280px) {
  .loadingArea {
    display: none;
  }
}
.loadingAreastatus_complete {
  height: 100%;
  min-height: 0;
}
.loadingAreastatus_start {
  justify-content: center;
  align-items: center;
  margin: 5px;
}
.loadingAreastatus_loading {
  height: 100%;
  min-height: 0;
}
.txtError {
  position: relative;
}
.txtErrorstatus_start {
  align-self: center;
  font-size: 12px;
  color: #cb3232;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  padding: 5px;
}
.freeBox__sgZs {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__xe0Mf {
  position: relative;
}
.textstatus_loading__xe0MfpCqDu {
  text-align: center;
  color: var(--token-c79ojIUd1);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.btnSend:global(.__wab_instance) {
  position: relative;
}
.btnSendstatus_complete:global(.__wab_instance) {
  display: none;
}
.btnSendstatus_loading:global(.__wab_instance) {
  display: none;
}
.text__eI8GC {
  height: auto;
}
.freeBox__hifYi {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: 100%;
  min-height: 0;
  padding: 8px;
}
.freeBox__g2HJa {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-oWf5ggoEo);
  height: auto;
  border-radius: 4px;
  padding: 8px;
}
.text__jQxva {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.textstatus_complete__jQxvAudpzf {
  display: none;
}
.text___6UlMv {
  position: relative;
}
.textstatus_complete___6UlMVudpzf {
  display: none;
}
.svg {
  position: relative;
  object-fit: cover;
  color: var(--token-wNURBLgcg);
  width: 200px;
  height: 30px;
  align-self: center;
  flex-shrink: 0;
}
.freeBox__ktBSq {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__ktBSq > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.freeBox__ktBSq > :global(.__wab_flex-container) > *,
.freeBox__ktBSq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ktBSq > :global(.__wab_flex-container) > picture > img,
.freeBox__ktBSq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.btnFacebook {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  flex-shrink: 0;
}
.btnTwitter {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  flex-shrink: 0;
}
.btnInstagram {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  flex-shrink: 0;
}
.footerWidget:global(.__wab_instance) {
  position: relative;
}

.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__l5VNi {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 768px) {
  .freeBox__l5VNi {
    background: url("./images/lightgreensvg.svg") 0% 0% / contain repeat-x;
  }
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
.freeBox__eDlLo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: 100%;
  min-height: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__eDlLo {
    align-self: center;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: none;
    height: 100%;
    min-height: 0;
    padding: 8px;
  }
}
.freeBox__qQon {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 2px 4px 4px 2px var(--token-0Uwcv5K4y);
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox__qQon > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__qQon > :global(.__wab_flex-container) > *,
.freeBox__qQon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__qQon > :global(.__wab_flex-container) > picture > img,
.freeBox__qQon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
@media (min-width: 380px) {
  .freeBox__qQon > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 380px) {
  .freeBox__qQon > :global(.__wab_flex-container) > *,
  .freeBox__qQon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__qQon > :global(.__wab_flex-container) > picture > img,
  .freeBox__qQon
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__qQon {
    width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--token-oWf5ggoEo);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 768px) {
  .freeBox__qQon > :global(.__wab_flex-container) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 768px) {
  .freeBox__qQon > :global(.__wab_flex-container) > *,
  .freeBox__qQon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__qQon > :global(.__wab_flex-container) > picture > img,
  .freeBox__qQon
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.text__kUpox {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.freeBox___7ImI0 {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBox___7ImI0 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox___7ImI0 {
    background: #eaf0e5;
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox___7ImI0 > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox___7ImI0 > :global(.__wab_flex-container) > *,
  .freeBox___7ImI0 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___7ImI0 > :global(.__wab_flex-container) > picture > img,
  .freeBox___7ImI0
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.freeBox__qxn2P {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__qxn2P > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__qxn2P {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
  }
}
@media (min-width: 280px) {
  .freeBox__qxn2P > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__qxn2P > :global(.__wab_flex-container) > *,
  .freeBox__qxn2P > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__qxn2P > :global(.__wab_flex-container) > picture > img,
  .freeBox__qxn2P
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__qxn2P > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__qxn2P > :global(.__wab_flex-container) > *,
  .freeBox__qxn2P > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__qxn2P > :global(.__wab_flex-container) > picture > img,
  .freeBox__qxn2P
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__oxsnk:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 280px) {
  .iconNumber__oxsnk:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (min-width: 380px) {
  .iconNumber__oxsnk:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__tIv2S {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__tIv2S {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__tIv2S {
    font-size: 11px;
  }
}
.selectDependant:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__qDVwX {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__qDVwX > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__qDVwX {
    background: var(--token-gmc_Ctzg2);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__qDVwX > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__qDVwX > :global(.__wab_flex-container) > *,
  .freeBox__qDVwX > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__qDVwX > :global(.__wab_flex-container) > picture > img,
  .freeBox__qDVwX
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox__qDVwX {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox__eO2Yq {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__eO2Yq > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__eO2Yq {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__eO2Yq > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__eO2Yq > :global(.__wab_flex-container) > *,
  .freeBox__eO2Yq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__eO2Yq > :global(.__wab_flex-container) > picture > img,
  .freeBox__eO2Yq
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__eO2Yq > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__eO2Yq > :global(.__wab_flex-container) > *,
  .freeBox__eO2Yq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__eO2Yq > :global(.__wab_flex-container) > picture > img,
  .freeBox__eO2Yq
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__ifLnj:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber__ifLnj:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__kp4Bf {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__kp4Bf {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__kp4Bf {
    font-size: 11px;
  }
}
.svg__bnyrB {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
  display: none;
}
@media (min-width: 380px) {
  .svg__bnyrB {
    display: none;
  }
}
.btnInfo {
  position: absolute;
  object-fit: cover;
  color: var(--token-vYO6h4FW3);
  width: auto;
  height: 30px;
  left: auto;
  top: -4px;
  right: 1px;
}
@media (min-width: 280px) {
  .btnInfo {
    display: block;
  }
}
@media (min-width: 380px) {
  .btnInfo {
    display: block;
  }
}
.selectNatureofDeath:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__ww0R4 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__ww0R4 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__ww0R4 {
    background: var(--token-gmc_Ctzg2);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__ww0R4 > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__ww0R4 > :global(.__wab_flex-container) > *,
  .freeBox__ww0R4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__ww0R4 > :global(.__wab_flex-container) > picture > img,
  .freeBox__ww0R4
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox__ww0R4 {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox__oFi2X {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__oFi2X > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__oFi2X {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__oFi2X > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__oFi2X > :global(.__wab_flex-container) > *,
  .freeBox__oFi2X > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__oFi2X > :global(.__wab_flex-container) > picture > img,
  .freeBox__oFi2X
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__oFi2X > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__oFi2X > :global(.__wab_flex-container) > *,
  .freeBox__oFi2X > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__oFi2X > :global(.__wab_flex-container) > picture > img,
  .freeBox__oFi2X
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__dt1PL:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber__dt1PL:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__ansvF {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__ansvF {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__ansvF {
    font-size: 11px;
  }
}
.svg___81HgK {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
  display: none;
}
@media (min-width: 380px) {
  .svg___81HgK {
    display: none;
  }
}
.btnInfo2 {
  position: absolute;
  object-fit: cover;
  color: var(--token-vYO6h4FW3);
  width: auto;
  height: 30px;
  left: auto;
  top: -4px;
  right: 1px;
}
@media (min-width: 280px) {
  .btnInfo2 {
    display: none;
  }
}
@media (min-width: 380px) {
  .btnInfo2 {
    display: none;
  }
}
.selectNatureofDeath2:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (min-width: 280px) {
  .selectNatureofDeath2:global(.__wab_instance) {
    display: none;
  }
}
@media (min-width: 380px) {
  .selectNatureofDeath2:global(.__wab_instance) {
    display: none;
  }
}
.dod:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__qyyjk {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__qyyjk > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__qyyjk {
    background: var(--token-gmc_Ctzg2);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__qyyjk > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__qyyjk > :global(.__wab_flex-container) > *,
  .freeBox__qyyjk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__qyyjk > :global(.__wab_flex-container) > picture > img,
  .freeBox__qyyjk
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox__qyyjk {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox__kkx4X {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__kkx4X > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__kkx4X {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__kkx4X > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__kkx4X > :global(.__wab_flex-container) > *,
  .freeBox__kkx4X > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__kkx4X > :global(.__wab_flex-container) > picture > img,
  .freeBox__kkx4X
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__kkx4X > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__kkx4X > :global(.__wab_flex-container) > *,
  .freeBox__kkx4X > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__kkx4X > :global(.__wab_flex-container) > picture > img,
  .freeBox__kkx4X
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber___3G0:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber___3G0:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (min-width: 280px) {
  .text__anUA {
    width: auto;
  }
}
.text__eRyJc {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__eRyJc {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__eRyJc {
    font-size: 11px;
  }
}
.inputStreet:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.selectCity:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.selectProvince:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__u3Sm {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__u3Sm > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__u3Sm {
    background: linear-gradient(var(--token-gmc_Ctzg2), var(--token-gmc_Ctzg2)),
      #eaf0e5;
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__u3Sm > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__u3Sm > :global(.__wab_flex-container) > *,
  .freeBox__u3Sm > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__u3Sm > :global(.__wab_flex-container) > picture > img,
  .freeBox__u3Sm
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox__u3Sm {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox__zEhiy {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__zEhiy > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__zEhiy {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__zEhiy > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__zEhiy > :global(.__wab_flex-container) > *,
  .freeBox__zEhiy > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__zEhiy > :global(.__wab_flex-container) > picture > img,
  .freeBox__zEhiy
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__zEhiy > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__zEhiy > :global(.__wab_flex-container) > *,
  .freeBox__zEhiy > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__zEhiy > :global(.__wab_flex-container) > picture > img,
  .freeBox__zEhiy
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__ygsPr:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber__ygsPr:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text___7IaO {
  padding-right: 0px;
}
.text__j31HN {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__j31HN {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__j31HN {
    font-size: 11px;
  }
}
.postalCheck:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text___284D6 {
  font-family: "Poppins", sans-serif;
  width: 100%;
  white-space: pre-wrap;
  font-size: 11px;
  min-width: 0;
}
.residentialStreet:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.residentialCity:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.residentialProvince:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox___3Ij2 {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox___3Ij2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox___3Ij2 {
    background: linear-gradient(var(--token-gmc_Ctzg2), var(--token-gmc_Ctzg2)),
      #eaf0e5;
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox___3Ij2 > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox___3Ij2 > :global(.__wab_flex-container) > *,
  .freeBox___3Ij2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___3Ij2 > :global(.__wab_flex-container) > picture > img,
  .freeBox___3Ij2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox___3Ij2 {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox__exSon {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__exSon > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__exSon {
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    display: none;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__exSon > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__exSon > :global(.__wab_flex-container) > *,
  .freeBox__exSon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__exSon > :global(.__wab_flex-container) > picture > img,
  .freeBox__exSon
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__exSon > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__exSon > :global(.__wab_flex-container) > *,
  .freeBox__exSon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__exSon > :global(.__wab_flex-container) > picture > img,
  .freeBox__exSon
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__hye4Q:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber__hye4Q:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__zrXrc {
  padding-right: 0px;
}
@media (min-width: 380px) {
  .text__zrXrc {
    height: auto;
  }
}
.text___4EfdV {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
  height: auto;
}
@media (min-width: 280px) {
  .text___4EfdV {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text___4EfdV {
    font-size: 11px;
  }
}
.downloadForm:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 280px) {
  .downloadForm:global(.__wab_instance) {
    display: none;
  }
}
.submitBtn5 {
  border-width: 1px;
  border-style: none;
}
.freeBox__aMwp {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__aMwp > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__aMwp {
    background: var(--token-gmc_Ctzg2);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__aMwp > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__aMwp > :global(.__wab_flex-container) > *,
  .freeBox__aMwp > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__aMwp > :global(.__wab_flex-container) > picture > img,
  .freeBox__aMwp
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox__aMwp {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox__zTpBz {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__zTpBz > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__zTpBz {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__zTpBz > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 0;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__zTpBz > :global(.__wab_flex-container) > *,
  .freeBox__zTpBz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__zTpBz > :global(.__wab_flex-container) > picture > img,
  .freeBox__zTpBz
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__zTpBz > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__zTpBz > :global(.__wab_flex-container) > *,
  .freeBox__zTpBz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__zTpBz > :global(.__wab_flex-container) > picture > img,
  .freeBox__zTpBz
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__u21N:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber__u21N:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__a1Oi0 {
  padding-right: 0px;
}
.text__rxH2L {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__rxH2L {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__rxH2L {
    font-size: 11px;
  }
}
.deceasedId:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .text__kXfSp {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__kXfSp {
    font-size: 11px;
  }
}
.deathNotice:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .text__w8IhN {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__w8IhN {
    font-size: 11px;
  }
}
.bankStatements:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .text__qqnvx {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__qqnvx {
    font-size: 11px;
  }
}
.claimForm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .text__wjAk8 {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__wjAk8 {
    font-size: 11px;
  }
}
.memberId:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .text__psmW9 {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__psmW9 {
    font-size: 11px;
  }
}
.deathCertificate:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 280px) {
  .text__upTSe {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__upTSe {
    font-size: 11px;
  }
}
.freeBox__qq2KS {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__qq2KS > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__qq2KS {
    background: var(--token-gmc_Ctzg2);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
@media (min-width: 280px) {
  .freeBox__qq2KS > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 280px) {
  .freeBox__qq2KS > :global(.__wab_flex-container) > *,
  .freeBox__qq2KS > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__qq2KS > :global(.__wab_flex-container) > picture > img,
  .freeBox__qq2KS
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 380px) {
  .freeBox__qq2KS {
    background: var(--token-gmc_Ctzg2);
  }
}
.freeBox__ev6KA {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__ev6KA > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 280px) {
  .freeBox__ev6KA {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 280px) {
  .freeBox__ev6KA > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    min-width: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 280px) {
  .freeBox__ev6KA > :global(.__wab_flex-container) > *,
  .freeBox__ev6KA > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__ev6KA > :global(.__wab_flex-container) > picture > img,
  .freeBox__ev6KA
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 380px) {
  .freeBox__ev6KA > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 4px);
    width: calc(100% + 4px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 380px) {
  .freeBox__ev6KA > :global(.__wab_flex-container) > *,
  .freeBox__ev6KA > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__ev6KA > :global(.__wab_flex-container) > picture > img,
  .freeBox__ev6KA
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 4px;
    margin-top: 0px;
  }
}
.iconNumber__q8Lsn:global(.__wab_instance) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}
@media (min-width: 380px) {
  .iconNumber__q8Lsn:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__zafQo {
  padding-right: 0px;
}
.text__vlQ5G {
  position: relative;
  color: var(--token-vYO6h4FW3);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 21px;
}
@media (min-width: 280px) {
  .text__vlQ5G {
    text-align: center;
    position: relative;
    color: var(--token-vYO6h4FW3);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}
@media (min-width: 380px) {
  .text__vlQ5G {
    font-size: 11px;
  }
}
.freeBox__r2OuR {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 280px) {
  .freeBox__r2OuR {
    background: var(--token-oWf5ggoEo);
    max-height: 100px;
    overflow: auto;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
  }
}
@media (min-width: 380px) {
  .freeBox__r2OuR {
    background: var(--token-oWf5ggoEo);
    overflow: auto;
    max-height: 100px;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
  }
}
.text__v58Yj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
@media (min-width: 280px) {
  .text__v58Yj {
    font-size: 11px;
  }
}
@media (min-width: 380px) {
  .text__v58Yj {
    font-size: 9px;
  }
}
.declaration:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 380px) {
  .declaration:global(.__wab_instance) {
    display: none;
  }
}
.text__wu5L {
  font-family: "Poppins", sans-serif;
  width: 100%;
  white-space: pre-wrap;
  font-size: 11px;
  min-width: 0;
}
.freeBox__lwqJl {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.submitBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn2 {
  border-width: 1px;
  border-style: none;
}
.freeBox__kiYCy {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.backBtn:global(.__wab_instance) {
  position: relative;
}
.submitBtn4 {
  border-width: 1px;
  border-style: none;
}
.freeBox__md69A {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__md69A {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: 0;
    padding: 8px;
  }
}
.forgotBtn:global(.__wab_instance) {
  position: relative;
}
.svg__uhhdg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  position: relative;
  border-radius: 50%;
  padding: 0px;
}
.rootremove {
  background: none;
  padding: 0px;
}
.rootdisabled {
  background: none;
}
.btnAddBenficiary {
  position: relative;
  object-fit: cover;
  width: 30px;
  height: 30px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  flex-shrink: 0;
}
.btnAddBenficiaryremove {
  color: var(--token-T-yLSgErM);
}
.btnAddBenficiarydisabled {
  color: var(--token-0Uwcv5K4y);
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.root {
  display: flex;
  flex-direction: column;
  background: var(--token-oWf5ggoEo);
  position: relative;
  width: 100%;
  min-width: 280px;
  max-width: 280px;
  border-radius: 8px;
  padding: 8px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
.img__bvLj6 {
  position: relative;
  object-fit: cover;
  border-radius: 4px;
}
.img__bvLj6 > picture > img {
  object-fit: cover;
}
.text__sjZc {
  font-weight: 700;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}
.slotTargetSlot7 {
  font-size: 14px;
  color: var(--token-vYO6h4FW3);
}
@media (min-width: 380px) {
  .slotTargetSlot7 {
    font-size: 14px;
    color: var(--token-vYO6h4FW3);
  }
}
@media (min-width: 768px) {
  .slotTargetSlot7 {
    font-size: 14px;
    color: var(--token-vYO6h4FW3);
  }
}
.text__pdhxb {
  position: relative;
  font-size: 12px;
  font-style: italic;
  padding: 8px;
}
.btnReadMore:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__ofk3O {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}

.root {
  display: flex;
  flex-direction: row;
  width: 360px;
  height: 131px;
  position: relative;
  padding: 16px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: none;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-width: 0;
}
.name {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: var(--token-vYO6h4FW3);
  font-weight: 700;
  min-width: 0;
  min-height: 0;
}
.subName {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 9px;
  font-weight: 700;
  color: var(--token-c79ojIUd1);
  min-width: 0;
  min-height: 0;
}
.quote {
  position: relative;
  font-size: 12px;
  font-style: italic;
  height: 100%;
  width: 100%;
  color: var(--token-vYO6h4FW3);
  min-width: 0;
  min-height: 0;
}

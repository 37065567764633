.root {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__uIHt {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 32px 0px 16px;
}
.freeBox__j5C9X {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  height: 100%;
  min-height: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__j5C9X {
    align-self: center;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: none;
    height: 100%;
    min-height: 0;
    padding: 8px;
  }
}
.freeBox__jsCvx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: var(--token-oWf5ggoEo);
  height: 100%;
  box-shadow: 0px 0px 4px 2px #00000014;
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__jsCvx {
    width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--token-oWf5ggoEo);
    min-height: 0;
    border-radius: 4px;
    padding: 8px;
  }
}
.text {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-vYO6h4FW3);
  position: relative;
  width: 100%;
  height: auto;
  align-self: flex-start;
  background: var(--token-h1nwR9emp);
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.inputPin {
  position: relative;
  width: 100%;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-left-style: none;
  font-size: 12px;
  color: var(--token-vYO6h4FW3);
  margin-bottom: 40px;
  box-shadow: none;
  margin-top: 16px;
  min-width: 0;
  padding: 16px;
  border-width: 1px;
}
.inputPassword:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__pBHrg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__pBHrg > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__pBHrg > :global(.__wab_flex-container) > *,
.freeBox__pBHrg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pBHrg > :global(.__wab_flex-container) > picture > img,
.freeBox__pBHrg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.submit:global(.__wab_instance) {
  position: relative;
}
.submitBtn2 {
  border-width: 1px;
  border-style: none;
}
.btnResendOtp:global(.__wab_instance) {
  position: relative;
}
.btnResend {
  cursor: pointer;
}
.svg__vTosx {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.freeBox___0Mq5X {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox___0Mq5X {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-width: 0;
    padding: 8px;
  }
}
.backBtn:global(.__wab_instance) {
  position: relative;
}
.svg___9F3Vx {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
  color: var(--token-vYO6h4FW3);
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
.footerWidget:global(.__wab_instance) {
  position: relative;
}

.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
  padding: 16px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
.profileImg {
  position: relative;
  object-fit: cover;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 50%;
}
.profileImg > picture > img {
  object-fit: cover;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: none;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-width: 0;
}
.nameTxt {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: var(--token-quptvOxLbYUbhC);
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  min-width: 0;
  min-height: 0;
}
.memberType {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 700;
  color: var(--token-1IgY_610qjxdAO);
  min-width: 0;
  min-height: 0;
}
.btnProfile:global(.__wab_instance) {
  position: relative;
  display: none;
}
@media (min-width: 1045px) {
  .btnProfile:global(.__wab_instance) {
    display: none;
  }
}

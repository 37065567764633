.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.freeBox__e80Kc {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: url("./images/lightgreenCutsvg.svg") 0% 0% / contain repeat-x;
  position: relative;
  width: 100%;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.navPageTitle:global(.__wab_instance) {
  position: relative;
}
.outer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 32px 0px 16px;
}
@media (min-width: 280px) {
  .outer {
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 768px) {
  .outer {
    height: 100%;
    min-height: 0;
  }
}
.cardHomeSliderCarousel:global(.__wab_instance) {
  position: relative;
  height: 460px;
  width: 100%;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 280px) {
  .cardHomeSliderCarousel:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .cardHomeSliderCarousel:global(.__wab_instance) {
    height: 460px;
    flex-shrink: 0;
  }
}
.freeBox__mSqx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  width: auto;
  padding: 16px;
}
.btnHow:global(.__wab_instance) {
  position: relative;
}
.text__ai88Q {
  color: var(--token-vYO6h4FW3);
}
.freeBox__wqIvF {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 628px;
  width: auto;
  background: url("./images/lightgreensvg.svg") 0% 0% / cover repeat;
  flex-shrink: 0;
  padding: 8px;
}
.text__pH4E {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--token-oWf5ggoEo);
  position: relative;
  width: 305px;
  height: auto;
  align-self: flex-start;
  padding: 8px;
}
.cardPricingSliderCarousel:global(.__wab_instance) {
  position: relative;
  width: 100%;
  height: 460px;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
}
.freeBox__z9M8 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  width: 100%;
  min-width: 0;
  padding: 16px;
}
.btnSignup2:global(.__wab_instance) {
  position: relative;
}
.btnAbout:global(.__wab_instance) {
  position: relative;
}
.testimonialHolder {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.testimonial__yoyvd:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.testimonial___39HnG:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.testimonial__gShjy:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.testimonial__eZjV:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.freeBox__cNzUh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  width: auto;
  padding: 16px;
}
.btnSignup:global(.__wab_instance) {
  position: relative;
  margin-top: 30px;
}
.footerWidget:global(.__wab_instance) {
  position: relative;
}
